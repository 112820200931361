import React, { useState } from 'react';
import { Button, Card, Col, Divider, Modal, Row, Tag } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import urlPageRBACUsers from '../../../../../urls/urlPageRBACUsers';
import { Permissions } from '../../../../../const/permissions';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import handleResponse from '../../../../../lib/handleResponse';
import useToggle from '../../../../../app/hooks/useToggle';
import FormCreateEditUser from '../../../forms/FormCreateEditUser';
import FormEditUserRoles from '../../../forms/FormEditUserRoles';
import FormEditUserPermissions from '../../../forms/FormEditUserPermissions';
import UserDetailsCard from '../../../components/UserDetailsCard';
import { refreshUserData } from '../../../../Auth/features/authSlice';
import { useApp } from '../../../../../app/context/AppContext';
import Spinner from '../../../../../components/Spinner';
import RoleTag from '../../../components/RoleTag';
import {
  useGetUserQuery,
  useUpdateUserMutation,
  useUpdateUserPermissionsMutation,
  useUpdateUserRolesMutation,
  useUpdateUserStatusMutation,
} from '../../../api/usersApiSlice';
import { useGetRolesQuery } from '../../../api/rolesApiSlice';
import { useGetPermissionsQuery } from '../../../api/permissionsApiSlice';



const PageUserDetails = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { userId: profileId } = useApp();
  const [ isEditUserModalActive, toggleEditUserModal ] = useToggle();
  const [ isEditUserRolesModalActive, toggleEditUserRolesModal ] = useToggle();
  const [ isEditUserPermissionsModalActive, toggleEditUserPermissionsModal ] = useToggle();
  const [ updateUserErrors, setUpdateUserErrors ] = useState({});
  const [ updateUserRolesErrors, setUpdateUserRolesErrors ] = useState({});
  const [ updateUserPermissionsErrors, setUpdateUserPermissionsErrors ] = useState({});

  const {
    data: userDetails = {
      permissions: { data: [] },
      roles: { data: [] },
    }, isLoading,
  } = useGetUserQuery({ userId, queryParams: { include: 'roles,permissions' } });

  const { data: { data: roles = [] } = { data: [], pagination: {} },
    isFetching: isLoadingRolesOptions,
  } = useGetRolesQuery({ queryParams: 'limit=0' });

  const { data: { data: permissionsList = [] } = { data: [], pagination: {} },
    isFetching: isLoadingPermissionsOptions,
  } = useGetPermissionsQuery({ queryParams: 'limit=0' });

  const [ updateUser, { isLoading: isUpdateUserLoading } ] = useUpdateUserMutation();
  const [ updateUserStatus, { isLoading: isUpdateUserStatusLoading } ] = useUpdateUserStatusMutation();
  const [ updateUserRoles, { isLoading: isUpdateUserRolesLoading } ] = useUpdateUserRolesMutation();
  const [ updateUserPermissions, { isLoading: isUpdateUserPermissionsLoading } ] = useUpdateUserPermissionsMutation();

  const userRoles = userDetails.roles?.data ?? [];
  const userPermissions = userDetails.permissions?.data ?? [];
  const userPermissionsIds = userDetails.permissions?.data?.map((item) => item.id) ?? [];
  const userRolesIds = userDetails.roles?.data?.map((item) => item.id) ?? [];
  const dividerClass = 'hp-border-color-black-40 hp-border-color-dark-80 hp-mb-32 hp-mt-32';


  const handleUpdateUserData = async (values) => {
    updateUser({ userId, data: values })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => {
            if (parseInt(userId, 10) === parseInt(profileId, 10)) {
              dispatch(refreshUserData({ data: { ...userDetails, ...response.data } }));
            }
          },
          toggleEditUserModal,
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setUpdateUserErrors(error.data.errors);
          }
        } ],
        error,
      ));
  };

  const handleUpdateUserRoles = async (values) => {
    updateUserRoles({ userId, values })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          toggleEditUserRolesModal,
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setUpdateUserRolesErrors(error.data.errors);
          }
        } ],
        error,
      ));
  };

  const handleUpdateUserPermissions = async (values) => {
    updateUserPermissions({ userId, values })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          toggleEditUserPermissionsModal,
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => {
          if (isObject(error?.data?.errors)) {
            setUpdateUserPermissionsErrors(error.data.errors);
          }
        } ],
        error,
      ));
  };

  const handleChangeStatus = () => {
    updateUserStatus({ userId, data: { is_active: !userDetails.is_active } })
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-updated-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-update-failed', [], error));
  };

  const hideUserDetailsModal = () => {
    toggleEditUserModal();
    setUpdateUserErrors({});
  };

  const hideUserRolesModal = () => {
    toggleEditUserRolesModal();
    setUpdateUserRolesErrors({});
  };

  const hideUserPermissionsModal = () => {
    toggleEditUserPermissionsModal();
    setUpdateUserPermissionsErrors({});
  };

  const editUserDetailsInitialValues = {
    name: userDetails.name,
    email: userDetails.email,
  };

  const userRolesList = () => {
    if (isEmpty(userRoles)) {
      return <IntlMessages id='user-details-roles-placeholder' />;
    }

    return userRoles.map((role) => <RoleTag key={role.id} role={role} />);
  };

  return (
    <>
      <Row gutter={32}>
        <Col className="hp-mb-32" span={24}>
          <BreadCrumbs
            breadCrumbParent={
              <Link to={urlPageRBACUsers()}>
                <IntlMessages id='users-breadcrumbs' />
              </Link>
            }
            breadCrumbActive={<IntlMessages id='user-details-breadcrumbs' />}
          />
        </Col>
      </Row>

      <Col span={24} className="hp-mb-24">
        <Spinner spinning={isLoading || isUpdateUserStatusLoading}>
          <Card>
            <div>
              <Modal
                title={userDetails?.name}
                width={450}
                centered
                visible={isEditUserModalActive}
                onCancel={hideUserDetailsModal}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
              >
                <Spinner spinning={isLoading}>
                  <FormCreateEditUser
                    isEditUser
                    initialValues={editUserDetailsInitialValues}
                    formErrors={updateUserErrors}
                    disabledEmail
                    onSubmit={handleUpdateUserData}
                    onCancel={hideUserDetailsModal}
                    isSubmitting={isUpdateUserLoading}
                  />
                </Spinner>
              </Modal>

              <Modal
                title={<IntlMessages id='ui-general-roles' />}
                width={450}
                centered
                visible={isEditUserRolesModalActive}
                onCancel={hideUserRolesModal}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
              >
                <Spinner spinning={isUpdateUserRolesLoading || isLoadingRolesOptions}>
                  <FormEditUserRoles
                    initialValues={{ roles_ids: userRolesIds }}
                    rolesList={roles}
                    isSubmitting={isUpdateUserRolesLoading}
                    formErrors={updateUserRolesErrors}
                    onCancel={hideUserRolesModal}
                    onSubmit={handleUpdateUserRoles}
                  />
                </Spinner>
              </Modal>

              <Modal
                title={<IntlMessages id='ui-general-permissions' />}
                width={450}
                centered
                visible={isEditUserPermissionsModalActive}
                onCancel={hideUserPermissionsModal}
                footer={null}
                closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
              >
                <Spinner spinning={isUpdateUserPermissionsLoading || isLoadingPermissionsOptions}>
                  <FormEditUserPermissions
                    initialValues={{ permissions_ids: userPermissionsIds }}
                    permissionsList={permissionsList}
                    formErrors={updateUserPermissionsErrors}
                    isSubmitting={isUpdateUserPermissionsLoading}
                    onCancel={hideUserPermissionsModal}
                    onSubmit={handleUpdateUserPermissions}
                  />
                </Spinner>
              </Modal>

              <UserDetailsCard
                userDetails={userDetails}
                handleChangeStatus={handleChangeStatus}
                toggleEditUserModal={toggleEditUserModal}
              />

              {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.GET_USER_ROLES ]) && (
                <>
                  <Divider className={dividerClass} />
                  <Row justify="space-between">
                    <Col md={12}>
                      <h3><IntlMessages id='ui-general-roles' /></h3>
                      <p className="hp-p1-body hp-mb-0">
                        {userRolesList()}
                      </p>
                    </Col>

                    {(HasRights([
                      Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_ROLES,
                      Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
                    ], true) ||
                      HasRights([
                        Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_ROLES,
                        Permissions.ADMINISTRATION.USERS.USER_DETAILS.EDIT_OWN_ROLE,
                        Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
                      ], true)) && (
                      <Col className="hp-mt-md-24">
                        <Button
                          type="primary"
                          onClick={toggleEditUserRolesModal}
                        >
                          <IntlMessages id='ui-general-edit' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}

              {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.GET_USER_PERMISSIONS ]) && (
                <>
                  <Divider className={dividerClass} />
                  <Row justify="space-between" className='hp-mb-8'>
                    <Col md={12}>
                      <h3><IntlMessages id='ui-general-permissions' /></h3>
                      <p className="hp-p1-body hp-mb-0">
                        {!isEmpty(userPermissions) ? userPermissions.map((permission) => {
                          return (
                            <Tag
                              key={permission.id}
                              className='hp-my-4'
                              color='geekblue'
                            >
                              {capitalize(permission.name)}
                            </Tag>
                          );
                        }) : (
                          <IntlMessages id='user-details-permissions-placeholder' />
                        )}
                      </p>
                    </Col>

                    {HasRights([
                      Permissions.ADMINISTRATION.USERS.USER_DETAILS.UPDATE_USER_PERMISSIONS,
                      Permissions.ADMINISTRATION.PERMISSIONS.VIEW_LIST,
                    ], true) && (
                      <Col className="hp-mt-md-24">
                        <Button
                          type="primary"
                          onClick={toggleEditUserPermissionsModal}
                        >
                          <IntlMessages id='ui-general-edit' />
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}
            </div>
          </Card>
        </Spinner>
      </Col>
    </>
  );
};

export default PageUserDetails;
