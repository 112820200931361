import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Space, Table, Typography } from 'antd';
import { RiAddLine } from 'react-icons/ri';
import { Search } from 'react-iconly';
import { Filter } from 'iconsax-react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import basePagination from '../../../../../const/pagination';
import urlPageCreateTemplate from '../../../../../urls/urlPageCreateTemplate';
import urlPageEditTemplate from '../../../../../urls/urlPageEditTemplate';
import useToggle from '../../../../../app/hooks/useToggle';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import handleResponse from '../../../../../lib/handleResponse';
import { getTemplatesTableColumns } from './PageTemplates.const';
import ActionButton from '../../../../../layout/components/action-button';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Sidebar from '../../../../../components/Sidebar';
import FormFilterTemplates from '../../../forms/FormFilterTemplates';
import {
  useCloneTemplateMutation,
  useDeleteTemplateMutation,
  useGetTemplateContentTypesQuery,
  useGetTemplatesQuery,
} from '../../../api/templatesApiSlice';



const { Title } = Typography;

const PageTemplates = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ isVisibleFilterSidebar, toggleFilterSidebar ] = useToggle();

  const searchFields = [ 'title' ];
  const searchFilterFields = [ 'id', 'title', 'status', 'created_from', 'created_to', 'content_type' ];

  const {
    pagination,
    search,
    setSearchTerm,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
    resetIsManuallyTriggered,
    setResetManuallyTriggered,
  } = useQueryParams({ searchFields, searchFilterFields });

  const {
    data: contentTypes = { data: [] },
    isFetchingContentTypes,
  } = useGetTemplateContentTypesQuery();

  const {
    data = { data: [], pagination: {} },
    isFetching,
    error: formErrors = {},
  } = useGetTemplatesQuery({ queryParams: searchParams.toString() });

  const [ deleteTemplate, { isLoading: isDeletingTemplate } ] = useDeleteTemplateMutation();
  const [ cloneTemplate, { isLoading: isCloningTemplate } ] = useCloneTemplateMutation();


  useEffect(() => {
    localStorage.setItem('searchParams', searchParams.toString());
  }, [ searchParams ]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setResetManuallyTriggered(false);
  };

  const handleDeleteTemplate = (id) => {
    deleteTemplate(id)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-deleted-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleCloneTemplate = (id) => {
    cloneTemplate(id)
      .unwrap()
      .then((res) => handleResponse(
        'success',
        intl,
        'templates-clone-successfully',
        [ () => navigate(urlPageEditTemplate({ templateId: res.data.id })) ],
      ))
      .catch((error) => handleResponse('error', intl, 'templates-clone-failed', [], error));
  };

  const isLoading = isFetching || isDeletingTemplate || isCloningTemplate || isFetchingContentTypes;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='templates-breadcrumbs' />} />

        <Col md={12} span={24}>
          <Row
            gutter={[ 16, 16 ]}
            justify="end"
            align="middle"
          >
            <Col md={12} span={24}>
              <Input
                allowClear={!isEmpty(search)}
                placeholder={intl.formatMessage({ id: 'ui-general-search-by-title' })}
                prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Space>
                <Link to={urlPageCreateTemplate()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                  >
                    <IntlMessages id='ui-general-create' />
                  </Button>
                </Link>
              </Space>
            </Col>
            <Col>
              <Space>
                <ActionButton
                  title=""
                  isActive={!isEmpty(initFilterValues)}
                  icon={<Filter size={18} />}
                  onClick={toggleFilterSidebar}
                />
              </Space>
            </Col>
          </Row>
        </Col>

        <Sidebar
          title={<Title level={5}>{intl.formatMessage({ id: 'templates-filter-title' })}</Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFilterTemplates
            initialValues={initFilterValues}
            formErrors={formErrors}
            contentTypes={contentTypes ?? []}
            isSubmitting={isFetching}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                setSearchTerm,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                setSearchField: setSearchTerm,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <Table
              sticky
              loading={makeTableLoadingObject(isLoading)}
              rowKey="id"
              columns={getTemplatesTableColumns(handleCloneTemplate, handleDeleteTemplate)}
              dataSource={data?.data}
              onChange={handleChangeTableParams}
              pagination={{
                ...basePagination,
                current: pagination.page,
                pageSize: pagination.limit,
                total: data.pagination?.total,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};


export default PageTemplates;

