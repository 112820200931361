import React from 'react';
import {
  PiUpload,
  PiTreeStructure,
  PiLink,
  PiTranslate,
  PiUsers,
  PiUserCirclePlus,
  PiLockKey,
  PiImagesSquare,
  PiNewspaperLight,
  PiMedal,
  PiCloudSunLight,
  PiSealCheck,
  PiRocketLaunchLight,
  PiQueue,
  PiPaperPlaneTilt,
  PiGift,
  PiListMagnifyingGlass,
  PiPackageLight,
  PiCards,
  PiArrowCounterClockwise,
} from 'react-icons/pi';
import { BiCategoryAlt } from 'react-icons/bi';
import { ImInsertTemplate } from 'react-icons/im';
import { IoEarth } from 'react-icons/io5';
import { VscNotebookTemplate } from 'react-icons/vsc';
import moment from 'moment';
import { DATE_FORMAT } from '../../const/system';
import { Permissions } from '../../const/permissions';
import { FEATURE_SHOW_OLD_NEWS } from '../../const/features';
import { FEATURES_SOURCE_MAP } from '../../domains/Featured/pages/PageFeatures/PageFeatures.const';
import { NEWS_DICT_STRUCTURE } from '../../domains/Content/pages/News/PageDynamicNews/PageDynamicNews.const';
import { POPUPS_SOURCE } from '../../domains/Other/pages/Popups/PagePopups/PagePopups.const';
import { COLLECTIONS_SOURCE, COLLECTIONS_SOURCES } from '../../domains/Content/pages/Collections/PageCollections/PageCollections.const';
import { FEEDS_SOURCE_MAP } from '../../domains/Content/pages/Feeds/PageFeeds.const';
import IntlMessages from '../../layout/components/lang/IntlMessages';
import urlPageNews from '../../urls/urlPageNews';
import urlPageNewsPriority from '../../urls/urlPageNewsPriority';
import urlPagePushIcons from '../../urls/urlPagePushIcons';
import urlPageDeeplinks from '../../urls/urlPageDeeplinks';
import urlPageRBACUsers from '../../urls/urlPageRBACUsers';
import urlPageRBACRoles from '../../urls/urlPageRBACRoles';
import urlPageRBACPermissions from '../../urls/urlPageRBACPermissions';
import urlPageLanguages from '../../urls/urlPageLanguages';
import urlPageStructures from '../../urls/urlPageStructures';
import urlPageDictionaries from '../../urls/urlPageDictionaries';
import urlPageImages from '../../urls/urlPageImages';
import urlPageImagesReserveStatistics from '../../urls/urlPageImagesReserveStatistics';
import urlPageTemplates from '../../urls/urlPageTemplates';
import urlPageDynamicNews from '../../urls/urlPageDynamicNews';
import urlPageDynamicNewsPriority from '../../urls/urlPageDynamicNewsPriority';
import urlPageCategories from '../../urls/urlPageCategories';
import urlPageAchievements from '../../urls/urlPageAchievements';
import urlPageCategoriesPriorities from '../../urls/urlPageCategoriesPriorities';
import urlPageFeatures from '../../urls/urlPageFeatures';
import urlPageFeaturePriorities from '../../urls/urlPageFeaturePriorities';
import urlPagePresets from '../../urls/urlPagePresets';
import urlPagePopups from '../../urls/urlPagePopups';
import urlPagePopupsPriority from '../../urls/urlPagePopupsPriority';
import urlPageBonuses from '../../urls/urlPageBonuses';
import urlPageRBACLogs from '../../urls/urlPageRBACLogs';
import urlPageCollections from '../../urls/urlPageCollections';
import urlPageCollectionPriority from '../../urls/urlPageCollectionPriority';
import urlPageFeeds from '../../urls/urlPageFeeds';
import urlPageResetStaticContentLogs from '../../urls/urlPageResetStaticContentLogs';



const pages = [
  {
    header: <IntlMessages id="sidebar-content" />,
    groupPermissions: [ Permissions.CONTENT.IMAGES.LIST ],
  },
  {
    id: 'content-feeds',
    title: <IntlMessages id="sidebar-content-feeds" />,
    icon: <PiCards size={18} />,
    groupPermissions: [
      Permissions.CONTENT.FEEDS.LIST,
      Permissions.CONTENT.FEEDS.PLANNING.BOARD.LIST,
      Permissions.CONTENT.FEEDS.RELEASED,
      Permissions.CONTENT.FEEDS.ARCHIVED,
      Permissions.CONTENT.FEEDS.TRASHED,
    ],
    children: [
      {
        id: 'content-feeds-planning-list',
        title: <IntlMessages id="sidebar-content-feeds-planning" />,
        navLink: urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.PLANNING }),
        permission: Permissions.CONTENT.FEEDS.PLANNING.BOARD.LIST,
      },
      {
        id: 'content-feeds-list',
        title: <IntlMessages id="ui-general-catalog" />,
        navLink: `${urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST })}?search=released_to%3A${moment().format(DATE_FORMAT)}`,
        permission: Permissions.CONTENT.FEEDS.LIST,
      },
      {
        id: 'content-feeds-released-list',
        title: <IntlMessages id="sidebar-content-feeds-released" />,
        navLink: `${urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.RELEASED })}?search=released_to%3A${moment().format(DATE_FORMAT)}`,
        permission: Permissions.CONTENT.FEEDS.RELEASED,
      },
      {
        id: 'content-feeds-archived-list',
        title: <IntlMessages id="sidebar-content-feeds-archived" />,
        navLink: urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.ARCHIVED }),
        permission: Permissions.CONTENT.FEEDS.ARCHIVED,
      },
      {
        id: 'content-feeds-trashed-list',
        title: <IntlMessages id="sidebar-content-feeds-trashed" />,
        navLink: urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.TRASHED }),
        permission: Permissions.CONTENT.FEEDS.TRASHED,
      },
    ],
  },
  {
    id: 'content-images',
    title: <IntlMessages id="sidebar-content-images" />,
    icon: <PiImagesSquare size={18} />,
    groupPermissions: [ Permissions.CONTENT.IMAGES.LIST, Permissions.CONTENT.IMAGES.RESERVE_STATISTICS.LIST ],
    children: [
      {
        id: 'content-images-index',
        title: <IntlMessages id="ui-general-catalog" />,
        navLink: urlPageImages(),
        permission: Permissions.CONTENT.IMAGES.LIST,
      },
      {
        id: 'content-content-reserve-statistics',
        title: <IntlMessages id="sidebar-content-reserve-statistics" />,
        navLink: urlPageImagesReserveStatistics(),
        permission: Permissions.CONTENT.IMAGES.RESERVE_STATISTICS.LIST,
      },
    ],
  },
  {
    id: 'content-categories',
    title: <IntlMessages id="sidebar-content-categories" />,
    icon: <BiCategoryAlt size={18} />,
    groupPermissions: [ Permissions.CONTENT.CATEGORIES.LIST ],
    children: [
      {
        id: 'content-categories-index',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageCategories(),
        permission: Permissions.CONTENT.CATEGORIES.LIST,
      },
      {
        id: 'content-categories-priorities',
        title: <IntlMessages id="sidebar-general-priority" />,
        navLink: urlPageCategoriesPriorities(),
        permission: Permissions.CONTENT.CATEGORIES.PRIORITIES.LIST,
      },
    ],
  },
  {
    id: 'content-bonuses',
    title: <IntlMessages id="sidebar-content-bonuses" />,
    icon: <PiGift size={18} />,
    navLink: urlPageBonuses(),
    permission: Permissions.CONTENT.BONUSES.LIST,
  },
  {
    id: 'content-achievements',
    title: <IntlMessages id="sidebar-content-achievements" />,
    icon: <PiMedal size={18} />,
    navLink: urlPageAchievements(),
    permission: Permissions.CONTENT.ACHIEVEMENTS.LIST,
  },
  {
    id: 'content-collections',
    title: <IntlMessages id="sidebar-content-collections" />,
    icon: <PiPackageLight size={18} />,
    groupPermissions: [ Permissions.CONTENT.COLLECTIONS.LIST ],
    children: [
      {
        id: 'content-collections-index',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageCollections(),
        permission: Permissions.CONTENT.COLLECTIONS.LIST,
      },
      {
        id: 'collections-priorities',
        title: <IntlMessages id="sidebar-general-priority" />,
        navLink: urlPageCollectionPriority(),
        permission: Permissions.CONTENT.COLLECTIONS.PRIORITIES.LIST,
      },
      {
        id: 'collections-dictionaries',
        title: <IntlMessages id="ui-general-dictionaries" />,
        navLink: urlPageDictionaries({
          urlPrefix: COLLECTIONS_SOURCE,
          source: COLLECTIONS_SOURCE,
          multipleSources: COLLECTIONS_SOURCES,
        }),
        permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'content-dynamic-news',
    title: <IntlMessages id="sidebar-featured-news" />,
    icon: <PiNewspaperLight size={18} />,
    groupPermissions: [ Permissions.CONTENT.NEWS.LIST ],
    children: [
      {
        id: 'content-dynamic-news-list',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageDynamicNews(),
        permission: Permissions.CONTENT.NEWS.LIST,
      },
      {
        id: 'content-dynamic-news-priority',
        title: <IntlMessages id="sidebar-general-priority" />,
        navLink: urlPageDynamicNewsPriority(),
        permission: Permissions.CONTENT.NEWS.PRIORITY,
      },
      {
        id: 'content-dynamic-news-dictionaries',
        title: <IntlMessages id="ui-general-dictionaries" />,
        navLink: urlPageDictionaries({ urlPrefix: NEWS_DICT_STRUCTURE, source: NEWS_DICT_STRUCTURE }),
        permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
      },
    ],
  },
  ...FEATURE_SHOW_OLD_NEWS ? [ {
    id: 'news',
    title: <IntlMessages id="sidebar-featured-old-news" />,
    icon: <PiNewspaperLight size={18} />,
    groupPermissions: [ Permissions.CONTENT.NEWS.LIST ],
    children: [
      {
        id: 'news-list',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageNews(),
        permission: Permissions.CONTENT.NEWS.LIST,
      },
      {
        id: 'news-priority',
        title: <IntlMessages id="sidebar-general-priority" />,
        navLink: urlPageNewsPriority(),
        permission: Permissions.CONTENT.NEWS.PRIORITY,
      },
    ],
  } ] : [],
  {
    header: <IntlMessages id="sidebar-featured" />,
    groupPermissions: [
      Permissions.FEATURED.FEATURES.DAILY.LIST,
      Permissions.FEATURED.FEATURES.EDITOR_CHOICE.LIST,
      Permissions.FEATURED.FEATURES.EXTERNAL_PROMO.LIST,
      Permissions.FEATURED.FEATURES.PRIORITY.LIST,
    ],
  },
  {
    id: 'daily',
    title: <IntlMessages id="sidebar-featured-daily" />,
    icon: <PiCloudSunLight size={18} />,
    groupPermissions: Permissions.FEATURED.FEATURES.DAILY.LIST,
    children: [
      {
        id: 'daily-list',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageFeatures({ featureSource: FEATURES_SOURCE_MAP.DAILY }),
        permission: Permissions.FEATURED.FEATURES.DAILY.LIST,
      },
      {
        id: 'daily-dictionaries',
        title: <IntlMessages id="ui-general-dictionaries" />,
        navLink: urlPageDictionaries({ urlPrefix: FEATURES_SOURCE_MAP.DAILY, source: FEATURES_SOURCE_MAP.DAILY }),
        permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'editors-choice',
    title: <IntlMessages id="sidebar-featured-editors-choice" />,
    icon: <PiSealCheck size={18} />,
    groupPermissions: Permissions.FEATURED.FEATURES.EDITOR_CHOICE.LIST,
    children: [
      {
        id: 'editors-choice-list',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageFeatures({ featureSource: FEATURES_SOURCE_MAP.EDITOR_CHOICE }),
        permission: Permissions.FEATURED.FEATURES.EDITOR_CHOICE.LIST,
      },
      {
        id: 'editors-choice-dictionaries',
        title: <IntlMessages id="ui-general-dictionaries" />,
        navLink: urlPageDictionaries({ urlPrefix: FEATURES_SOURCE_MAP.EDITOR_CHOICE, source: FEATURES_SOURCE_MAP.EDITOR_CHOICE }),
        permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'external-promo',
    title: <IntlMessages id="sidebar-featured-external-promo" />,
    icon: <PiRocketLaunchLight size={18} />,
    groupPermissions: Permissions.FEATURED.FEATURES.EXTERNAL_PROMO.LIST,
    children: [
      {
        id: 'external-promo-list',
        title: <IntlMessages id="ui-general-list" />,
        navLink: urlPageFeatures({ featureSource: FEATURES_SOURCE_MAP.EXTERNAL_PROMO }),
        permission: Permissions.FEATURED.FEATURES.EXTERNAL_PROMO.LIST,
      },
      {
        id: 'external-promo-dictionaries',
        title: <IntlMessages id="ui-general-dictionaries" />,
        navLink: urlPageDictionaries({ urlPrefix: FEATURES_SOURCE_MAP.EXTERNAL_PROMO, source: FEATURES_SOURCE_MAP.EXTERNAL_PROMO }),
        permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'featured-priorities',
    title: <IntlMessages id="sidebar-general-priority" />,
    icon: <PiQueue size={18} />,
    navLink: urlPageFeaturePriorities(),
    permission: Permissions.FEATURED.FEATURES.PRIORITY.LIST,
  },
  {
    header: <IntlMessages id="sidebar-other" />,
    groupPermissions: [
      Permissions.OTHER.PUSH_ICONS.LIST.VIEW,
      Permissions.OTHER.DEEPLINKS.LIST,
    ],
  },
  {
    id: 'push-icons',
    title: <IntlMessages id="sidebar-pages-push-icons" />,
    icon: <PiUpload size={18} />,
    navLink: urlPagePushIcons(),
    permission: Permissions.OTHER.PUSH_ICONS.LIST.VIEW,
  },
  {
    id: 'deeplinks',
    title: <IntlMessages id="sidebar-pages-deeplinks" />,
    icon: <PiLink size={18} />,
    navLink: urlPageDeeplinks(),
    permission: Permissions.OTHER.DEEPLINKS.LIST,
  },
  {
    id: 'popups',
    title: <IntlMessages id="sidebar-pages-popups" />,
    icon: <PiPaperPlaneTilt size={18} />,
    groupPermissions: [
      Permissions.OTHER.POPUPS.LIST,
      Permissions.OTHER.POPUPS.PRIORITY.LIST,
      Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
    ],
    children: [
      {
        id: 'popups-list',
        title: <IntlMessages id="sidebar-general-list" />,
        navLink: urlPagePopups(),
        permission: Permissions.OTHER.POPUPS.LIST,
      },
      {
        id: 'popups-priorities',
        title: <IntlMessages id="sidebar-general-priority" />,
        navLink: urlPagePopupsPriority(),
        permission: Permissions.OTHER.POPUPS.PRIORITY.LIST,
      },
      {
        id: 'popups-dictionaries',
        title: <IntlMessages id="ui-general-dictionaries" />,
        navLink: urlPageDictionaries({ urlPrefix: POPUPS_SOURCE, source: POPUPS_SOURCE }),
        permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
      },
    ],
  },
  {
    id: 'static-content-reset',
    title: <IntlMessages id="sidebar-pages-reset-static-content" />,
    icon: <PiArrowCounterClockwise size={18} />,
    navLink: urlPageResetStaticContentLogs(),
    permission: Permissions.OTHER.STATIC_CONTENT_RESET.LIST,
  },
  {
    header: <IntlMessages id="sidebar-template-constructors" />,
    groupPermissions: [
      Permissions.CONSTRUCTORS.TEMPLATES.LIST,
    ],
  },
  {
    id: 'template-constructors',
    title: <IntlMessages id="sidebar-templates" />,
    icon: <ImInsertTemplate size={14} />,
    navLink: urlPageTemplates(),
    permission: Permissions.CONSTRUCTORS.TEMPLATES.LIST,
  },
  {
    id: 'presets',
    title: <IntlMessages id="sidebar-presets" />,
    icon: <VscNotebookTemplate size={14} />,
    navLink: urlPagePresets(),
    permission: Permissions.CONSTRUCTORS.PRESETS.LIST,
  },
  {
    header: <IntlMessages id="sidebar-rbac" />,
    groupPermissions: [
      Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE,
      Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
      Permissions.ADMINISTRATION.PERMISSIONS.VIEW_LIST,
    ],
  },
  {
    id: 'users',
    title: <IntlMessages id="sidebar-pages-rbac-users" />,
    icon: <PiUsers size={18} />,
    navLink: urlPageRBACUsers(),
    permission: Permissions.ADMINISTRATION.USERS.USERS_LIST.VIEW_PAGE,
  },
  {
    id: 'roles',
    title: <IntlMessages id="ui-general-roles" />,
    icon: <PiUserCirclePlus size={18} />,
    navLink: urlPageRBACRoles(),
    permission: Permissions.ADMINISTRATION.ROLES.ROLES_LIST.VIEW_PAGE,
  },
  {
    id: 'permissions',
    title: <IntlMessages id="ui-general-permissions" />,
    icon: <PiLockKey size={18} />,
    navLink: urlPageRBACPermissions(),
    permission: Permissions.ADMINISTRATION.PERMISSIONS.VIEW_LIST,
  },
  {
    id: 'logs',
    title: <IntlMessages id="sidebar-general-logs" />,
    icon: <PiListMagnifyingGlass size={18} />,
    navLink: urlPageRBACLogs(),
    permission: Permissions.ADMINISTRATION.LOGS.LIST,
  },
  {
    header: <IntlMessages id="sidebar-translations" />,
    groupPermissions: [
      Permissions.TRANSLATIONS.LANGUAGES.LANGUAGES_LIST.VIEW_PAGE,
      Permissions.TRANSLATIONS.STRUCTURES.LIST.VIEW_PAGE,
    ],
  },
  {
    id: 'languages',
    title: <IntlMessages id="ui-general-languages" />,
    icon: <IoEarth size={18} />,
    navLink: urlPageLanguages(),
    permission: Permissions.TRANSLATIONS.LANGUAGES.LANGUAGES_LIST.VIEW_PAGE,
  },
  {
    id: 'structures',
    title: <IntlMessages id="ui-general-structures" />,
    icon: <PiTreeStructure size={18} />,
    navLink: urlPageStructures(),
    permission: Permissions.TRANSLATIONS.STRUCTURES.LIST.VIEW_PAGE,
  },
  {
    id: 'dictionaries',
    title: <IntlMessages id="ui-general-dictionaries" />,
    icon: <PiTranslate size={18} />,
    navLink: urlPageDictionaries(),
    permission: Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE,
  },
];

export default pages;
