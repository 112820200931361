import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Modal, Popconfirm, Row, Space } from 'antd';
import { useIntl } from 'react-intl';
import { RiArrowLeftLine, RiErrorWarningLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import urlPageImages from '../../../../../urls/urlPageImages';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import urlPageImagesReserveStatistics from '../../../../../urls/urlPageImagesReserveStatistics';
import urlPageImagesReserveStatisticsReference from '../../../../../urls/urlPageImagesReserveStatisticsReference';
import urlPageImagesReserveStatisticsReferenceEdit from '../../../../../urls/urlPageImagesReserveStatisticsReferenceEdit';
import ActionButton from '../../../../../layout/components/action-button';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import DocumentationTooltip from '../../../../../components/DocumentationTooltip/DocumentationTooltip';
import ReserveStatisticsTable from '../../../components/ReserveStatisticsTable';
import { useGetReserveStatisticsQuery, useUpdateReserveStatisticsReferenceMutation } from '../../../api/reserveStatisticsApiSlice';
import handleResponse from '../../../../../lib/handleResponse';
import { useImagesReserveStatisticContext } from '../../../contexts/ImagesReserveStatisticContext';



const PageImagesReserveStatisticsReference = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ initialData, setInitialData ] = useState([]);
  const [ isRedirectConfirmationModalOpen, setConfirmationModalOpen ] = useState(false);
  const [ navigationUrl, setNavigationUrl ] = useState('');
  const { referenceData, initialReferenceData } = useImagesReserveStatisticContext();

  const isEditing = pathname.endsWith('edit');

  const { data: {
    data: reserveStatisticsData = [],
  } = { data: [], pagination: {} }, isFetching } = useGetReserveStatisticsQuery();

  const [
    updateReserveStatisticsReference,
    { isLoading: isUpdatingReserveStatisticsReference },
  ] = useUpdateReserveStatisticsReferenceMutation();

  useEffect(() => {
    if (!isEmpty(reserveStatisticsData) && !isFetching) {
      setInitialData(reserveStatisticsData);
    }
  }, [ reserveStatisticsData, isFetching ]);

  const handleSave = () => {
    updateReserveStatisticsReference({ payload: { items: referenceData } })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => {
            if (!isEmpty(response.data)) {
              setInitialData(response.data);
            }
          },
          () => navigate(urlPageImagesReserveStatisticsReference()),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        error,
      ));
  };

  const handleClickOnBreadcrumbInEditing = (event, url) => {
    event.preventDefault();

    if (isEditing && !isEqual(initialReferenceData, referenceData)) {
      setConfirmationModalOpen(true);
      setNavigationUrl(url);
    } else {
      navigate(url);
    }
  };

  return (
    <>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isRedirectConfirmationModalOpen}
        onOk={() => {
          setConfirmationModalOpen(false);
          navigate(navigationUrl);
        }}
        onCancel={() => setConfirmationModalOpen(false)}
      >
        <IntlMessages id="ui-general-leave-changes-confirm-message" />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-16"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(event, urlPageImages())}
              to={urlPageImages()}
            >
              <IntlMessages id='images-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(event, urlPageImagesReserveStatistics())}
              to={urlPageImagesReserveStatistics()}
            >
              <IntlMessages id='images-reserve-statistics-breadcrumbs' />
            </Link>
          }
          breadCrumbParent3={isEditing ?
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(event, urlPageImagesReserveStatisticsReference())}
              to={urlPageImagesReserveStatisticsReference()}
            >
              <IntlMessages id='images-reserve-statistics-view-reference' />
            </Link> : null}
          breadCrumbActive={
            <div>
              <IntlMessages id={!isEditing ? 'images-reserve-statistics-view-reference' : 'ui-general-edit'} />
              <DocumentationTooltip link='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/1174405123/Reserve+statistics+benchmarks' />
            </div>
          }
        />

        <Col>
          <Space>
            {!isEqual(initialReferenceData, referenceData) ? (
              <Popconfirm
                title={<IntlMessages id="ui-general-cancel-confirmation" />}
                placement="top"
                onConfirm={() => navigate(isEditing ? urlPageImagesReserveStatisticsReference() : urlPageImagesReserveStatistics())}
                okText={<IntlMessages id="ui-general-ok" />}
                cancelText={<IntlMessages id="ui-general-cancel" />}
                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                okButtonProps={{ danger: true }}
              >
                <Button
                  className='hp-mr-8'
                  icon={!isEditing && <RiArrowLeftLine className="remix-icon hp-mr-8" />}
                >
                  <IntlMessages id={isEditing ? 'ui-general-cancel' : 'ui-general-back-to-view'} />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                onClick={() => navigate(isEditing ? urlPageImagesReserveStatisticsReference() : urlPageImagesReserveStatistics())}
                className='hp-mr-8'
                icon={!isEditing && <RiArrowLeftLine className="remix-icon hp-mr-8" />}
              >
                <IntlMessages id={isEditing ? 'ui-general-cancel' : 'ui-general-back-to-view'} />
              </Button>
            )}

            {!isEditing ? (
              <PrivateComponent allowedPermissions={[ Permissions.CONTENT.IMAGES.RESERVE_STATISTICS.EDIT_REFERENCES ]}>
                <ActionButton
                  className=''
                  onClick={() => navigate(urlPageImagesReserveStatisticsReferenceEdit())}
                  title={intl.formatMessage({ id: 'ui-general-edit' })}
                />
              </PrivateComponent>
            ) : (
              <ActionButton
                loading={isUpdatingReserveStatisticsReference}
                disabled={isEqual(initialReferenceData, referenceData)}
                onClick={handleSave}
                title={intl.formatMessage({ id: 'ui-general-save' })}
              />
            )}
          </Space>
        </Col>

      </Row>

      <Row gutter={[ 16, 16 ]}>
        <Col className='hp-mb-16 hp-text-color-black-80 hp-text-color-dark-60'>
          <IntlMessages id='images-reserve-statistics-reference-notification' />
        </Col>
      </Row>

      <Row gutter={[ 16, 16 ]}>
        <Col span={24}>
          <ReserveStatisticsTable
            isReferenceTable
            isEdit={isEditing}
            isLoading={isFetching || isUpdatingReserveStatisticsReference}
            initialData={initialData}
            monthReserveValue={1}
          />
        </Col>
      </Row>
    </>
  );
};

export default PageImagesReserveStatisticsReference;
