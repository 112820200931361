import React from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Tooltip } from 'antd';
import { Form, FormItem, Input, ResetButton, SubmitButton, Select } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import { useGetTemplatesQuery } from '../../../Template builder/api/templatesApiSlice';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import mkTemplatesOptions from '../../utils/mkTemplatesOptions';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import { DATE_FORMAT, confirmationOptions } from '../../../../const/system';
import { NEWS_TEMPLATE_CONTENT_TYPE } from '../FormCreateDynamicNews/FormCreateDynamicNews.const';



const validationSchema = Yup.object().shape({
  id: Yup.string()
    .min(1, 'Minimum 1 symbol required'),
  title: Yup.string(),
  is_published: Yup.string(),
  template: Yup.string(),
  template_key_value: Yup.string(),
  released_at: Yup.date(),
  released_to: Yup.date(),
});

const FormNewsFilter = ({
  initialValues,
  formErrors = {},
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();
  const {
    data: { data: templates } = { data: [] },
    isFetching: isFetchingTemplates,
  } = useGetTemplatesQuery({
    queryParams: `limit=0&search=is_used:1;content_type:${NEWS_TEMPLATE_CONTENT_TYPE}`,
  });

  const { onBlur } = useDatepickerHandler();

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialErrors={formErrors}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onCancel}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form layout="vertical" >
            <FormItem
              name='id'
              className="ant-form-item-col stretched"
              label={
                <Row className='w-full' justify="space-between" align="middle">
                  <IntlMessages id="ui-general-id" />
                  <Tooltip placement="topRight" title={<IntlMessages id="ui-general-filter-id-key-help" />}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              }
            >
              <Input
                name="id"
                placeholder={intl.formatMessage({ id: 'dynamic-news-form-id-placeholder' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'ui-general-title' })}
              name='title'
            >
              <Input
                name="title"
                placeholder={intl.formatMessage({ id: 'dynamic-news-form-title-placeholder' })}
              />
            </FormItem>

            <FormItem
              label={intl.formatMessage({ id: 'ui-general-published' })}
              name='is_published'
            >
              <Select
                allowClear
                name="is_published"
                value={values?.is_published?.toString() ?? null}
                options={confirmationOptions}
                placeholder={intl.formatMessage({ id: 'ui-general-choose-status' })}
              />
            </FormItem>

            <PrivateComponent allowedPermissions={[ Permissions.CONSTRUCTORS.TEMPLATES.FILTER ]}>
              <FormItem
                label={intl.formatMessage({ id: 'ui-general-template' })}
                name='template'
              >
                <Select
                  allowClear
                  showSearch
                  loading={isFetchingTemplates}
                  name="template"
                  value={values?.template?.toString() ?? null}
                  options={mkTemplatesOptions(templates)}
                  placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                  filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                />
              </FormItem>
            </PrivateComponent>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-released-at' })}
                  name='released_at'
                >
                  <DatePicker
                    name='released_at'
                    format={DATE_FORMAT}
                    showToday
                    value={values?.released_at ? moment(values.released_at) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('released_at', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('released_at', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-released-to' })}
                  name='released_to'
                >
                  <DatePicker
                    name='released_to'
                    format={DATE_FORMAT}
                    value={values?.released_to ? moment(values.released_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('released_to', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('released_to', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-apply" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormNewsFilter.propTypes = {
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormNewsFilter;
