import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';



export const TagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getTags: builder.query({
        query: () => ({ url: `/${Config.VITE_HC_API_VERSION}/tags` }),
        transformResponse: (response) => {
          return { data: response.data };
        },
        providesTags: [ 'Tags' ],
      }),
    });
  },
});

export const {
  useGetTagsQuery,
} = TagsApiSlice;
