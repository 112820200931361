import PropTypes from 'prop-types';
import { Col, Divider, Modal, Row, Space, Spin, Tabs, Typography } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Filter } from 'iconsax-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useCallback, useEffect, useState } from 'react';
import { FEEDS_SOURCE_MAP, getFeedsSourceTranslation, searchFilterFields } from '../../pages/Feeds/PageFeeds.const';
import urlPageFeeds from '../../../../urls/urlPageFeeds';
import { Permissions } from '../../../../const/permissions';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import resetFilter from '../../../../lib/resetFilter';
import applyFilter from '../../../../lib/applyFilter';
import getFeedsNotificationLink from '../../utils/getFeedsNotificationLink';
import getModalWidthByModalMode from '../../../../lib/getModalWidthByModalMode';
import getModalTitleByModalMode from '../../../../lib/getModalTitleByModalMode';
import { hasRights } from '../../../../components/HasRights/HasRights';
import ActionButton from '../../../../layout/components/action-button';
import Sidebar from '../../../../components/Sidebar';
import FormFeedsFilter from '../../forms/FormFeedsFilter';
import ImageModalCard from '../ImageModalCard';
import BreadCrumbs from '../../../../layout/components/breadcrumbs';
import Spinner from '../../../../components/Spinner';
import ContentCard from '../ContentCard';
import EmptyDataPlaceholder from '../../../../components/EmptyDataPlaceholder';
import DocumentationTooltip from '../../../../components/DocumentationTooltip/DocumentationTooltip';
import ImageModalCardTags from '../ImageModalCard/ImageModalCardTags';



const { Title } = Typography;
const { TabPane } = Tabs;


const FeedsLayout = ({
  feedSource = '',
  modalMode = '',
  showContour = false,
  toggleShowContour = () => {},
  isVisibleFilterSidebar = false,
  toggleFilterSidebar = () => {},
  previewModalOpen = false,
  handlePreviewModalClose = () => {},
  previewImage = {},
  handleUpdateListItem = (_id, _values = {}) => {},
  handleChangeModalMode = (_mode) => {},
  formErrors = {},
  initFilterValues = {},
  handleSetFilterToCurrentDate = () => {},
  searchQueryParams,
  setSearchParams = () => {},
  setInitFilterValues = () => {},
  setResetManuallyTriggered = () => {},
  resetIsManuallyTriggered = false,
  isLoading = false,
  groupedItemsList = {},
  itemsArray = [],
  itemsLength = 0,
  handleLoadNextData = () => {},
  hasMore = false,
  actions = () => {},
  handleCardClick = (_item) => {},
}) => {
  const isTrashOrArchiveSource = [ FEEDS_SOURCE_MAP.ARCHIVED, FEEDS_SOURCE_MAP.TRASHED ].includes(feedSource);

  const [ filteredGroupsItemList, setFilteredGroupsItemList ] = useState({});
  const [ activeModalTab, setActiveModalTab ] = useState('general-info');

  useEffect(() => {
    const filteredObj = {};

    if (!isEmpty(groupedItemsList)) {
      Object.entries(groupedItemsList)?.forEach(([ key, items ]) => {
        const areAllElementsHidden = items.every((item) => item.isHidden);

        if (!areAllElementsHidden) {
          filteredObj[key] = items;
        }
      });
    }
    setFilteredGroupsItemList(filteredObj);

  }, [ groupedItemsList ]);

  const onCardClick = useCallback((item) => {
    handleCardClick(item);
    setActiveModalTab('general-info');
  }, [ itemsArray ]);

  return (
    <>
      <Modal
        className='feed-modal'
        width={getModalWidthByModalMode(modalMode)}
        title={getModalTitleByModalMode(modalMode)}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <Tabs
          type="card"
          activeKey={activeModalTab}
          onChange={(key) => setActiveModalTab(key)}
        >
          <TabPane tab={<IntlMessages id='ui-general-info' />} key="general-info">
            <ImageModalCard
              data={previewImage}
              modalMode={modalMode}
              handleModalClose={handlePreviewModalClose}
              handleUpdateListItem={(imageId, values = {}) => handleUpdateListItem(imageId, values)}
              handleModalMode={(mode) => handleChangeModalMode(mode)}
              initialEditImageErrors={{}}
              editDisabled
            />
          </TabPane>

          {hasRights([
            Permissions.CONTENT.IMAGES.TAGS.LIST,
            Permissions.CONTENT.IMAGES.TAGS.UPDATE,
          ]) && (
            <TabPane
              key="tags-info"
              tab={<IntlMessages id='ui-general-tags' />}
            >
              {activeModalTab === 'tags-info' && (
                <ImageModalCardTags previewData={previewImage} />
              )}
            </TabPane>
          )}
        </Tabs>
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={feedSource !== FEEDS_SOURCE_MAP.LIST ? (
            <Link to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST })}>
              <IntlMessages id='sidebar-content-feeds' />
            </Link>
          ) : ''}
          breadCrumbActive={
            <div>
              <IntlMessages id={getFeedsSourceTranslation(feedSource)} />
              <DocumentationTooltip link={getFeedsNotificationLink(feedSource)} />
            </div>
          }
        />

        <Col>
          <Space>
            <ActionButton
              onClick={toggleShowContour}
              title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
            />
            <ActionButton
              title=""
              isActive={!isEmpty(searchQueryParams.toString())}
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          key={feedSource}
          title={<Title level={5}><IntlMessages id="ui-general-filter" /></Title>}
          visible={isVisibleFilterSidebar}
          toggleSidebar={toggleFilterSidebar}
        >
          <FormFeedsFilter
            feedSource={feedSource}
            isSubmitting={isLoading}
            formErrors={formErrors}
            initialValues={initFilterValues}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
                resetIsManuallyTriggered,
                callbacks: [ () => {
                  if (isEmpty(initFilterValues) && !isTrashOrArchiveSource) {
                    handleSetFilterToCurrentDate();
                  }
                } ],
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      {(isLoading && isEmpty(filteredGroupsItemList)) ? (
        <div className="spin-overlay">
          <div className="spin-wrapper">
            <Spinner spinning={isLoading} />
          </div>
        </div>
      ) : (
        <div id="scrollableDiv" style={{ height: window.innerHeight - 225, overflow: 'auto' }}>
          {!isEmpty(filteredGroupsItemList) ? (
            <InfiniteScroll
              dataLength={itemsLength}
              next={handleLoadNextData}
              scrollableTarget="scrollableDiv"
              scrollThreshold='200px'
              hasMore={hasMore}
              loader={isLoading && (
                <div className='centered-item'><Spin /></div>
              )}
            >
              {!isTrashOrArchiveSource ? (
                <>
                  {Object.keys(filteredGroupsItemList).map((date) => {
                    return (
                      <div key={date} className='hp-mb-32'>
                        <h4>{date}</h4>
                        <Divider />
                        <div className='content-card-grid'>
                          {filteredGroupsItemList[date].map((item) => (
                            <ContentCard
                              key={item.id}
                              data={item}
                              showContour={showContour}
                              actions={actions(item)}
                              onClick={() => onCardClick(item)}
                              isHidden={item.isHidden}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className='content-card-grid hp-mb-32'>
                  {itemsArray.map((item) => (
                    <ContentCard
                      key={item.id}
                      data={item}
                      showContour={showContour}
                      onClick={handleCardClick}
                      actions={actions(item)}
                      isHidden={item.isHidden}
                    />
                  ))}
                </div>
              )}
            </InfiniteScroll>
          ) : (
            <div className='hp-mt-64'>
              <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

FeedsLayout.propTypes = {
  feedSource: PropTypes.string.isRequired,
  modalMode: PropTypes.string.isRequired,
  previewModalOpen: PropTypes.bool.isRequired,
  handlePreviewModalClose: PropTypes.func.isRequired,
  previewImage: PropTypes.object,
  handleUpdateListItem: PropTypes.func.isRequired,
  handleChangeModalMode: PropTypes.func.isRequired,
  handleSetFilterToCurrentDate: PropTypes.func,
  setResetManuallyTriggered: PropTypes.func.isRequired,
  resetIsManuallyTriggered: PropTypes.bool.isRequired,
  handleLoadNextData: PropTypes.func.isRequired,
  handleCardClick: PropTypes.func.isRequired,
  setSearchParams: PropTypes.func.isRequired,
  setInitFilterValues: PropTypes.func.isRequired,
  actions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
  formErrors: PropTypes.object,
  groupedItemsList: PropTypes.object,
  initFilterValues: PropTypes.object.isRequired,
  searchQueryParams: PropTypes.any,
  itemsArray: PropTypes.array,
  itemsLength: PropTypes.number,
  showContour: PropTypes.bool.isRequired,
  toggleShowContour: PropTypes.func.isRequired,
  isVisibleFilterSidebar: PropTypes.bool.isRequired,
  toggleFilterSidebar: PropTypes.func.isRequired,
};

export default FeedsLayout;
