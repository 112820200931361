import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import { TYPE_BOOLEAN } from '../../Template builder/const/templates';



const fileProp = 'originFileObj';

const mkTemplateDynamicFormData = (values, language, fields = []) => {
  const clonedValues = cloneDeep(values);
  const formData = new FormData();

  Object.entries(clonedValues).forEach(([ key, value ]) => {
    if (key === 'data') {
      Object.entries(value)
        .forEach(([ innerKey, innerValue ]) => {
          const fieldObj = fields.find((field) => field.full_name === innerKey);
          const fieldObjKey = fieldObj.full_name;

          if (innerValue.hasOwnProperty('template_field_id') && innerValue.hasOwnProperty('value')) {
            formData.append(`${key}[${fieldObjKey}][template_field_id]`, innerValue.template_field_id);
            if (Array.isArray(innerValue.value) && innerValue.value[0]?.hasOwnProperty(fileProp)) {
              formData.append(`${key}[${fieldObjKey}][value]`, innerValue.value[0][fileProp]);
            } else if (Array.isArray(innerValue.value) && !innerValue.value[0]?.hasOwnProperty(fileProp) && innerValue.value[0]?.hasOwnProperty('url')) {
              formData.append(`${key}[${fieldObjKey}][value]`, null);
            } else if (Array.isArray(innerValue.value)) {
              if (!innerValue.value.length) {
                formData.append(`${key}[${fieldObjKey}][value]`, null);
              } else {
                innerValue.value?.forEach(((item) => formData.append(`${key}[${fieldObjKey}][value][]`, item)));
              }
            } else {
              const valueType = fields.find((field) => {
                return field.id === innerValue.template_field_id;
              })?.type;

              if (valueType && valueType === TYPE_BOOLEAN && innerValue.value === null) {
                innerValue.value = false;
              }

              formData.append(`${key}[${fieldObjKey}][value]`, innerValue.value);
            }
          }
        });
    } else if (key === 'target') {
      Object.entries(value)
        .forEach(([ fieldObjKey, innerValue ]) => {
          if (isArray(innerValue)) {
            innerValue.forEach((item) => formData.append(`${key}[${fieldObjKey}][]`, item));
          } else {
            formData.append(`${key}[${fieldObjKey}]`, innerValue);
          }
        });
    } else if (Array.isArray(value)) {
      if (value[0]?.hasOwnProperty(fileProp)) {
        formData.append(key, value[0][fileProp]);
      } else if (!value[0]?.hasOwnProperty(fileProp) && value[0]?.hasOwnProperty('url')) {
        formData.append(key, null);
      } else {
        value.forEach((item) => formData.append(`${key}[]`, item));
      }
    } else {
      if (value === '') {
        formData.append(key, null);
      } else {
        formData.append(key, value);
      }
    }
  });

  return formData;
};

export default mkTemplateDynamicFormData;
