const static_content_reset = {
  'static-content-reset-logs-breadcrumbs': 'Reset static content',
  'static-content-reset-logs-table-type-col': 'Reset type',
  'static-content-reset-logs-table-type-reset-daily': 'Reset daily static data',
  'static-content-reset-logs-table-type-reset-feed': 'Reset feed static data',
  'static-content-reset-logs-btn-reset-daily': 'Reset daily on CDN',
  'static-content-reset-logs-btn-reset-feed': 'Reset feed on CDN',
  'static-content-reset-logs-btn-reset-feed-confirm': 'Reset feed static data?',
  'static-content-reset-logs-btn-reset-daily-confirm': 'Reset daily static data?',
  'static-content-reset-successfully': 'Static data successfully reset',
  'static-content-reset-failed': 'Static data reset failed',
};

export default static_content_reset;
