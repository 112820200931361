import React from 'react';
import { Row, Tag, Tooltip } from 'antd';
import { PiPencil } from 'react-icons/pi';
import { Eye } from 'iconsax-react';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import urlPageStructuresEdit from '../../../../../urls/urlPageStructuresEdit';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const getListTableColumns = (handleView) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '80px',
    sorter: true,
  },
  {
    title: <IntlMessages id="structures-source-field" />,
    align: 'center',
    dataIndex: 'source',
    width: '100px',
    render: (item) => <IntlMessages id={`structures-source-${item}`} />,
  },
  {
    title: <IntlMessages id="structures-version-field" />,
    align: 'center',
    dataIndex: 'version',
    width: '100px',
    render: (item) => <Tag color='blue' >{item}</Tag>,
  },
  {
    title: <IntlMessages id="ui-general-description" />,
    align: 'center',
    dataIndex: 'description',
    width: '100px',
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    width: '150px',
    render: (value) => getFormattedDate(value),
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: '90px',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.TRANSLATIONS.LANGUAGES.LANGUAGES_LIST.EDIT ]) && (
            <>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
                <div className="hp-text-right">
                  <Eye
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-success-1 hp-text-color-black-80 hp-m-4"
                    onClick={() => {
                      handleView(item);
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
                <div className="hp-text-right">
                  <Link to={urlPageStructuresEdit({ id: item.id })}>
                    <PiPencil
                      size={20}
                      className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                    />
                  </Link>
                </div>
              </Tooltip>
            </>
          )}
        </Row>
      );
    },
  },
];
