import isString from 'lodash/isString';



const getUrlPathnamePrefix = (pathname) => {
  if (!pathname || !isString(pathname)) {
    return null;
  }

  const pathSource = pathname.split('/')[1];

  return pathSource !== 'dictionaries' ? pathSource : null;
};

export default getUrlPathnamePrefix;
