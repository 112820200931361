import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';



export const StaticContentResetLogsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getStaticContentResetLogs: builder.query({
        query: () => {
          return ({ url: `/${Config.VITE_HC_API_VERSION}/static-content-reset-logs` });
        },
        transformResponse: (response) => {
          return { data: response.data };
        },
        providesTags: [ 'StaticContentReset' ],
        keepUnusedDataFor: 1,
      }),
      resetStaticContent: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/static-content-reset`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'StaticContentReset' ],
      }),
    });
  },
});

export const {
  useGetStaticContentResetLogsQuery,
  useResetStaticContentMutation,
} = StaticContentResetLogsApiSlice;
