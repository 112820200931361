import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';
import navigation from '../../../../navigation/vertical';
import { useApp } from '../../../../app/context/AppContext';
import { findOpenedKeys, findSelectedKeys, getPathnameWithoutQuery } from './index.const';



const { SubMenu } = Menu;

const MenuItem = (props) => {
  // eslint-disable-next-line react/prop-types
  const { onClose } = props;
  const { userPermissions } = useApp();
  const menuVisibleItems = navigation.filter((menuItem) => userPermissions?.some((permission) => !isEmpty(menuItem) ? menuItem?.groupPermissions?.includes(permission) || menuItem.permission === permission : null));

  // Redux
  const customise = useSelector((state) => state.customise);

  // Location
  const { pathname } = useLocation();
  const currentLocation = getPathnameWithoutQuery(pathname);
  const defaultSelectedKey = findSelectedKeys(currentLocation, menuVisibleItems) || '';
  const defaultOpenKeys = findOpenedKeys(menuVisibleItems, defaultSelectedKey);

  const menuItem = menuVisibleItems.map((item, index) => {
    if (item.header) {
      // eslint-disable-next-line react/no-array-index-key
      return <Menu.ItemGroup key={index} title={item.header} />;
    }

    if (item.children) {
      return (
        <SubMenu key={item.id} icon={item.icon} title={item.title}>
          {item.children.map((itemChildren) => {
            const isVisible = userPermissions?.includes(itemChildren.permission);

            if (!isVisible) {
              return null;
            }

            if (!itemChildren.children) {
              const itemSelected = currentLocation === getPathnameWithoutQuery(itemChildren.navLink) || itemChildren.id === defaultSelectedKey;

              return (
              // Level 2
                <Menu.Item
                  key={itemChildren.id}
                  onClick={onClose}
                  className={itemSelected ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'}
                >
                  <Link to={itemChildren.navLink}>{itemChildren.title}</Link>
                </Menu.Item>
              );
            }
            return (
            // Level 3
              <SubMenu key={itemChildren.id} title={itemChildren.title}>
                {itemChildren.children.map((childItem) => {
                  const itemSelected = currentLocation === getPathnameWithoutQuery(childItem.navLink) || childItem.id === defaultSelectedKey;

                  return (
                    <Menu.Item
                      key={childItem.id}
                      className={itemSelected ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'}
                      onClick={onClose}
                    >
                      <Link to={childItem.navLink}>{childItem.title}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
        </SubMenu>
      );
    }
    const itemSelected = currentLocation === getPathnameWithoutQuery(item.navLink) || item.id === defaultSelectedKey;

    return (
    // Level 1
      <Menu.Item
        key={item.id}
        icon={item.icon}
        onClick={onClose}
        className={itemSelected ? 'ant-menu-item-selected' : 'ant-menu-item-selected-in-active'}
        style={item.tag && { pointerEvents: 'none' }}
      >
        {item.tag ? (
          <a href="#123" className="hp-d-flex hp-align-items-center hp-d-flex-between">
            <span>{item.title}</span>
            <Tag
              className="hp-mr-0 hp-border-none hp-text-color-black-100 hp-bg-success-3 hp-border-radius-full hp-px-8"
              style={{ marginRight: -14 }}
            >
              {item.tag}
            </Tag>
          </a>
        ) : (
          <Link to={item.navLink}>{item.title}</Link>
        )}
      </Menu.Item>
    );
  });

  return (
    <Menu
      mode="inline"
      theme={customise.theme}
      className="hp-bg-black-20 hp-bg-dark-90"
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={[ defaultSelectedKey ]}
      selectedKeys={[ defaultSelectedKey ]}
    >
      {menuItem}
    </Menu>
  );
};

export default MenuItem;
