import React from 'react';
import { Alert, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import ImageModalCardHeader from '../ImageModalCardHeader';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';



const ImageModalCardBodyUnpublish = ({ data, preview }) => {
  return (
    <>
      <ImageModalCardHeader data={data} />
      <Row gutter={[ 32, 32 ]} justify="left" className='hp-my-24'>
        <Col xs={24} md={8}>
          <img alt="preview" src={preview} />
        </Col>
        <Col xs={24} md={16}>
          {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED ]) && (
            <Alert
              className='hp-p-16 hp-mt-8'
              message={<IntlMessages id='feeds-modal-unpublish-archive-text' />}
              type="warning"
              showIcon
            />
          )}
          {HasRights([ Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED ]) && (
            <Alert
              className='hp-p-16 hp-mt-8'
              message={<IntlMessages id='feeds-modal-unpublish-trash-text' />}
              type="error"
              showIcon
            />
          )}
        </Col>
      </Row>
    </>
  );
};

ImageModalCardBodyUnpublish.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  preview: PropTypes.string.isRequired,
};

export default ImageModalCardBodyUnpublish;
