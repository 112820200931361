import Config from '../configs/appConfig';



export const getBeePanelEnvUrl = () => {
  switch (Config.VITE_APP_ENVIRONMENT) {
    case 'staging': return 'https://bee-freelancer-stage.xfin.net';
    case 'production': return 'https://bee-freelancer.app';
    default: return 'https://bee-freelancer-dev.xfin.net';
  }
};
