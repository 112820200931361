import { Card } from 'antd';
import React from 'react';
import capitalize from 'lodash/capitalize';
import { imageTypes } from '../../../../../const/imageTypes';
import getInitialImageCategoriesValues from '../../../../../lib/getInitialImageCategoriesValues';
import { validationSchema } from '../../../forms/FormEditImage/FormEditImage.const';



const rareFormatIndices = [ 4, 8 ];

const tapsLimitMapping = {
  '<700': [ 1, 5, 9, 12, 13 ],
  '700-999': [ 2, 6, 10, 14 ],
  '1000+': [ 3, 7, 11 ],
  '580-699': [ 15 ],
};

export const getTapsLimit = (index) => Object.keys(tapsLimitMapping).find((key) => tapsLimitMapping[key].includes(index)) || '';

export const getCardFormat = (index) => rareFormatIndices.includes(index)
  ? `Rare (${capitalize(imageTypes.cargo)})` : '';

export const getDefaultEmptyCards = (
  hasActionButton = false,
  startIndex,
  totalImages,
  isEditing = false,
  generateEmptySlots = false,
) => {
  const emptyCards = [];
  const totalCards = Math.max(15, totalImages + 1);

  // eslint-disable-next-line fp/no-loops
  for (let i = startIndex; i < totalCards; i++) {
    const index = generateEmptySlots ? i + 1 : i;
    const tapsLimit = getTapsLimit(index);
    const format = getCardFormat(index);

    emptyCards.push(
      <Card
        key={`default-${index}`}
        style={{ display: generateEmptySlots && startIndex % 2 === 0 && index > 15 ? 'none' : 'flex' }}
        className={`content-card content-image content-image--empty 
          ${isEditing && hasActionButton ? 'content-image--empty__action' : ''}`}
      >
        <span className="content-image__serial-number">
          <strong>{index}</strong>
        </span>
        <span className="content-image__help-label">
          {tapsLimit}
          {format}
        </span>
      </Card>,
    );
  }

  return emptyCards;
};


export const validateSelectedItems = async ({
  planningBoardImages,
  noLinesHashtagId,
  mysteryCategoryId,
  updateImageWithErrorsIds,
  updateInitialImagesErrors,
}) => {
  const errors = {};

  const validationPromises = planningBoardImages.map(async (item) => {
    try {
      const preparedItem = {
        hashtags: item?.tags?.data?.map((tag) => tag.id),
        publication_time: null,
        ...item,
        ...getInitialImageCategoriesValues(item),
      };

      const schema = validationSchema(
        item,
        noLinesHashtagId,
        mysteryCategoryId,
        true,
      );

      await schema.validate(preparedItem, { abortEarly: false });
    } catch (error) {
      if (error.inner) {
        errors[item.id] = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
      }
    }
  });

  await Promise.all(validationPromises);

  if (Object.keys(errors).length) {
    updateImageWithErrorsIds(Object.keys(errors).map((item) => Number(item)));
  }
  return updateInitialImagesErrors(errors);
};
