import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Badge, Col, Menu } from 'antd';
import { User } from 'react-iconly';
import PropTypes from 'prop-types';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import menuImg from '../../../../assets/images/profile/menu-img.svg';
import avatar from '../../../../assets/images/memoji/memoji-1.png';



const MenuProfile = ({ footer = 'none', onCloseDrawer = () => {}, moreBtn = null, profile = {} }) => {
  const { pathname } = useLocation();
  const splitLocation = pathname.split('/');
  const customise = useSelector((state) => state.customise);
  const menuIconClass = 'remix-icon hp-mr-8';


  const menuFooterItem = () => {
    if (footer !== 'none') {
      return (
        <div className="hp-profile-menu-footer">
          <img alt="profile" src={menuImg} />
        </div>
      );
    }

    return null;
  };


  return (
    <Col flex="300px" className="hp-profile-menu hp-py-24">
      <div className="hp-w-100">
        <div className="hp-profile-menu-header hp-mt-md-16 hp-text-center">
          <Col className="hp-menu-header-btn hp-pr-16 hp-mb-12 hp-text-right">
            {moreBtn}
          </Col>
          <Badge>
            <Avatar size={80} src={profile?.avatar ?? avatar} />
          </Badge>

          <h3 className="hp-mt-24 hp-mb-4">{profile?.name}</h3>
          <a href={`mailto:${profile?.email}`} className="hp-p1-body">
            {profile?.email ?? ''}
          </a>
        </div>

        <Menu
          mode="inline"
          className="hp-w-100 hp-profile-menu-body"
          theme={customise.theme === 'light' ? 'light' : 'dark'}
        >
          <Menu.Item
            key="1"
            icon={<User set="curved" className={menuIconClass} />}
            className={`
              hp-mb-16 hp-pl-24 hp-pr-32
              ${splitLocation[splitLocation.length - 1] === 'profile'
      ? 'ant-menu-item-selected'
      : 'ant-menu-item-selected-in-active'}
            `}
            onClick={onCloseDrawer}
          >
            <Link to="../profile">
              <IntlMessages id="profile-personal-information-page" />
            </Link>
          </Menu.Item>
        </Menu>
      </div>

      {menuFooterItem()}
    </Col>
  );
};

MenuProfile.propTypes = {
  footer: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
  onCloseDrawer: PropTypes.func,
  moreBtn: PropTypes.object,
  profile: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default MenuProfile;

