import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { Col, Row, TimePicker } from 'antd';
import { useFormikContext } from 'formik';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { TIME_FORMAT } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';



const FormEditImage = ({
  allCategories,
  allHashtags,
  canUpdatePublicationTime = false,
  isFieldsRequired = false,
}) => {
  const intl = useIntl();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const categoryOptions = allCategories?.map((category) => {
    return {
      value: category.id,
      label: `${category.name} (${intl.formatMessage({ id: 'content-images-category-client-id' })}: ${category.client_id})`,
    };
  }) ?? [];

  const hashtagsOptions = allHashtags?.map((hashtag) => {
    return {
      value: hashtag.id,
      label: `#${hashtag.hashtag}`,
    };
  }) ?? [];

  const feedsOptions = JSON.parse(localStorage.getItem('feeds_options')) || {};
  const allLabels = feedsOptions?.labels;

  const labelsOptions = allLabels?.map((label) => {
    return {
      value: label,
      label,
    };
  }) ?? [];

  return (
    <Form layout="vertical" className='update-image-modal-form'>
      <FormItem
        required={isFieldsRequired}
        className='ant-form-item-col update-image-modal-form__form-item'
        name="main_category"
        label={intl.formatMessage({ id: 'content-images-main-category' })}
      >
        <Select
          allowClear
          onChange={async (value) => {
            if (!value) {
              await setFieldValue('main_category', null);
              await setFieldTouched('main_category', true);
            }

            await setFieldValue('main_category', value, true);
            await setFieldTouched('labels', true);
          }}
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="main_category"
          options={categoryOptions.filter((category) => !values.categories.includes(category.value))}
          placeholder={<IntlMessages id="content-images-main-category-placeholder" />}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="categories"
        label={intl.formatMessage({ id: 'ui-general-categories' })}
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="categories"
          options={categoryOptions.filter((category) => category.value !== values.main_category)}
          placeholder={<IntlMessages id="ui-general-choose-categories" />}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="labels"
        label={intl.formatMessage({ id: 'ui-general-labels' })}
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="labels"
          options={labelsOptions}
          placeholder={<IntlMessages id="ui-general-choose-labels" />}
          onChange={async (value) => {
            if (isEmpty(value)) {
              await setFieldTouched('labels', true);
            }

            await setFieldValue('labels', value, true);
          }}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="hashtags"
        label={intl.formatMessage({ id: 'ui-general-hashtags' })}
      >
        <Select
          mode="multiple"
          allowClear
          showSearch
          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
          size='small'
          name="hashtags"
          options={hashtagsOptions}
          placeholder={<IntlMessages id="ui-general-choose-hashtags" />}
          onChange={async (value) => {
            await setFieldValue('hashtags', value, true);
            await setFieldTouched('labels', true);
          }}
        />
      </FormItem>

      <FormItem
        className='ant-form-item-col update-image-modal-form__form-item'
        name="comment"
        label={intl.formatMessage({ id: 'ui-general-comment' })}
      >
        <Input.TextArea
          name="comment"
          placeholder={intl.formatMessage({ id: 'ui-general-specify-value' })}
          rows={2}
          showCount
          maxLength={255}
        />
      </FormItem>

      {canUpdatePublicationTime && (
        <FormItem
          className='ant-form-item-col update-image-modal-form__form-item'
          name="publication_time"
          label={intl.formatMessage({ id: 'ui-general-publication-time' })}
        >
          <Row gutter={16} className='hp-align-items-center'>
            <Col span={7}>
              <TimePicker
                showNow
                allowClear={false}
                name="publication_time"
                format={TIME_FORMAT}
                defaultValue={values.publication_time}
                value={values.publication_time ? moment(values.publication_time, TIME_FORMAT) : null}
                onChange={(time, timeString) => setFieldValue('publication_time', timeString)}
              />
            </Col>
            <Col>
              <IntlMessages id="feeds-image-modal-publication-time-info" />
            </Col>
          </Row>
        </FormItem>
      )}
    </Form>
  );
};

FormEditImage.propTypes = {
  allCategories: PropTypes.array.isRequired,
  allHashtags: PropTypes.array.isRequired,
  canUpdatePublicationTime: PropTypes.bool,
  isFieldsRequired: PropTypes.bool,
};

export default FormEditImage;
