import React from 'react';
import capitalize from 'lodash/capitalize';
import { Popconfirm, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageRBACUsersDetails from '../../../../../urls/urlPageRBACUserDetails';
import avatarImg from '../../../../../assets/images/memoji/memoji-1.png';
import { DATE_TIME_FORMAT } from '../../../../../const/system';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import UserStatus from '../../../components/UserStatus';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const USER_STATUS_MAP = {
  ACTIVE: 1,
  BLOCKED: 0,
};

export const getUsersListTableColumns = (handleDeleteUser, filteredKeys, userId, tableSize) => {
  return [
    {
      title: '',
      dataIndex: 'avatar',
      align: 'center',
      width: '60px',
      render: (value) => {
        return (
          <div>
            <img src={value ?? avatarImg} alt="user-img" />
          </div>
        );
      },
    },
    {
      title: <IntlMessages id="ui-general-id" />,
      dataIndex: 'id',
      align: 'center',
      width: '65px',
      sorter: true,
      render: (value) => <Link to={urlPageRBACUsersDetails({ userId: value })} target="_blank">{value}</Link>,
    },
    {
      title: <IntlMessages id="ui-general-name" />,
      align: 'center',
      width: tableSize.width * 0.16,
      render: (item) => <CollapsibleText text={item.name} />,
    },
    {
      title: <IntlMessages id="users-table-email-col" />,
      dataIndex: 'email',
      align: 'center',
      width: tableSize.width * 0.20,
      render: (value) => {
        return (
          <a href={`mailto:${value}`}>
            <div className='hp-break'>
              {value}
            </div>
          </a>
        );
      },
    },
    {
      title: <IntlMessages id="ui-general-status" />,
      dataIndex: 'is_active',
      align: 'center',
      width: tableSize.width * 0.11,
      render: (value) => <UserStatus status={value} />,
      filteredValue: filteredKeys,
      filters: Object.entries(USER_STATUS_MAP).map(([ status, value ]) => (
        {
          text: <span>{capitalize(status)}</span>,
          value,
        }
      )),
    },
    {
      title: <IntlMessages id="users-table-reg-date-col" />,
      dataIndex: 'created_at',
      align: 'center',
      width: tableSize.width * 0.14,
      render: (value) => getFormattedDate(value),
      sorter: true,
    },
    {
      title: <IntlMessages id="users-table-log-date-col" />,
      dataIndex: 'last_login',
      align: 'center',
      width: tableSize.width * 0.14,
      render: (value) => getFormattedDate(value, DATE_TIME_FORMAT),
      sorter: true,
    },
    ...(HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW, Permissions.ADMINISTRATION.USERS.USERS_LIST.DELETE_USER ]) ?
      [ {
        title: <IntlMessages id="ui-general-action" />,
        key: 'action',
        align: 'center',
        width: '90px',
        render: (item) => {
          return (
            <Row className="da-h-100" align="middle" justify="center">
              {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) && (
                <Tooltip placement="top" title={<IntlMessages id="users-table-tooltip-edit-btn" />}>
                  <div className="hp-text-right">
                    <Link
                      to={urlPageRBACUsersDetails({ userId: item.id })}
                      target="_blank"
                    >
                      <Eye
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                      />
                    </Link>
                  </div>
                </Tooltip>
              )}

              {HasRights([ Permissions.ADMINISTRATION.USERS.USERS_LIST.DELETE_USER ]) && userId !== item.id && (
                <Popconfirm
                  title={<IntlMessages id="users-table-delete-btn-confirm-message" />}
                  placement="top"
                  onConfirm={() => handleDeleteUser(item.id)}
                  okText={<IntlMessages id="ui-general-yes" />}
                  cancelText={<IntlMessages id="ui-general-no" />}
                  icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                  okButtonProps={{ danger: true }}
                >
                  <Tooltip placement="top" title={<IntlMessages id="users-table-tooltip-delete-btn" />}>
                    <div className="hp-text-right">
                      <Delete
                        size={20}
                        className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                      />
                    </div>
                  </Tooltip>
                </Popconfirm>
              )}
            </Row>
          );
        },
      } ] : []
    ),
  ];
};
