import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const PushIconsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'PushIcons' ],
  endpoints: (builder) => {
    return ({
      getPushIcons: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          if (!queryParams) {
            return null;
          }

          return ({ url: `/${Config.VITE_HC_API_VERSION}/push-icons${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        providesTags: [ 'PushIcons' ],
        keepUnusedDataFor: 1,
      }),
      createPushIcon: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/push-icons`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'PushIcons' ],
      }),
      updatePushIcon: builder.mutation({
        query: ({ id, values }) => {
          return ({
            url: `/${Config.VITE_HC_API_VERSION}/push-icons/${id}`,
            method: 'PUT',
            body: values,
          });
        },
        invalidatesTags: (pushIcon) => [ { type: 'PushIcons', id: pushIcon?.id } ],
      }),
      deletePushIcon: builder.mutation({
        query: (id) => ({
          url: `${Config.VITE_HC_API_VERSION}/push-icons/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'PushIcons' ],
      }),
    });
  },
});

export const {
  useGetPushIconsQuery,
  useCreatePushIconMutation,
  useUpdatePushIconMutation,
  useDeletePushIconMutation,
} = PushIconsApiSlice;
