import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Upload } from 'antd';
import { Form, FormItem, Input, Select, ResetButton, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RiUploadCloud2Line } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import { dateFieldFormat, STATUS_MAP } from '../../../../const/system';
import { typeOptions } from '../FormCreateNews/FormCreateNews.const';
import useDebounce from '../../../../app/hooks/useDebounce';
import { useGetDictionariesQuery } from '../../../Translations/api/dictionariesApiSlice';
import getDictionariesOptions from '../../../../lib/getDictionariesOptions';
import prepareFormData from '../../../../lib/prepareFormData';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';



const validationSchema = Yup.object().shape({
  target: Yup.string().nullable(),
  dictionary: Yup.number().min(1).nullable(),
  published_at: Yup.date().nullable(),
  published_to: Yup.date().nullable(),
  type: Yup.string().required(),
  icons: Yup
    .array()
    .test(
      'iconsAmount',
      '2 icons required',
      (value) => {
        return value?.length === 0 || value?.length === 2;
      },
    ),
  status: Yup.bool().test(
    'status',
    'Status can not be On until any field is empty',
    (value, context) => {
      const { parent } = context;

      if (!value) {
        return true;
      }

      return parent?.type && parent?.target && parent?.dictionary
        && parent?.published_at && parent?.published_to && parent?.icons.length;
    },
  ),
});

const FormUpdateNews = ({
  onSubmit,
  onCancel,
  isSubmitting,
  initialValues,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();
  const [ search, setSearch ] = useState('');
  const searchQuery = useDebounce(search.trim(), 300);

  const { data: { data: dictionaries = [] } = { data: [] },
    isFetching: isDictionaryFetching,
  } = useGetDictionariesQuery({
    queryParams: searchQuery ? `limit=100&search=name:${searchQuery};status:ready` : 'limit=100&search=status:ready',
  });

  const onSelect = (event, field, setFieldValue) => {
    setFieldValue(field, event);
  };

  const onSearch = useCallback((searchText) => {
    setSearch(searchText);
  }, [ search ]);

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={{
        status: STATUS_MAP.ON === initialValues.status,
        icons: initialValues.icon ? [ {
          uid: '-1',
          name: 'phone.png',
          status: 'done',
          url: initialValues.icon,
        }, {
          uid: '-2',
          name: 'tablet.png',
          status: 'done',
          url: initialValues.icon?.replace('phone', 'tablet'),
        } ] : [],
        type: initialValues.type,
        target: initialValues.target_scheme || initialValues.target_url,
        published_at: initialValues.published_at,
        published_to: initialValues.published_to,
        dictionary: initialValues.dictionary?.data?.id || null,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const icons = values.icons.filter((icon) => 'originFileObj' in icon);

        onSubmit(initialValues.id, prepareFormData({
          ...values,
          icons,
        }));

        resetForm();
      }}
      onReset={async (values) => {
        onCancel(values);
      }}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form layout="vertical" id="news-update-form">
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-type' })}
                  name='type'
                  required
                >
                  <Select
                    allowClear={!values.status}
                    name="type"
                    options={typeOptions}
                    placeholder={intl.formatMessage({ id: 'ui-general-choose-type' })}
                  />
                </FormItem>
              </Col>
            </Row>

            <FormItem
              label={intl.formatMessage({ id: 'news-create-form-target-label' })}
              name='target'
            >
              <Input
                allowClear={!values.status}
                name="target"
                placeholder={intl.formatMessage({ id: 'news-create-form-target-placeholder' })}
              />
            </FormItem>

            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-dictionary' })}
                  name='dictionary'
                >
                  <Select
                    allowClear={!values.status}
                    showSearch
                    loading={isDictionaryFetching}
                    value={isDictionaryFetching ? null : (values?.dictionary ?? null)}
                    options={getDictionariesOptions(dictionaries)}
                    onSelect={(event) => onSelect(event, 'dictionary', setFieldValue)}
                    onSearch={onSearch}
                    filterOption={false}
                    onChange={(event) => onSelect(event, 'dictionary', setFieldValue)}
                    placeholder={intl.formatMessage({ id: 'news-create-form-dictionary-placeholder' })}
                    name='dictionary'
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'news-create-form-icons-label' })}
                  name='icons'
                >
                  <Upload.Dragger
                    name="icons"
                    multiple
                    beforeUpload={() => false}
                    listType="picture"
                    fileList={values.icons}
                    onRemove={() => false}
                    onChange={({ fileList }) => {
                      if (fileList.length > 2) {
                        fileList = fileList.slice(-2);
                      }
                      setFieldValue('icons', fileList);
                    }}
                    defaultFileList={[ ...values.icons ]}
                  >
                    <p className="ant-upload-drag-icon hp-mb-0">
                      <RiUploadCloud2Line size={24} className="remix-icon" />
                    </p>

                    <p className="ant-upload-text">
                      <IntlMessages id="news-create-form-upload-description-label" />
                    </p>
                  </Upload.Dragger>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published-at' })}
                  name='published_at'
                >
                  <DatePicker
                    allowClear={!values.status}
                    name='published_at'
                    format={dateFieldFormat}
                    value={values?.published_at ? moment(values.published_at) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('published_at', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('published_at', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published-to' })}
                  name='published_to'
                >
                  <DatePicker
                    allowClear={!values.status}
                    name='published_to'
                    format={dateFieldFormat}
                    value={values?.published_to ? moment(values.published_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('published_to', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('published_to', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-status' })}
                  name='status'
                >
                  <Switch
                    name="status"
                    checkedChildren={<IntlMessages id="ui-general-off" />}
                    unCheckedChildren={<IntlMessages id="ui-general-on" />}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-save" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormUpdateNews.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
};


export default FormUpdateNews;
