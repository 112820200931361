import React from 'react';
import { Alert, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import ImageModalCardHeader from '../ImageModalCardHeader';
import FormEditImage from '../../../forms/FormEditImage';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import PreviewImages from '../../PreviewImages';
import { imageStatuses } from '../../../../../const/imageStatuses';



const ImageModalCardBodyEdit = ({
  data,
  categories,
  hashtags,
  canUpdatePublicationTime = false,
  isInPlanningBoard = false,
}) => {
  const { status } = data;

  return (
    <>
      <ImageModalCardHeader data={data} />
      <Row gutter={[ 32, 32 ]} justify="center" className='hp-mt-16 hp-border-top-1'>
        <PreviewImages
          icon={data?.icon?.data?.path}
          preview={data?.preview?.data?.path}
          mdSpan={7}
        />
        <Col xs={24} md={10}>
          <FormEditImage
            allCategories={categories}
            allHashtags={hashtags}
            canUpdatePublicationTime={canUpdatePublicationTime}
            isFieldsRequired={isInPlanningBoard || status === imageStatuses.release_on}
          />
        </Col>

        <Col span={24}>
          {status !== imageStatuses.ready_for_release && (
            <Row justify="center" className='hp-mx-0 hp-pb-16'>
              <Alert
                showIcon
                type="info"
                message={
                  <IntlMessages
                    id={[
                      imageStatuses.release_on,
                      imageStatuses.bonus,
                      imageStatuses.achievement,
                      imageStatuses.daily,
                      imageStatuses.pack,
                    ].includes(status) ?
                      'feeds-modal-edit-image-save-and-publish-info' :
                      'feeds-modal-edit-image-save-info'}
                  />
                }
              />
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

ImageModalCardBodyEdit.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    status: PropTypes.string,
    icon: PropTypes.object,
    preview: PropTypes.object,
    created_at: PropTypes.string,
    data: PropTypes.object,
    source: PropTypes.object,
    categories: PropTypes.object,
    used_in_collections: PropTypes.array,
    used_in_editor_choices: PropTypes.array,
  }),
  categories: PropTypes.array,
  hashtags: PropTypes.array,
  canUpdatePublicationTime: PropTypes.bool,
  isInPlanningBoard: PropTypes.bool,
};

export default ImageModalCardBodyEdit;
