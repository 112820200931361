import { Select } from 'formik-antd';
import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';
import { countries } from '../../const/countries';
import makeSelectFilterOption from '../../lib/makeSelectFilterOption';



const CountrySelector = ({
   name = null,
   placeholder = null,
   isDisabledSelectAll = false,
   onSelectAll = () => {},
   disabled = false,
   isView = false,
   setFieldValue = () => {},
 }) => {
  return (
    <div className="hp-d-flex">
      <Select
        allowClear
        showSearch
        mode="multiple"
        className="single-line-select"
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        options={countries}
        filterOption={(input, option) => makeSelectFilterOption(option?.label ?? '', input)}
        onChange={async (value) => {
          await setFieldValue(value.sort());
        }}
      />
      {!isView && (
        <Button
          type="outline"
          style={{ minWidth: '135px' }}
          className="hp-ml-8"
          disabled={isDisabledSelectAll}
          onClick={onSelectAll}
        >
          <IntlMessages id="ui-general-select-all" />
        </Button>
      )}
    </div>
  );
};

CountrySelector.propTypes = {
  name: PropTypes.string.isRequired,
  isDisabledSelectAll: PropTypes.bool.isRequired,
  placeholder: PropTypes.any.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isView: PropTypes.bool,
};

export default CountrySelector;
