import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const StructuresApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Structures' ],
  endpoints: (builder) => ({
    getStructures: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        if (!queryParams) {
          return null;
        }

        return ({ url: `/${Config.VITE_HC_API_VERSION}/structures${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          pagination: response?.meta?.pagination,
        };
      },
      providesTags: [ 'Structures' ],
      keepUnusedDataFor: 1,
    }),

    getAllVersionsStructures: builder.query({
      query: (params = null) => {
        const queryParams = params ? `${makeQueryParams(params.queryParams)}&limit=0` : '?limit=0';

        return ({ url: `/${Config.VITE_HC_API_VERSION}/structures-all-versions${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          pagination: response?.meta?.pagination,
        };
      },
      providesTags: [ 'Structures' ],
    }),

    getStructure: builder.query({
      query: (id) => ({ url: `/${Config.VITE_HC_API_VERSION}/structures/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: [ 'Structures' ],
    }),

    updateStructure: builder.mutation({
      query: ({ id, values }) => ({
        url: `/${Config.VITE_HC_API_VERSION}/structures/${id}/versions`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: (data) => [ { type: 'Structures', id: data?.id }, { type: 'Dictionaries' } ],
    }),

    getStructureVersions: builder.query({
      query: (id) => ({ url: `/${Config.VITE_HC_API_VERSION}/structures/${id}/versions` }),
      providesTags: [ 'StructureVersions' ],
    }),
  }),
});

export const {
  useGetStructuresQuery,
  useGetAllVersionsStructuresQuery,
  useGetStructureQuery,
  useGetStructureVersionsQuery,
  useUpdateStructureMutation,
} = StructuresApiSlice;
