import Yup from '../vendor/yup';



const parseVersion = (version) => version.split('.').map(Number);

const isSmallerThan = (version1, version2) => {
  const v1 = parseVersion(version1);
  const v2 = parseVersion(version2);

  const length = Math.max(v1.length, v2.length);

  // eslint-disable-next-line fp/no-loops
  for (let i = 0; i < length; i++) {
    const segment1 = v1[i] ?? 0;
    const segment2 = v2[i] ?? 0;

    if (segment1 !== segment2) {
      return segment1 < segment2;
    }
  }

  return false;
};

export const targetValidation = Yup.lazy((values) => {
  return Yup.object().shape({
    min_version: Yup.string()
      .nullable()
      .matches(
        /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.?(0|[1-9]\d*)?$/,
        'Min version has incorrect format. Try semantic version like 1.0 or 1.0.0',
      )
      .test(
        'check-min-version',
        'Min version should be less than or equal to the max version',
        (value) => {
          const maxValue = values.max_version;

          if (value && maxValue) {
            return isSmallerThan(value, maxValue) || value === maxValue;
          }

          return true;
        },
      ),
    max_version: Yup.string()
      .nullable()
      .matches(
        /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.?(0|[1-9]\d*)?$/,
        'Max version has incorrect format. Try semantic version like 1.0 or 1.0.0',
      )
      .test(
        'check-max-version',
        'Max version should be greater than or equal to the min version',
        (value) => {
          const minValue = values.min_version;

          if (value && minValue) {
            return isSmallerThan(minValue, value) || value === minValue;
          }

          return true;
        },
      ),
    platform: Yup.array().of(Yup.string()).min(1, 'Enter at least 1 target platform').required('Target platform is required'),
    countries: Yup.array().of(Yup.string()).min(1, 'Enter at least 1 target country').required('Target countries is required'),
  });
});
