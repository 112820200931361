export const makePayload = (planningBoardImages) => {
  return planningBoardImages.map((image, index) => {
    return {
      id: image.id ?? null,
      priority: index + 1,
      comment: image.comment ?? null,
      labels: image.labels ?? [],
      hashtags: image.hashtags?.data?.map((tag) => tag.id) ?? [],
      main_category: image.categories?.data?.find((category) => category.is_main)?.id ?? null,
      categories: image.categories?.data?.filter((category) => !category.is_main).map((category) => category.id) ?? [],
    };
  });
};
