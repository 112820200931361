import capitalize from 'lodash/capitalize';



const getTagsOptions = (tags = []) => {
  return tags.map((item) => {
    return {
      label: capitalize(item.name),
      value: item.id,
    };
  });
};

export default getTagsOptions;
