import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Modal, Popconfirm, Row, Spin, Tabs, Tooltip } from 'antd';
import { RiCloseFill, RiErrorWarningLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { Filter } from 'iconsax-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import urlPageFeeds from '../../../../../urls/urlPageFeeds';
import urlPageFeedsPlanningDetails from '../../../../../urls/urlPageFeedsPlanningDetails';
import urlPageFeedsPlanningDetailsEdit from '../../../../../urls/urlPageFeedsPlanningDetailsEdit';
import {
  FEEDS_SOURCE_MAP,
  getFeedsSourceTranslation,
  MODAL_MODE,
  searchFilterFields,
  ON_LOAD_LIMIT,
  PLANNING_BOARD_PUBLISH_LIMIT,
} from '../PageFeeds.const';
import { MYSTERY_CATEGORY_VALUE, NO_LINES_HASHTAG_VALUE } from '../../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { DATE_FORMAT } from '../../../../../const/system';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import { imageStatuses } from '../../../../../const/imageStatuses';
import { hasRights, PrivateComponent } from '../../../../../components/HasRights/HasRights';
import resetFilter from '../../../../../lib/resetFilter';
import applyFilter from '../../../../../lib/applyFilter';
import useToggle from '../../../../../app/hooks/useToggle';
import getModalWidthByModalMode from '../../../../../lib/getModalWidthByModalMode';
import getModalTitleByModalMode from '../../../../../lib/getModalTitleByModalMode';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import getFeedsNotificationLink, { SPECIFIC_DAY_PLANNING } from '../../../utils/getFeedsNotificationLink';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import isNotReleased from '../../../../../lib/isNotReleased';
import { makeNotification, notificationTypes } from '../../../../../lib/makeNotification';
import { getCardBackground } from '../../../components/FeedsPlanningBoardCalendar/FeedsPlanningBoardCalendarCells/FeedsPlanningBoardCalendarCells.const';
import { validateSelectedItems } from './PageFeedsPlanningDetailsContent.const';
import DocumentationTooltip from '../../../../../components/DocumentationTooltip/DocumentationTooltip';
import Sidebar from '../../../../../components/Sidebar';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormFeedsFilter from '../../../forms/FormFeedsFilter';
import ActionButton from '../../../../../layout/components/action-button';
import Spinner from '../../../../../components/Spinner';
import TotalLabel from '../../../../../components/TotalLabel';
import ContentCard from '../../../components/ContentCard';
import ImageModalCard from '../../../components/ImageModalCard';
import ImageModalCardTags from '../../../components/ImageModalCard/ImageModalCardTags';
import ImageModalCardSimilarImages from '../../../components/ImageModalCard/ImageModalCardSimilarImages';
import SelectedLabel from '../../../../../components/SelectedLabel';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import PlanningBoardDetailsBadge from '../../../components/PlanningBoardDetailsBadge';
import FeedsCalendarWidget from '../../../components/FeedsCalendarWidget';
import FeedsPlanningBoardImages from '../../../components/FeedsPlanningBoardImages';
import PlanningBoardDetailsHeader from '../../../components/PlanningBoardDetailsHeader';
import { usePlanningBoardImagesContext } from '../../../contexts/PlanningImagesContext';
import { useGetCategoriesQuery } from '../../../api/categoriesApiSlice';
import { useGetHashtagsQuery } from '../../../api/hashtagsApiSlice';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';
import { useGetSimilarImagesQuery } from '../../../api/imagesApiSlice';
import { useGetFeedsPlanningBoardDetailsQuery } from '../../../api/feedsPlanningApiSlice';



const { TabPane } = Tabs;


const PageFeedsPlanningDetailsContent = () => {
  const navigate = useNavigate();
  const { date } = useParams();
  const { pathname } = useLocation();
  const intl = useIntl();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ previewImage, setPreviewImage ] = useState({});
  const [ showContour, toggleShowContour ] = useToggle();
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ isAnimationStopped, setIsAnimationStopped ] = useState(false);
  const [ isUpdateList, setIsUpdateList ] = useState(false);
  const [ updatedListItem, setUpdatedListItem ] = useState({});
  const [ isImageAddingToBoard, setIsImageAddingToBoard ] = useState(false);
  const [ activeModalTab, setActiveModalTab ] = useState('general-info');
  const [ isResetPlanningBoardImages, setResetPlanningBoardImages ] = useState(false);
  const [ isConfirmationModalOpen, setIsConfirmationModalOpen ] = useState(false);
  const [ storageTotalCount, setStorageTotalCount ] = useState(0);
  const [ navigationUrl, setNavigationUrl ] = useState('');
  const [ selectedItems, setSelectedItems ] = useState([]);

  const {
    items: planningBoardImages,
    originalItems: planningBoardOriginalImages,
    initialImagesErrors,
    updateCurrentlyAddedToBoardItem,
    updateItems,
    updateOriginalItems,
    updateImageWithErrorsIds,
    updateInitialImagesErrors,
  } = usePlanningBoardImagesContext();

  //Default height of storage container (calculated initially)
  const initialStorageHeight = window.innerHeight - (window.innerWidth > 1997 ? 2000 : 1400);
  const [ maxStorageHeight, setMaxStorageHeight ] = useState(initialStorageHeight);

  const planningContainerRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const storageFilterFormRef = useRef(null);

  const feedSource = getSourceFromUrl(pathname);
  const isEditing = pathname.endsWith('edit');
  const hasStoragePermission = hasRights([ Permissions.CONTENT.FEEDS.PLANNING.STORAGE ]);
  const hasPlanningPermission = hasRights([ Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS ]);
  const isOpenedTagsTab = activeModalTab === 'tags-info';

  const { data: { data, pagination } = { data: [] },
    isFetching: isLoadingStorageImages,
    error: formErrors = {},
  } = useGetFeedsQuery({ feedSource, queryParams }, { refetchOnMountOrArgChange: true });

  const {
    data: { data: planningDetails } = { data: {} },
    isFetching: isLoadingPlanningDetails,
  } = useGetFeedsPlanningBoardDetailsQuery(date);

  const { data: {
    data: categories = [],
  } = { data: [], pagination: {} } } = useGetCategoriesQuery({ queryParams: 'limit=0' });

  const { data: {
    data: hashtags = [],
  } = { data: [], pagination: {} } } = useGetHashtagsQuery({ queryParams: 'limit=0' });

  const { data: {
    data: similarImages = [],
  } = { data: [], pagination: {} }, isFetching: isLoadingSimilarImages } = useGetSimilarImagesQuery({
    id: previewImage.id,
    queryParams: `?date=${date}&limit=6`,
  }, { skip: !isOpenedTagsTab });


  const noLinesHashtagId = hashtags?.find((hashtag) => hashtag.hashtag === NO_LINES_HASHTAG_VALUE)?.id;
  const mysteryCategoryId = categories?.find((category) => category.name === MYSTERY_CATEGORY_VALUE)?.id;

  const {
    itemsArray,
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    pagination,
    searchFilterFields,
    source: feedSource,
    isUpdateList,
    updatedListItem,
  });

  const itemsInStorage = itemsArray.filter((item) => !planningBoardImages.find((boardItem) => item.id === boardItem.id));
  const isEmptyMultiselect = selectedItems.length === 0;
  const isSelectedAll = selectedItems.length === itemsInStorage.length;
  const publishMaxLimitExceeded = (planningBoardImages.length + selectedItems.length) > PLANNING_BOARD_PUBLISH_LIMIT;
  const initialTotalValue = pagination?.total ?? 0;


  useEffect(() => {
    if (!isEmpty(combinedQueryParams)) {
      if (combinedQueryParams) {
        setQuery(combinedQueryParams);
      }

      if (combinedQueryParams === '') {
        setQuery('');
      }
    }
  }, [ combinedQueryParams ]);

  useEffect(() => {
    if (isEditing) {
      validateSelectedItems({
        planningBoardImages,
        noLinesHashtagId,
        mysteryCategoryId,
        updateImageWithErrorsIds,
        updateInitialImagesErrors,
      }).then(() => {
        setIsImageAddingToBoard(false);
      });
    }
  }, [ isEditing, planningBoardImages, noLinesHashtagId, mysteryCategoryId ]);


  useEffect(() => {
    if (selectedItems.length > 0) {
      setSelectedItems([]);
    }
  }, [ isEditing ]);

  useEffect(() => {
    const currentStorageItemsOnBoard = planningBoardImages.length - planningBoardOriginalImages.length;
    const total = initialTotalValue - currentStorageItemsOnBoard;

    setStorageTotalCount(total);
  }, [ initialTotalValue ]);

  useEffect(() => {
    // Calculate height interval which added to storage component
    const initialHeightInterval = window.innerWidth < 1997 ? 1000 : 900;
    // Height that we plus to initial height when add new items. 600 is the maximum value, if bigger auto scroll fails
    const heightInterval = window.innerWidth < 1680 ? 1000 : 600;

    const changeHeight = () => {
      if (planningContainerRef.current && scrollableDivRef.current) {
        const { height: planningHeight } = planningContainerRef.current.getBoundingClientRect();

        if (maxStorageHeight < planningHeight) {
          if (itemsLength === 20) {
            setMaxStorageHeight(() => Math.min(initialHeightInterval, planningHeight + 20));
          } else {
            setMaxStorageHeight((prevHeight) => itemsLength < 10
              ? initialHeightInterval
              : Math.min(prevHeight + heightInterval, planningHeight + 20));
          }
        }
      }
    };

    changeHeight();
  }, [ itemsLength ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimationStopped(true);
    }, 10000);

    return () => {
      clearTimeout(timer);
      setIsAnimationStopped(false);
    };
  }, [ date ]);

  useEffect(() => {
    if (!isEmpty(planningDetails)) {
      setPreviewImage({});

      const hasDraftImages = planningBoardImages?.some((item) => item.status === imageStatuses.ready_for_release);

      if (!hasDraftImages) {
        updateItems(planningDetails?.images);
        updateOriginalItems(planningDetails?.images);
      }

      window.scrollTo(0, 0);
    }
  }, [ planningDetails ]);

  useEffect(() => {
    if (isResetPlanningBoardImages) {
      setResetPlanningBoardImages(false);
    }
  }, [ isResetPlanningBoardImages ]);

  useEffect(() => {
    if (itemsInStorage?.length === ON_LOAD_LIMIT && hasMore) {
      handleLoadNextData();
    }
  }, [ planningBoardImages ]);


  const handleUpdateListItem = (id, values = {}) => {
    setIsUpdateList(true);
    setUpdatedListItem({ id, values });
  };

  const handleUpdateTotal = (isReturn = false) => {
    if (isReturn && itemsInStorage.length < storageTotalCount) {
      setStorageTotalCount((prev) => prev + 1);
    }
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
    setActiveModalTab('general-info');
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
    updateInitialImagesErrors({});
    setIsImageAddingToBoard(false);
  };

  const handleCloseEdit = (date) => {
    storageFilterFormRef.current?.resetForm({});
    setStorageTotalCount(initialTotalValue);
    navigate(urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date }));
  };

  const handleCancelChanges = () => {
    updateCurrentlyAddedToBoardItem({});
    updateImageWithErrorsIds([]);
    updateInitialImagesErrors({});
    updateItems(planningBoardOriginalImages);
    setStorageTotalCount(initialTotalValue);
  };

  const toggleUnpublishModal = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.UNPUBLISH);
    togglePreviewModal();
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleLeaveEditing = () => {
    handleCloseConfirmationModal();

    if (navigationUrl) {
      handleCancelChanges();
      navigate(navigationUrl);
    }
  };

  const showModal = (url) => {
    setIsConfirmationModalOpen(true);
    setNavigationUrl(url);
  };

  const handleClickOnBreadcrumbInEditing = (event, url) => {
    event.preventDefault();

    if (isEditing && !isEqual(planningBoardImages, planningBoardOriginalImages)) {
      showModal(url);
    } else {
      storageFilterFormRef.current?.resetForm({});
      navigate(url);
    }
  };

  const handleResetFilterAndNavigate = (url) => {
    storageFilterFormRef.current?.resetForm({});

    setTimeout(() => navigate(url), 0);
  };

  const handleNavigate = (url) => navigate(queryParams ? `${url}?${queryParams}` : url);

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedItems((prev) => isChecked
      ? [ ...prev, itemsInStorage.find((item) => item.id === id) ]
      : prev.filter((item) => item.id !== id));
  };

  const handleSelectAll = () => {
    setSelectedItems([ ...itemsInStorage ]);
  };

  const handleUnselectAll = () => {
    setSelectedItems([]);
  };

  const addToBoardSelectedItems = () => {
    setIsImageAddingToBoard(true);
    updateItems([ ...planningBoardImages, ...selectedItems ]);
    setStorageTotalCount((prev) => prev - selectedItems.length);
    if ((itemsInStorage.length - selectedItems.length) <= ON_LOAD_LIMIT && hasMore) {
      handleLoadNextData();
    }
    setSelectedItems([]);
    makeNotification(
      notificationTypes.success,
      intl.formatMessage({ id: 'planning-board-add-to-board-notification' }),
    );
  };

  const canUpdatePublicationTime = isNotReleased(previewImage.release_date)
    && previewImage.status !== imageStatuses.ready_for_release
    && !isEditing;

  const isSelectedStorageItem = (id) => selectedItems.some((selected) => selected.id === id);
  const isInPlanningBoard = !!planningBoardImages.find((boardItem) => previewImage.id === boardItem.id);

  const getTotalCount = (itemsInStorage.length > storageTotalCount) ? itemsInStorage.length : storageTotalCount;

  return (
    <>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isConfirmationModalOpen}
        onOk={handleLeaveEditing}
        onCancel={handleCloseConfirmationModal}
      >
        <IntlMessages id="ui-general-leave-changes-confirm-message" />
      </Modal>

      <Modal
        className='feed-modal'
        width={getModalWidthByModalMode(modalMode)}
        title={isImageAddingToBoard ? <IntlMessages id="planning-board-add-to-board" /> : getModalTitleByModalMode(modalMode)}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        destroyOnClose
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        {/*<Tabs*/}
        {/*  type="card"*/}
        {/*  defaultActiveKey="general-info"*/}
        {/*  onTabClick={handleClickTab}*/}
        {/*>*/}
        <Tabs
          type="card"
          activeKey={activeModalTab}
          onChange={(key) => setActiveModalTab(key)}
        >
          <TabPane
            key="general-info"
            tab={<IntlMessages id='ui-general-info' />}
          >
            <ImageModalCard
              data={previewImage}
              modalMode={modalMode}
              handleModalClose={handlePreviewModalClose}
              handleUpdateListItem={(imageId, values = {}) => handleUpdateListItem(imageId, values)}
              handleModalMode={(mode) => setModalMode(mode)}
              handleUpdateTotal={handleUpdateTotal}
              canUpdatePublicationTime={canUpdatePublicationTime}
              initialEditImageErrors={initialImagesErrors.hasOwnProperty(previewImage.id) ? initialImagesErrors[previewImage.id] : {}}
              isImageAddingToBoard={isImageAddingToBoard}
              editDisabled={modalMode === MODAL_MODE.UNPUBLISH}
              isInPlanningBoard={isInPlanningBoard}
            />
          </TabPane>

          {hasRights([
            Permissions.CONTENT.IMAGES.TAGS.LIST,
            Permissions.CONTENT.IMAGES.TAGS.UPDATE,
            Permissions.CONTENT.IMAGES.TAGS.VIEW_SIMILAR_IMAGES,
          ]) && (
            <TabPane
              key="tags-info"
              tab={<IntlMessages id='ui-general-tags' />}
            >
              {activeModalTab === 'tags-info' && (
                <Row className='tags-info-tab-container'>
                  <Col span={16}>
                    <ImageModalCardTags
                      previewData={previewImage}
                      hasSimilarImages
                    />
                  </Col>

                  <ImageModalCardSimilarImages
                    data={similarImages}
                    isLoading={isLoadingSimilarImages}
                  />
                </Row>
              )}
            </TabPane>
          )}
        </Tabs>
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(
                event,
                urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST }),
              )}
              to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST })}
            >
              <IntlMessages id='sidebar-content-feeds' />
            </Link>
          }
          breadCrumbParent2={
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(
                event,
                urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.PLANNING }),
              )}
              to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.PLANNING })}
            >
              <IntlMessages id={getFeedsSourceTranslation(feedSource)} />
            </Link>
          }
          breadCrumbParent3={isEditing ? (
            <Link
              onClick={(event) => handleClickOnBreadcrumbInEditing(
                event,
                urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date }),
              )}
              to={urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date })}
            >
              {date}
            </Link>
          ) : null}
          breadCrumbActive={
            <div>
              {!isEditing ? (
                <div>
                  {date}
                  <DocumentationTooltip link={getFeedsNotificationLink(SPECIFIC_DAY_PLANNING)} />
                </div>
              ) : (
                <div>
                  <IntlMessages id='ui-general-edit' />
                  <DocumentationTooltip link={getFeedsNotificationLink(SPECIFIC_DAY_PLANNING)} />
                </div>
              )}
            </div>
          }
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <FeedsCalendarWidget
            isEditing={isEditing}
            isLoading={isLoadingPlanningDetails}
            toggleShowContour={toggleShowContour}
            showContour={showContour}
            handleCancelChanges={handleCancelChanges}
            handleCloseEdit={handleCloseEdit}
            handleResetFilterAndNavigate={handleResetFilterAndNavigate}
            handleChangeCalendarDate={(date) => handleResetFilterAndNavigate(
              urlPageFeedsPlanningDetails({
                feedSource: FEEDS_SOURCE_MAP.PLANNING,
                date: date?.format(DATE_FORMAT),
              }),
            )}
            handleNavigate={() => handleNavigate(
              urlPageFeedsPlanningDetailsEdit({
                feedSource: FEEDS_SOURCE_MAP.PLANNING,
                date,
              }),
            )}
          />
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Sidebar
          visible={isFilterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={600}
        >
          <FormFeedsFilter
            innerRef={storageFilterFormRef}
            isSubmitting={isLoadingStorageImages}
            initialValues={initFilterValues}
            formErrors={formErrors}
            feedSource={feedSource}
            onCancel={() => {
              setResetManuallyTriggered(true);
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
              handleUnselectAll();
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
              handleUnselectAll();
            }}
          />
        </Sidebar>

        <PrivateComponent allowedPermissions={[ Permissions.CONTENT.FEEDS.PLANNING.STORAGE ]}>
          <Col
            xs={24}
            md={12}
            lg={hasPlanningPermission ? 14 : 24}
            xxl={hasPlanningPermission ? 16 : 24}
          >
            <Spinner spinning={isLoadingStorageImages || isLoadingPlanningDetails} tip=''>
              <Card
                width="100%"
                title={(
                  <Row gutter={[ 16, 16 ]} justify='space-between'>
                    <Col><IntlMessages id="ui-general-storage-images" /></Col>
                    <Col>
                      <Row gutter={[ 16, 16 ]}>
                        {isEditing && (
                          <>
                            <Col>
                              <Button
                                size="small"
                                disabled={isEmptyMultiselect}
                                onClick={handleUnselectAll}
                              >
                                <IntlMessages id="planning-board-cancel-multiselect" />
                              </Button>
                            </Col>

                            <Col>
                              <ActionButton
                                className=''
                                size="small"
                                onClick={handleSelectAll}
                                disabled={isSelectedAll}
                                title={intl.formatMessage({ id: 'ui-general-select-all' })}
                              />
                            </Col>

                            <Col>
                              <Popconfirm
                                title={
                                  <>
                                    <b><IntlMessages id="planning-board-multiselect-confirmation-title" /></b>
                                    <br />
                                    <IntlMessages id="planning-board-multiselect-confirmation-text" />
                                  </>
                                }
                                onConfirm={addToBoardSelectedItems}
                                placement="top"
                                disabled={isEmptyMultiselect || !publishMaxLimitExceeded}
                                okText={<IntlMessages id="ui-general-ok" />}
                                cancelText={<IntlMessages id="ui-general-cancel" />}
                                icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                                okButtonProps={{ danger: false }}
                              >
                                <Tooltip title={isEmptyMultiselect ? <IntlMessages id="planning-board-multiselect-add-to-board-hint" /> : ''}>
                                  <Button
                                    size="small"
                                    type="primary"
                                    disabled={isEmptyMultiselect}
                                    onClick={!publishMaxLimitExceeded ? addToBoardSelectedItems : ''}
                                  >
                                    <IntlMessages id="feeds-card-add-to-board" />
                                  </Button>
                                </Tooltip>
                              </Popconfirm>
                            </Col>
                          </>
                        )}
                        <Col>
                          <ActionButton
                            title=''
                            className=''
                            size='small'
                            isActive={!isEmpty(initFilterValues)}
                            icon={<Filter size={18} />}
                            onClick={toggleFilterSidebar}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              >
                <Row
                  justify='space-between'
                  className='hp-pb-18 center'
                  style={{ marginTop: '-6px', alignItems: 'center' }}
                >
                  <TotalLabel total={isEmpty(formErrors) ? getTotalCount : 0} />
                  {isEditing && (
                    <SelectedLabel total={selectedItems.length} />
                  )}
                </Row>

                <div
                  id="scrollableDiv"
                  ref={scrollableDivRef}
                  style={{ height: maxStorageHeight, overflow: 'auto' }}
                >
                  {!isLoadingPlanningDetails && !isEmpty(groupedItemsList) && itemsInStorage?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={itemsLength}
                      next={handleLoadNextData}
                      scrollableTarget="scrollableDiv"
                      scrollThreshold='100px'
                      hasMore={hasMore}
                      loader={isLoadingStorageImages && <div className='centered-item'><Spin /></div>}
                    >
                      <div className='content-card-grid content-card-grid--small hp-mb-32'>
                        {itemsInStorage.map((item) => (
                          <div key={item.id} className="hp-position-relative">
                            {isEditing && (
                              <div className='multiselect-card-checkbox'>
                                <Checkbox
                                  checked={isSelectedStorageItem(item.id)}
                                  onChange={(event) => handleCheckboxChange(item.id, event.target.checked)}
                                />
                              </div>
                            )}
                            <ContentCard
                              key={item.id}
                              data={item}
                              showContour={showContour}
                              onClick={handleCardClick}
                              isSelectedStorageItem={isSelectedStorageItem(item.id)}
                            />
                          </div>
                        ))}
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <div className='hp-mt-64'>
                      <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
                    </div>
                  )}
                </div>
              </Card>
            </Spinner>
          </Col>
        </PrivateComponent>

        <PrivateComponent allowedPermissions={[ Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS ]}>
          <Col
            xs={24}
            md={12}
            lg={hasStoragePermission ? 10 : 24}
            xxl={hasStoragePermission ? 8 : 24}
          >
            <Spinner spinning={isLoadingPlanningDetails} tip=''>
              <PlanningBoardDetailsBadge date={date} isEditing={isEditing}>
                <div className={`feeds-calendar-widget ${isAnimationStopped ? 'stop-animation' : ''}`}>
                  <Card
                    width="100%"
                    title={
                      <PlanningBoardDetailsHeader
                        isEditing={isEditing}
                        handleCancelChanges={handleCancelChanges}
                        isLoadingPlanningDetails={isLoadingPlanningDetails}
                      />
                    }
                    className={getCardBackground(planningBoardImages?.length ?? 0, date)}
                  >
                    <div id='planningContainer' ref={planningContainerRef}>
                      <FeedsPlanningBoardImages
                        initialImages={planningBoardImages ?? []}
                        isEditing={isEditing}
                        isResetPlanningBoardImages={isResetPlanningBoardImages}
                        showContour={showContour}
                        handleCardClick={handleCardClick}
                        toggleUnpublishModal={toggleUnpublishModal}
                        handleUpdateTotal={handleUpdateTotal}
                        isLoadingPlanningDetails={isLoadingPlanningDetails}
                      />
                    </div>
                  </Card>
                </div>
              </PlanningBoardDetailsBadge>
            </Spinner>
          </Col>
        </PrivateComponent>
      </Row>
    </>
  );
};

export default PageFeedsPlanningDetailsContent;
