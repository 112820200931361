import React from 'react';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import { PiWaveSineDuotone } from 'react-icons/pi';
import { RiBarChartFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Tooltip } from 'antd';
import { hasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageFeedsPlanningDetails from '../../../../../urls/urlPageFeedsPlanningDetails';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import { FEEDS_SOURCE_MAP } from '../../../pages/Feeds/PageFeeds.const';
import { getCardBackground, getMedianOrAvgColor } from './FeedsPlanningBoardCalendarCells.const';



const FeedsPlanningBoardItem = ({ item }) => {
  const imagesCount = item?.images_count ?? 0;
  const cardBackground = getCardBackground(imagesCount, item.date);
  const isCurrentDay = dayjs(new Date(item.date)).startOf('day').isSame(dayjs(new Date()).startOf('day'));

  return (
    <div className={cardBackground}>
      <div className="board-card-header-container">
        <div className="board-card-header">
          <div className={isCurrentDay ? 'day-number-circled' : 'day-number'}>
            {dayjs(item.date).format('D')}
          </div>
          <div className="week-day">{capitalize(dayjs(item.date).format('dd'))}</div>
        </div>
      </div>

      {imagesCount ? (
        <>
          <div className="board-card-content-h3">
            <IntlMessages id="ui-general-published" />
            :&nbsp;
            <strong>{imagesCount}</strong>
          </div>
          <div className="board-card-content-stats">
            <Tooltip
              placement="top"
              title={<IntlMessages id="feeds-calendar-average" />}
            >
              <Row className='hp-align-items-center'>
                <PiWaveSineDuotone size="18" className="hp-mr-4" />
                <Col style={getMedianOrAvgColor(item?.average)}>
                  {item.average ?? EMPTY_VALUE_PLACEHOLDER}
                </Col>
              </Row>
            </Tooltip>
            &nbsp;/&nbsp;
            <Tooltip
              placement="top"
              title={<IntlMessages id="feeds-calendar-median" />}
            >
              <Row className='hp-align-items-center'>
                <RiBarChartFill size="18" className="hp-mr-4" />
                <Col style={getMedianOrAvgColor(item?.median)}>
                  {item.median ?? EMPTY_VALUE_PLACEHOLDER}
                </Col>
              </Row>
            </Tooltip>
          </div>
        </>
      ) : (
        <div className={`feed-board-empty ${cardBackground}`}>
          <IntlMessages id="ui-general-empty" />
        </div>
      )}
    </div>
  );
};

const FeedsPlanningBoardCalendarCells = ({ data }) => {
  return (
    <div
      className="board-container"
      style={{ maxHeight: window.innerHeight - 370, overflow: 'auto' }}
    >
      {data.map((item) => (
        <div className='board-container-card' key={item.date}>
          {hasRights([ Permissions.CONTENT.FEEDS.PLANNING.BOARD.DETAILS ]) ? (
            <Link className='board-container-card__link' to={urlPageFeedsPlanningDetails({ feedSource: FEEDS_SOURCE_MAP.PLANNING, date: item.date })}>
              <FeedsPlanningBoardItem item={item} />
            </Link>
          ) : <FeedsPlanningBoardItem item={item} />}
        </div>
      ))}
    </div>
  );
};

FeedsPlanningBoardItem.propTypes = {
  item: PropTypes.shape({
    date: PropTypes.string,
    images_count: PropTypes.number,
    average: PropTypes.number,
    median: PropTypes.number,
  }),
};

FeedsPlanningBoardCalendarCells.propTypes = {
  data: PropTypes.array.isRequired,
};

export default FeedsPlanningBoardCalendarCells;
