import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const BonusesApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Bonuses', 'BonusesItem' ],
  endpoints: (builder) => {
    return ({
      getBonuses: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          if (!queryParams) {
            return null;
          }

          return ({ url: `/${Config.VITE_HC_API_VERSION}/bonuses${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Bonuses' ],
        keepUnusedDataFor: 1,
      }),
      getOldBonuses: builder.query({
        query: () => ({ url: `/${Config.VITE_HC_API_VERSION}/old-bonuses` }),
      }),
      getSpecificBonus: builder.query({
        query: (bonusId) => ({ url: `/${Config.VITE_HC_API_VERSION}/bonuses/${bonusId}` }),
        transformResponse: (response) => response.data,
        providesTags: () => [ { type: 'BonusesItem' } ],
      }),
      createBonus: builder.mutation({
        query: ({ values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/bonuses`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Bonuses' ],
      }),
      updateBonus: builder.mutation({
        query: ({ bonusId, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/bonuses/${bonusId}`,
          method: 'PUT',
          body: values,
        }),
        invalidatesTags: () => [ 'Bonuses', 'BonusesItem' ],
      }),
      deleteBonus: builder.mutation({
        query: (bonusId) => ({
          url: `/${Config.VITE_HC_API_VERSION}/bonuses/${bonusId}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Bonuses' ],
      }),
      unpublishBonus: builder.mutation({
        query: (bonusId) => ({
          url: `/${Config.VITE_HC_API_VERSION}/bonuses/${bonusId}/unpublish`,
          method: 'POST',
        }),
        invalidatesTags: () => [ 'Bonuses', 'BonusesItem' ],
      }),
    });
  },
});

export const {
  useGetBonusesQuery,
  useGetOldBonusesQuery,
  useGetSpecificBonusQuery,
  useCreateBonusMutation,
  useUpdateBonusMutation,
  useDeleteBonusMutation,
  useUnpublishBonusMutation,
} = BonusesApiSlice;
