import React from 'react';
import { Descriptions, Tag } from 'antd';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Link, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageCategoryDetails from '../../../../urls/urlPageCategoryDetails';
import { imageStatuses } from '../../../../const/imageStatuses';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { FEEDS_SOURCE_MAP } from '../../pages/Feeds/PageFeeds.const';
import { getLabelsColors } from '../ContentCardTags/ContentCardTags.const';
import getFormattedDate from '../../../../lib/getFormattedDate';
import StatusTag from '../../../../components/StatusTag';
import CollapsibleText from '../../../../components/CollapsibleText';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';



const PreviewImageDescription = ({ data }) => {
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const mainCategory = data?.categories?.data.filter((item) => item.is_main)[0];
  const nonMainCategories = data?.categories?.data.filter((category) => !category.is_main);

  return (
    <Descriptions
      className="preview-image"
      title=''
      layout='vertical'
      column={1}
    >
      <Descriptions.Item label={<IntlMessages id="content-images-main-category-label" />}>
        {mainCategory && data?.categories?.data?.length > 0 ? (
          <div>
            {HasRights([ Permissions.CONTENT.CATEGORIES.VIEW ]) ? (
              <Link to={urlPageCategoryDetails({ categoryId: mainCategory.id })} target="_blank">
                <Tag className='hp-mb-4' color={appColors.blue}>
                  <CollapsibleText text={capitalize(mainCategory.name)} maxLength='20' />
                </Tag>
              </Link>
            ) : (
              <Tag className='hp-mb-4' color={appColors.blue}>
                <CollapsibleText text={capitalize(mainCategory.name)} maxLength='20' />
              </Tag>
            )}
          </div>
        ) : <IntlMessages id='ui-general-not-selected' />}
      </Descriptions.Item>

      <Descriptions.Item contentStyle={{ flexWrap: 'wrap' }} label={<IntlMessages id="ui-general-categories" />}>
        {nonMainCategories?.length > 0 ?
          nonMainCategories.map((category) => {
            return (
              <span key={category.id}>
                {HasRights([ Permissions.CONTENT.CATEGORIES.VIEW ]) ? (
                  <Link to={urlPageCategoryDetails({ categoryId: category.id })} target="_blank">
                    <Tag className='hp-mb-4' color={appColors.lightIndigo}>
                      <CollapsibleText text={capitalize(category.name)} maxLength='20' />
                    </Tag>
                  </Link>
                ) : (
                  <Tag className='hp-mb-4' color={appColors.lightIndigo}>
                    <CollapsibleText text={capitalize(category.name)} maxLength='20' />
                  </Tag>
                )}
              </span>
            );
          }) : <IntlMessages id='ui-general-not-selected' />}
      </Descriptions.Item>

      <Descriptions.Item
        contentStyle={{ flexWrap: 'wrap' }}
        label={<IntlMessages id="content-images-labels-label" />}
      >
        {!isEmpty(data?.labels) ? data.labels?.map((label) => {
          return (
            <Tag
              key={label}
              className='hp-mb-4'
              color={getLabelsColors(label)}
            >
              {capitalize(label)}
            </Tag>
          );
        }) : <IntlMessages id='ui-general-not-selected' />}
      </Descriptions.Item>

      <Descriptions.Item contentStyle={{ flexWrap: 'wrap' }} label={<IntlMessages id="content-images-hashtags-label" />}>
        {data?.hashtags?.data.length > 0 ? data.hashtags.data.map((hashtag) => {
          return (
            <Tag key={hashtag.id} className='hp-mb-4'>{`#${hashtag.hashtag}`}</Tag>
          );
        }) : <IntlMessages id='ui-general-not-selected' />}
      </Descriptions.Item>

      {data.status === imageStatuses.pack && (
        <>
          {data?.published_at && (
            <Descriptions.Item label={<IntlMessages id="ui-general-published-at" />}>
              {getFormattedDate(data?.published_at, DATE_TIME_FORMAT)}
            </Descriptions.Item>
          )}

          {data?.published_to && (
            <Descriptions.Item label={<IntlMessages id="ui-general-published-to" />}>
              {getFormattedDate(data?.published_to, DATE_TIME_FORMAT)}
            </Descriptions.Item>
          )}

          {Boolean(data?.use_utc_zero_time) === true && (
            <Descriptions.Item label={<IntlMessages id="features-use-utc" />}>
              <StatusTag active={data?.use_utc_zero_time} />
            </Descriptions.Item>
          )}
        </>
      )}

      <Descriptions.Item label={<IntlMessages id="ui-general-comment" />}>
        {data?.comment ? (
          <div>{data.comment}</div>
        ) : EMPTY_VALUE_PLACEHOLDER}
      </Descriptions.Item>

      {((![ FEEDS_SOURCE_MAP.ARCHIVED, FEEDS_SOURCE_MAP.TRASHED ].includes(feedSource) || !feedSource) && data?.release_date) && (
        <Descriptions.Item label={<IntlMessages id="ui-general-release-date" />}>
          {dayjs(data?.release_date).format(DATE_TIME_FORMAT)}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

PreviewImageDescription.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.shape({
      data: PropTypes.array,
    }),
    labels: PropTypes.array,
    comment: PropTypes.string,
    status: PropTypes.string,
    release_date: PropTypes.string,
    published_at: PropTypes.string,
    published_to: PropTypes.string,
    use_utc_zero_time: PropTypes.bool,
    hashtags: PropTypes.shape({
      data: PropTypes.array,
    }),
  }),
};

export default PreviewImageDescription;
