import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import range from 'lodash/range';
import { Empty, Skeleton } from 'antd';
import SortableItem from '../../../../components/SortableItem';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER, STATUS_MAP } from '../../../../const/system';
import { useGetLanguagesQuery } from '../../../Translations/api/languagesApiSlice';



const NewsPriorityList = ({ data, isLoading, onOrderChange }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);

  const { data: { data: languages = [] } = { data: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' });

  useEffect(() => {
    if (data.length > 1) {
      const init = range(1, data.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ data ]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || !active) return;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        onOrderChange(newOrder);
        return newOrder;
      });
    }
  };

  const dataTitle = (item) => {
    if (item?.dictionary?.data?.id) {
      const lang = languages?.find((lang) => lang.code === 'ENG');

      if (!lang) {
        return null;
      }

      return item?.dictionary?.data?.structure?.data?.fields?.data?.map((field) => {
        const translation = item?.dictionary?.data?.translations?.data?.find((item) => {
          return item.structure_field_id === field.id && lang.id === item.language_id;
        });

        return (
          <p key={field.id} className="text-start hp-mb-0">
            {translation?.text}
          </p>
        );
      });
    }

    if (item?.legacy_dictionary) {
      return item?.legacy_dictionary?.translation_english?.title;
    }

    return '';
  };

  const willPublished = (values) => {
    if (values?.status === STATUS_MAP.OFF) {
      return (
        <>
          <hr />
          <span className="bold-sm-text">
            {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${dayjs(values?.published_at).format(DATE_TIME_FORMAT)}`}
          </span>
        </>
      );
    }

    return null;
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          {!data.length && !isLoading ? (
            <Empty />
          ) : (
            <DndContext
              modifiers={[ restrictToParentElement ]}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                <div className="priority-container">
                  {items.map((item) => {
                    const position = Number(item) - 1;
                    const newsItem = data[position];

                    return (
                      <SortableItem key={item} id={item}>
                        <div className={`priority-item ${newsItem?.status === STATUS_MAP.OFF ? 'priority-item-off' : ''}`}>
                          <div className="hp-d-flex hp-align-items-center">
                            <LazyLoadImage
                              style={{
                                height: '100px',
                                width: '150px',
                                objectFit: 'contain',
                              }}
                              effect='blur'
                              alt="picture preview"
                              src={newsItem?.icon ? `${newsItem?.icon}?time=${Date.now()}` : EMPTY_IMAGE_PLACEHOLDER}
                              placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
                            />
                            <div className="priority-content">
                              {dataTitle(newsItem)}
                              &nbsp;
                              (ID:
                              {newsItem?.id}
                              )
                              {willPublished(newsItem)}
                            </div>
                          </div>
                        </div>
                      </SortableItem>
                    );
                  })}
                </div>
              </SortableContext>
            </DndContext>
          )}
        </div>
      )}
    </div>
  );
};

NewsPriorityList.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default NewsPriorityList;
