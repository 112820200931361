import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal, Spin, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter, DragOverlay,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { RiCloseFill } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { makeNotification, notificationTypes } from '../../../../lib/makeNotification';
import {
  createTableColumn,
  getCategoryLabelById,
  getColumnInitialValues,
  getInitialColumns,
  restrictToTableBounds,
} from './ReserveStatisticsTable.const';
import makeTableLoadingObject from '../../../../lib/makeTableLoadingObject';
import useToggle from '../../../../app/hooks/useToggle';
import ReserveStatisticsTableLayout from './ReserveStatisticsTableLayout';
import ReserveStatisticsTableDraggableHeader from './ReserveStatisticsTableDraggableHeader';
import ReserveStatisticsTableDraggableRow from './ReserveStatisticsTableDraggableRow';
import ButtonDanger from '../../../../components/ButtonDanger';
import ButtonDraft from '../../../../components/ButtonDraft';
import FormAddReserveStatisticsTableRows from '../../forms/FormAddReserveStatisticsTableRows';
import FormAddEditReserveStatisticsTableColumn from '../../forms/FormAddEditReserveStatisticsTableColumn';
import { useImagesReserveStatisticContext } from '../../contexts/ImagesReserveStatisticContext';



const ReserveStatisticsTable = ({
  initialData = [],
  isLoading = false,
  isEdit = false,
  monthReserveValue = 2,
  isReferenceTable = false,
  categoriesOptions = [],
  formatsOptions = [],
  excludedHashtagIds = '',
  isActiveAddRowModal = false,
  isActiveAddColumnModal = false,
  handleCloseAddRowModal = () => {},
  handleCloseAddColumnModal = () => {},
}) => {
  const intl = useIntl();
  const [ data, setData ] = useState([]);
  const [ columns, setColumns ] = useState([]);
  const [ activeItem, setActiveItem ] = useState(null);
  const [ activeType, setActiveType ] = useState(null);
  const [ filteredData, setFilteredData ] = useState([]);
  const [ filteredCategoriesKeys, setFilteredCategoriesKeys ] = useState([]);
  const [ isActiveRemoveItemModal, toggleRemoveItemModalOpen ] = useToggle();
  const [ isActiveEditItemModal, toggleEditItemModalOpen ] = useToggle();
  const [ removingItem, setRemovingItem ] = useState({});
  const [ updatingItem, setUpdatingItem ] = useState({});

  const {
    updateTableStructure,
    updateInitialTableStructure,
    updateReferenceData,
    updateInitialReferenceData,
  } = useImagesReserveStatisticContext();

  const cellFocusRef = useRef({ rowIndex: null, columnId: null });
  const tableContainerRef = useRef(null);
  const sensors = useSensors(useSensor(PointerSensor));

  const isViewMode = !isEdit && !isReferenceTable;
  const hasTotal = isReferenceTable || isViewMode;
  const canUpdate = isEdit && !isReferenceTable && HasRights([ Permissions.CONTENT.IMAGES.RESERVE_STATISTICS.EDIT_TABLE_STRUCTURE ]);

  const filteredCategoriesOptions = categoriesOptions.filter(
    (option) => !data.some((item) => item.category_name === option.label),
  );

  const handleChangeCellValue = (event, rowIndex, column_id) => {
    let value = parseInt(event.target.value || '0', 10);

    value = Math.max(0, Math.min(value, 9999)); //Set mix-max cell value

    requestAnimationFrame(() => {
      cellFocusRef.current = { rowIndex, columnId: column_id };

      setData((prevData) => {
        return prevData.map((row, index) => {
          if (index === rowIndex) {
            const updatedRow = {
              ...row,
              [column_id]: { ...row[column_id], benchmark: value },
            };

            updatedRow.total = Object.keys(updatedRow)
              .filter((key) => !isNaN(key))
              .reduce((sum, key) => sum + (updatedRow[key]?.benchmark || 0), 0);

            return updatedRow;
          }
          return row;
        });
      });
    });
  };

  const collectDataRows = (data) => {
    return data?.map((item, index) => {
      return {
        id: item?.dataKey,
        order: index + 1,
        category_id: item.category_id,
      };
    }) ?? [];
  };

  const collectDataColumns = (columns) => {
    return columns?.filter((item) => item.key !== 'action' && item.key !== 'category_name')
      .map((item, index) => {
        let formats = null;

        if (item?.params?.formats) {
          if (isArray(item.params.formats)) {
            formats = item.params.formats;
          } else {
            formats = item.params.formats.split(',');
          }
        }

        return {
          id: item?.params?.column_id ?? null,
          order: index + 1,
          taps_from: item?.params?.taps_from ?? null,
          taps_to: item?.params?.taps_to ?? null,
          formats,
        };
      }) ?? [];
  };

  const collectReferenceData = (data) => {
    const referenceData = [];

    data.forEach((item) => {
      const columns = Object.keys(item).filter((key) => !isNaN(key));

      columns.forEach((column) => {
        referenceData.push({
          row_id: item.key,
          column_id: Number(column),
          benchmark: item[column]?.benchmark ?? 0,
        });
      });
    });

    return referenceData;
  };

  const dynamicColumns = Array.from(
    new Map(
      initialData.map((colData) => [
        colData.column_id,
        createTableColumn({
          colData,
          colKey: colData.column_id,
          intl,
          monthReserveValue,
          excludedHashtagIds,
          isReferenceTable,
          isEdit,
          handleChangeCellValue,
        }),
      ]),
    ).values(),
  );

  const groupedData = () => {
    let initialOrder = 1;

    return initialData.reduce((acc, item) => {
      const { row_id, column_id, value, category_name, category_id, benchmark } = item;

      if (!acc[row_id]) {
        acc[row_id] = {
          key: row_id,
          dataKey: row_id,
          category_id,
          category_name,
          total: 0,
          initialOrder,
        };

        initialOrder += 1;
      }

      acc[row_id][column_id] = { value, benchmark };
      acc[row_id].total += isReferenceTable ? benchmark : value;
      return acc;
    }, {});
  };

  const initialColumns = getInitialColumns({
    groupedData,
    isViewMode,
    filteredCategoriesKeys,
    isEdit,
    isReferenceTable,
    dynamicColumns,
    hasTotal,
    canUpdate,
    setRemovingItem,
    toggleRemoveItemModalOpen,
  });


  useEffect(() => {
    //Set filtered data for column total recalculation
    if (filteredCategoriesKeys.length === 0 || !filteredCategoriesKeys) {
      setFilteredData(data);
    }
  }, [ data, filteredCategoriesKeys ]);


  useEffect(() => {
    //Return focus on edited cell after data update
    if (cellFocusRef.current.rowIndex !== null && cellFocusRef.current.columnId !== null) {
      const { rowIndex, columnId } = cellFocusRef.current;
      const inputToFocus = document.querySelector(`[data-row="${rowIndex}"][data-col="${columnId}"]`);

      if (inputToFocus) {
        inputToFocus.focus();
      }
    }
  }, [ filteredData ]);


  useEffect(() => {
    const tableData = Object.values(groupedData());

    tableData.sort((first, second) => first.initialOrder - second.initialOrder);

    setData(tableData);
    setFilteredData(tableData.filter((item) => filteredCategoriesKeys.includes(item.dataKey)));
    setColumns(initialColumns);

    if (isReferenceTable) {
      const refData = collectReferenceData(tableData);

      updateInitialReferenceData(refData);
      updateReferenceData(refData);
    } else {
      updateInitialTableStructure({
        rows: collectDataRows(tableData),
        columns: collectDataColumns(initialColumns),
      });
    }
  }, [ initialData, intl, isEdit, monthReserveValue, isReferenceTable ]);


  useEffect(() => {
    if (isReferenceTable) {
      updateReferenceData(collectReferenceData(data));
    } else {
      updateTableStructure({
        rows: collectDataRows(data),
        columns: collectDataColumns(columns),
      });
    }
  }, [ data, columns, isReferenceTable ]);


  useEffect(() => {
    if (!isEdit) {
      setColumns(initialColumns);
    }
  }, [ filteredCategoriesKeys ]);


  const handleDragStart = ({ active }) => {
    if (active.data.current?.type === 'column') {
      setActiveItem(active.id);
      setActiveType(active.data.current?.type);
    }
  };

  const handleDragEnd = ({ active, over }) => {
    const resetActiveItems = () => {
      setActiveItem(null);
      setActiveType(null);
    };

    if (!active || !over || active.id === over.id) {
      resetActiveItems();
      return;
    }

    const isRowDrag = data.some((item) => item.key === active.id);
    const isColumnDrag = columns.some((col) => col.key === active.id);

    const moveItem = (items, setItems) => {
      const oldIndex = items.findIndex((item) => item.key === active.id);
      const newIndex = items.findIndex((item) => item.key === over.id);

      if (oldIndex !== -1 && newIndex !== -1 && oldIndex !== newIndex) {
        const updatedItems = arrayMove(items, oldIndex, newIndex);

        setItems(updatedItems);
      }
      resetActiveItems();
    };

    if (isRowDrag) {
      moveItem(data, setData);
    }

    if (isColumnDrag) {
      moveItem(columns, setColumns);
    }
  };

  const handleChangeTableParams = (pagination, filters, sorter, extra) => {
    if (isViewMode && filters.category_name) {
      setFilteredData(extra.currentDataSource);
      setFilteredCategoriesKeys(extra.currentDataSource.map((item) => item.key));
    } else {
      setFilteredData([]);
      setFilteredCategoriesKeys([]);
    }
  };

  const handleChangeTableStructure = () => {
    if (!isEmpty(removingItem)) {
      if (removingItem?.type === 'row') {
        setFilteredCategoriesKeys(filteredCategoriesKeys.filter((item) => item !== removingItem.key));
        setData(data.filter((item) => item?.category_id !== removingItem?.category_id));
      }
      if (removingItem?.type === 'column') {
        const columnIndex = removingItem.key.match(/col_(\d+)/);
        const columnKey = columnIndex ? parseInt(columnIndex[1], 10) : null;

        if (columnKey !== null) {
          setColumns(columns.filter((column) => column?.dataIndex !== columnKey));
        }
      }
    }

    toggleRemoveItemModalOpen();

    makeNotification(
      notificationTypes.success,
      intl.formatMessage({ id: removingItem?.type === 'row'
        ? 'form-remove-rows-success-notification'
        : 'form-remove-columns-success-notification' }),
    );
  };

  const handleAddRows = (values) => {
    setData((prevData) => {
      let key = prevData.reduce((latestKey, obj) => {
        return obj.key > latestKey ? obj.key : latestKey;
      }, prevData[0]?.key || 0) + 1;

      const numericKeys = prevData.length > 0
        ? Object.keys(prevData[0]).filter((key) => !isNaN(key))
        : [];

      const addedRows = [];

      values.categories.forEach((item) => {
        const total = numericKeys.reduce((sum, key) => sum + (prevData[0]?.[key]?.value || 0), 0);

        addedRows.push({
          category_name: getCategoryLabelById(item, categoriesOptions),
          category_id: item,
          key,
          dataKey: null,
          total,
        });

        key += 1;
      });
      return [ ...prevData, ...addedRows ];
    });

    makeNotification(notificationTypes.success, intl.formatMessage({ id: 'form-add-rows-success-notification' }));
  };

  const scrollToRightOnColumnAdd = () => {
    requestAnimationFrame(() => {
      const tableContainer = tableContainerRef.current?.querySelector('.ant-table-body');

      if (tableContainer) {
        tableContainer.scrollLeft = tableContainer.scrollWidth;
      }
    });
  };

  const handleAddColumn = (values, excludedHashtagIds) => {
    const actionIndex = columns.findIndex((col) => col.key === 'action');

    // Calculate column index to insert it before Action column
    const newColIndex = actionIndex !== -1 ? actionIndex : columns.length;

    const newColumn = createTableColumn({
      colData: values,
      colKey: Date.now(), // Generate unique key
      intl,
      monthReserveValue,
      excludedHashtagIds,
      isReferenceTable,
      isEdit,
    });

    setColumns((prevColumns) => {
      const newColumns = [ ...prevColumns ];

      newColumns.splice(newColIndex, 0, newColumn);
      return newColumns;
    });

    makeNotification(notificationTypes.success, intl.formatMessage({ id: 'form-add-column-success-notification' }));
    handleCloseAddColumnModal();
    scrollToRightOnColumnAdd();
  };

  const handleCloseEditModal = () => {
    setUpdatingItem({});
    toggleEditItemModalOpen();
  };

  const handleEditColumn = (values, excludedHashtagIds) => {
    const updatedColumnIndex = columns.findIndex((col) => col.key === values.key);

    const updatedColumn = createTableColumn({
      colData: values,
      colKey: values.column_id,
      intl,
      monthReserveValue,
      excludedHashtagIds,
      isReferenceTable,
      isEdit,
    });

    setColumns((prevColumns) => {
      const newColumns = [ ...prevColumns ];

      newColumns[updatedColumnIndex] = updatedColumn;
      return newColumns;
    });

    makeNotification(notificationTypes.success, intl.formatMessage({ id: 'form-edit-column-success-notification' }));
    handleCloseEditModal();
  };

  return (
    <>
      <Modal
        width={400}
        className='reserve-statistics-table__confirmation-modal'
        title={
          <IntlMessages
            id={!isEmpty(removingItem) && removingItem?.type === 'row'
              ? 'images-reserve-statistics-remove-row'
              : 'images-reserve-statistics-remove-col'}
          />
        }
        centered
        visible={isActiveRemoveItemModal}
        onCancel={toggleRemoveItemModalOpen}
        footer={[
          <ButtonDraft
            className='btn-draft'
            key="cancel"
            text={<IntlMessages id='ui-general-cancel' />}
            onClick={toggleRemoveItemModalOpen}
          />,
          <ButtonDanger
            type='primary'
            className='btn-danger'
            key="ok"
            text={<IntlMessages id='ui-general-remove' />}
            onClick={handleChangeTableStructure}
          />,
        ]}
      >
        <ExclamationCircleOutlined className='hp-mr-8 reserve-statistics-table__confirmation-modal-icon' />
        <IntlMessages
          id={!isEmpty(removingItem) && removingItem?.type === 'row'
            ? 'images-reserve-statistics-remove-row-confirmation'
            : 'images-reserve-statistics-remove-column-confirmation'}
        />
        <span className='hp-ml-4' style={{ color: appColors.error }}>
          {removingItem?.type === 'row' ? removingItem.category_name : removingItem?.title}
        </span>
        {' ?'}
      </Modal>

      <Modal
        title={intl.formatMessage({ id: 'images-reserve-statistics-add-rows' })}
        width={440}
        centered
        destroyOnClose
        visible={isActiveAddRowModal}
        onCancel={handleCloseAddRowModal}
        footer={null}
        getContainer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <Spin spinning={isLoading}>
          <FormAddReserveStatisticsTableRows
            categoriesOptions={filteredCategoriesOptions}
            onCancel={handleCloseAddRowModal}
            onSubmit={(values) => {
              handleAddRows(values);
              handleCloseAddRowModal();
            }}
          />
        </Spin>
      </Modal>

      <Modal
        title={intl.formatMessage({ id: isActiveEditItemModal ? 'images-reserve-statistics-edit-col' : 'images-reserve-statistics-add-col' })}
        width={440}
        centered
        destroyOnClose
        visible={isActiveAddColumnModal || isActiveEditItemModal}
        onCancel={isActiveEditItemModal ? handleCloseEditModal : handleCloseAddColumnModal}
        footer={null}
        getContainer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <Spin spinning={isLoading}>
          <FormAddEditReserveStatisticsTableColumn
            initialValues={getColumnInitialValues(updatingItem, columns)}
            formatsOptions={formatsOptions}
            onCancel={isActiveEditItemModal ? handleCloseEditModal : handleCloseAddColumnModal}
            isActiveEditItemModal={isActiveEditItemModal}
            onSubmit={(values) => {
              if (isActiveEditItemModal) {
                handleEditColumn(values, excludedHashtagIds);
              } else {
                handleAddColumn(values, excludedHashtagIds);
              }
            }}
          />
        </Spin>
      </Modal>

      <ReserveStatisticsTableLayout isLoading={isLoading}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          modifiers={[ restrictToTableBounds ]}
        >
          <SortableContext
            items={columns.map((col) => col.key)}
            strategy={horizontalListSortingStrategy}
          >
            <div ref={tableContainerRef}>
              <Table
                bordered
                loading={makeTableLoadingObject(isLoading)}
                rowKey="key"
                onChange={handleChangeTableParams}
                dataSource={data}
                pagination={false}
                columns={columns.map((col) => ({
                  ...col,
                  title: col.sortable ? (
                    <ReserveStatisticsTableDraggableHeader
                      key={col.key}
                      id={col.key}
                      title={col.title}
                      modalTitle={col.modalTitle}
                      isEdit={isEdit}
                      canUpdate={canUpdate}
                      handleDeleteItem={setRemovingItem}
                      handleUpdateItem={setUpdatingItem}
                      toggleRemoveItemModalOpen={toggleRemoveItemModalOpen}
                      toggleEditItemModalOpen={toggleEditItemModalOpen}
                    />
                  ) : col.title,
                }))}
                scroll={{ x: '100%', y: `calc(100vh - ${hasTotal ? '390px' : '340px'})` }}
                components={{
                  body: {
                  // eslint-disable-next-line react/no-unstable-nested-components
                    row: ({ children, ...props }) => (
                      <SortableContext
                        items={data.map((row) => row.key)}
                        strategy={verticalListSortingStrategy}
                      >
                        <ReserveStatisticsTableDraggableRow
                        /* eslint-disable-next-line react/jsx-props-no-spreading,react/prop-types */
                          id={props['data-row-key']} {...props} isEdit={isEdit}
                          isReferenceTable={isReferenceTable}
                        >
                          {children}
                        </ReserveStatisticsTableDraggableRow>
                      </SortableContext>
                    ),
                  },
                }}
                /* eslint-disable-next-line react/no-unstable-nested-components */
                summary={hasTotal ? () => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        index={0}
                        fixed="left"
                        align="center"
                      >
                        <b><IntlMessages id='ui-general-total' /></b>
                      </Table.Summary.Cell>
                      {columns.slice(1, -1).map((col, index) => (
                        <Table.Summary.Cell
                          key={col.key || index}
                          index={index + 1}
                          align="center"
                        >
                          <b>
                            {filteredData.reduce((sum, row) => {
                              const value = row[col.dataIndex]
                                ? row[col.dataIndex][isReferenceTable ? 'benchmark' : 'value']
                                : 0;

                              return sum + (value || 0);
                            }, 0)}
                          </b>
                        </Table.Summary.Cell>
                      ))}
                      <Table.Summary.Cell
                        fixed="right"
                        align="center"
                        index={columns.length - 1}
                      >
                        <b>{filteredData.reduce((sum, row) => sum + (row.total || 0), 0)}</b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                ) : null}
              />
            </div>
          </SortableContext>

          {activeType === 'column' && activeItem && (
            <DragOverlay>
              <div className='drag-overlay-active-item'>
                {columns.find((col) => col.key === activeItem)?.title || ''}
              </div>
            </DragOverlay>
          )}
        </DndContext>
      </ReserveStatisticsTableLayout>
    </>
  );
};

ReserveStatisticsTable.propTypes = {
  initialData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  monthReserveValue: PropTypes.number,
  isReferenceTable: PropTypes.bool,
  isActiveAddRowModal: PropTypes.bool,
  isActiveAddColumnModal: PropTypes.bool,
  handleCloseAddRowModal: PropTypes.func,
  handleCloseAddColumnModal: PropTypes.func,
  categoriesOptions: PropTypes.array,
  formatsOptions: PropTypes.array,
  excludedHashtagIds: PropTypes.string,
};

export default ReserveStatisticsTable;
