import React from 'react';
import { PrivateRoute } from '../../components/HasRights/HasRights';
import { Permissions } from '../../const/permissions';
import urlPageDictionaries from '../../urls/urlPageDictionaries';
import urlPageDictionaryCreate from '../../urls/urlPageDictionaryCreate';
import urlPageDictionaryEdit from '../../urls/urlPageDictionaryEdit';
import urlPageTranslationDetails from '../../urls/urlPageTranslationDetails';
import urlPageDictionariesImport from '../../urls/urlPageDictionariesImport';
import urlPageDictionariesImportSuccess from '../../urls/urlPageDictionariesImportSuccess';
import PageDictionaries from '../../domains/Translations/pages/Dictionaries/PageDictionaries';
import PageDictionaryCreate from '../../domains/Translations/pages/Dictionaries/PageDictionaryCreate';
import PageDictionaryEdit from '../../domains/Translations/pages/Dictionaries/PageDictionaryEdit';
import PageTranslationsDetails from '../../domains/Translations/pages/Translations/PageTranslationDetails';
import PageDictionariesImport from '../../domains/Translations/pages/Dictionaries/PageDictionariesImport';
import PageDictionariesImportSuccess from '../../domains/Translations/pages/Dictionaries/PageDictionariesImportSuccess';



export const dictionariesRoutes = ({ urlPrefix = null }) => [
  {
    path: urlPageDictionaries({ urlPrefix }),
    element: (
      <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.LIST.VIEW_PAGE ]}>
        <PageDictionaries />
      </PrivateRoute>
    ),
  },
  {
    path: urlPageDictionaryCreate({ urlPrefix }),
    element: (
      <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.LIST.CREATE ]}>
        <PageDictionaryCreate />
      </PrivateRoute>
    ),
  },
  {
    path: urlPageDictionaryEdit({ urlPrefix }),
    element: (
      <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.EDIT ]}>
        <PageDictionaryEdit />
      </PrivateRoute>
    ),
  },
  {
    path: urlPageTranslationDetails({ urlPrefix }),
    element: (
      <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.DETAILS.VIEW ]}>
        <PageTranslationsDetails />
      </PrivateRoute>
    ),
  },
  {
    path: urlPageDictionariesImport({ urlPrefix }),
    element: (
      <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.FILES.IMPORT ]}>
        <PageDictionariesImport />
      </PrivateRoute>
    ),
  },
  {
    path: urlPageDictionariesImportSuccess({ urlPrefix }),
    element: (
      <PrivateRoute allowedPermissions={[ Permissions.TRANSLATIONS.DICTIONARIES.FILES.IMPORT ]}>
        <PageDictionariesImportSuccess />
      </PrivateRoute>
    ),
  },
];
