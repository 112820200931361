import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const NewsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'News' ],
  endpoints: (builder) => {
    return ({
      getNews: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          if (!queryParams) {
            return null;
          }

          return ({ url: `/${Config.VITE_HC_API_VERSION}/news${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
          };
        },
        providesTags: [ 'News' ],
      }),
      getNewsPriority: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/news/settings/priorities${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'NewsPriority' ],
      }),
      updateNewsPriority: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/news/settings/priorities`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'NewsPriority' ],
      }),
      createNews: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/news`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'News', 'Dictionaries' ],
      }),
      updateNews: builder.mutation({
        query: ({ id, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/news/${id}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: (news) => [ { type: 'News', id: news?.id }, { type: 'News' }, { type: 'Dictionaries' } ],
      }),
      deleteNews: builder.mutation({
        query: (id) => ({
          url: `/${Config.VITE_HC_API_VERSION}/news/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'News', 'Dictionaries' ],
      }),
    });
  },
});

export const {
  useGetNewsQuery,
  useGetNewsPriorityQuery,
  useUpdateNewsPriorityMutation,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useDeleteNewsMutation,
} = NewsApiSlice;
