import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const FeedsPlanningApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getFeedsPlanningBoard: builder.query({
        query: (params) => {
          const { queryParams } = params;
          const queryData = makeQueryParams(queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/feeds/planning/board${queryData}` });
        },
        transformResponse: (response) => {
          return { data: response.data };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'FeedsPlanning' ],
        keepUnusedDataFor: 0,
      }),
      getFeedsPlanningBoardDetails: builder.query({
        query: (date) => ({
          url: `/${Config.VITE_HC_API_VERSION}/feeds/planning/board/${date}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          return { data: response.data };
        },
        providesTags: [ 'FeedsPlanningDetails' ],
        keepUnusedDataFor: 0,
      }),
      publishFeedsPlanningBoard: builder.mutation({
        query: ({ date, payload }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/feeds/planning/board/${date}/publish`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: () => [ 'FeedsPlanning', 'FeedsPlanningDetails', 'Images' ],
      }),
      unpublishFeedsPlanningBoard: builder.mutation({
        query: ({ date }) => {
          return {
            url: `/${Config.VITE_HC_API_VERSION}/feeds/planning/board/${date}/unpublish`,
            method: 'POST',
          };
        },
        invalidatesTags: () => [ 'Feeds', 'FeedsPlanning', 'FeedsPlanningDetails', 'Images' ],
      }),
    });
  },
});

export const {
  useGetFeedsPlanningBoardQuery,
  useGetFeedsPlanningBoardDetailsQuery,
  useUnpublishFeedsPlanningBoardMutation,
  usePublishFeedsPlanningBoardMutation,
} = FeedsPlanningApiSlice;
