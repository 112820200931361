import React, { useState } from 'react';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Button, Card, Col, List, Row, Space, Tag } from 'antd';
import { useIntl } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import FormStructureField from '../FormStructureField/FormStructureField';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import makeSelectFilterSort from '../../../../lib/makeSelectFilterSort';



const DEFAULT_ENABLED = [ 'ENG' ];

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .required(<IntlMessages id="ui-general-validation-required" />)
    .min(2, 'Min 2 symbol required'),
  language_ids: Yup.array().of(Yup.number()),
  fields: Yup.object().shape({
    data: Yup.array().min(1).of(Yup.object().shape({
      label: Yup.string()
        .required(<IntlMessages id="ui-general-validation-required" />)
        .min(2, 'Min 2 symbol required')
        .max(50, 'Max 50 symbols required'),
      validation: Yup.object().nullable().shape({
        min: Yup.number().label('min').typeError(<IntlMessages id="validation-structures-fields-validation-min-number" />)
          .integer().nullable(),
        max: Yup.number().label('max').typeError(<IntlMessages id="validation-structures-fields-validation-max-number" />)
          .integer().nullable(),
        require: Yup.boolean().nullable(),
      }),
    })),

  }),
});

const FormEditStructure = ({
  onSubmit,
  initialValues,
  isSubmitting,
  isLoading,
  formErrors,
  languages,
}) => {
  const intl = useIntl();

  const [ fields, setFields ] = useState([]);
  const [ fieldsUploaded, setFieldsUploaded ] = useState(false);

  if (!fieldsUploaded && initialValues.fields?.data.length) {
    setFields(initialValues.fields.data);
    setFieldsUploaded(true);
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        const structFields = values.fields.data.map((field) => {
          return {
            label: field.label,
            field_type: 'string',
            validation: {
              min: parseInt(field?.validation?.min, 10) ?? null,
              max: parseInt(field?.validation?.max, 10) ?? null,
              required: field?.validation?.required ?? false,
            },
          };
        });

        onSubmit({
          description: values.description.trim(),
          language_ids: values.language_ids,
          fields: structFields,
        });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty, setValues, values = {} } = props;

        const handleDeleteField = (event, index) => {
          const tempFields = cloneDeep(values.fields).data;

          tempFields.splice(index, 1);

          setFields([ ...tempFields ]);

          setValues({ ...values, fields: { data: tempFields } });
        };

        const handleAddField = () => {
          const newItem = { id: null, label: '', key: Date.now(), validation: { min: null, max: null, required: false } };

          const tempFields = cloneDeep(values.fields).data;

          tempFields.push(newItem);

          setFields([ ...tempFields ]);
          setValues({ ...values, fields: { data: tempFields } });
        };

        return (
          <Form layout="vertical" className='w-full'>
            <Col className="hp-profile-content-list hp-mt-32 hp-ml-24 hp-pb-sm-0 hp-pb-12">
              <Card
                title={<IntlMessages id="structures-details-title" />}
                loading={isLoading}
                style={{ marginBottom: '10px' }}
              >
                <Space direction="vertical" className='hp-mb-8' >
                  <Space>
                    <b className='mr-4'><IntlMessages id='structures-source-field' /></b>
                    <span>{intl.formatMessage({ id: `structures-source-${values.source}` })}</span>
                  </Space>
                  <Space>
                    <b className='mr-4'><IntlMessages id='structures-version-field' /></b>
                    <Tag color='blue'>{values.version}</Tag>
                  </Space>
                </Space>

                <FormItem
                  className='ant-form-item-col'
                  name="description"
                  label={<IntlMessages id="ui-general-description" />}
                  required
                >
                  <Input.TextArea
                    name='description'
                    placeholder={intl.formatMessage({ id: 'form-structures-description-placeholder' })}
                  />
                </FormItem>

                <FormItem
                  label={<IntlMessages id='ui-general-languages' />}
                  name='language_ids'
                  required
                >
                  <Select
                    mode="multiple"
                    showSearch
                    name="language_ids"
                    className='w-full'
                    value={values?.language_ids?.filter((lng) => {
                      return languages.find((item) => item.id === lng);
                    }).sort((id1, id2) => {
                      return id1 - id2;
                    })}
                    optionFilterProp="children"
                    filterOption={(input, option) => makeSelectFilterOption(option.children, input)}
                    filterSort={(optionA, optionB) => makeSelectFilterSort(optionA, optionB)}
                  >
                    {languages.map((lng) => (
                      <Select.Option key={lng.id} value={lng.id} disabled={DEFAULT_ENABLED.indexOf(lng.code) !== -1}>
                        {lng.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>

              </Card>
            </Col>

            <Col className="hp-profile-content-list hp-ml-24 hp-pb-sm-0 hp-pb-12">
              <Card loading={isLoading}>
                <Button type="button" onClick={handleAddField}><IntlMessages id="form-structures-add-field" /></Button>
                {values.fields?.data.length === 0 ? (
                  <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert"><li><IntlMessages id="validation-structures-min-fields" /></li></div></div>
                ) : ''}

                {(values.fields?.data?.length ? (
                  <List direction='vertical'>
                    {fields.map((field, k) => {
                      return (
                        <FormStructureField
                          name='fields.data'
                          values={values}
                          key={field.key + field.id + field.label}
                          index={k}
                          initialValues={{ label: '', ...field }}
                          onDeleteField={(ev) => {
                            handleDeleteField(ev, k);
                          }}
                        />
                      );
                    })}
                  </List>
                ) : null)}

              </Card>
            </Col>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <SubmitButton
                  className="hp-mr-8 hp-mt-8"
                  loading={isSubmitting}
                  disabled={!dirty || !isValid || fields.length !== values.fields?.data?.length}
                >
                  <IntlMessages id='ui-general-submit' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditStructure.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  languages: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

FormEditStructure.defaultProps = {
  formErrors: {},
  languages: [],
  isLoading: true,
};

export default FormEditStructure;
