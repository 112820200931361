import React from 'react';
import { Popconfirm, Row, Tooltip } from 'antd';
import { PiPencil } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Delete } from 'react-iconly';
import { Eye } from 'iconsax-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageAchievementsDetails from '../../../../../urls/urlPageAchievementsDetails';
import getFormattedDate from '../../../../../lib/getFormattedDate';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import { achievementSettings } from '../../../../../const/profileSettings';
import { EMPTY_IMAGE_PLACEHOLDER, EMPTY_IMAGE_PREVIEW } from '../../../../../const/system';
import CollapsibleText from '../../../../../components/CollapsibleText';



export const getAchievementsTableColumns = (handleEdit, handleDelete) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: '80px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-code" />,
    dataIndex: 'code',
    align: 'center',
    width: '90px',
    sorter: true,
  },
  {
    title: <IntlMessages id="ui-general-name" />,
    dataIndex: 'name',
    align: 'center',
    sorter: true,
    render: (value) => <CollapsibleText text={value} />,
  },
  {
    title: <IntlMessages id="ui-general-content" />,
    align: 'center',
    width: '280px',
    render: (value) => {
      const imagesLength = value.images?.data.length;

      return (
        <div className="hp-d-flex">
          {imagesLength > 0 ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {value.images?.data?.slice(0, achievementSettings.previewItems).map((image) => {
                return (
                  <LazyLoadImage
                    className='lazy-load-img-list'
                    effect='blur'
                    alt="picture preview"
                    key={image.id}
                    placeholderSrc={EMPTY_IMAGE_PREVIEW}
                    src={image?.preview?.data?.path ? `${image?.preview?.data?.path}?time=${Date.now()}` : EMPTY_IMAGE_PLACEHOLDER}
                  />
                );
              })}
            </>
          ) : (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-no-attached-images" />}>
              <LazyLoadImage
                className='lazy-load-img-list'
                effect='blur'
                key="empty"
                alt="empty preview"
                placeholderSrc={EMPTY_IMAGE_PREVIEW}
                src={EMPTY_IMAGE_PREVIEW}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    sorter: true,
    render: (value) => getFormattedDate(value),
  },
  {
    title: <IntlMessages id="ui-general-action" />,
    key: 'action',
    align: 'center',
    width: '120px',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          {HasRights([ Permissions.CONTENT.ACHIEVEMENTS.VIEW ], true) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-view" />}>
              <div className="hp-text-right">
                <Link
                  to={urlPageAchievementsDetails({ id: item.id })}
                  target="_blank"
                >
                  <Eye
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                  />
                </Link>
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.CONTENT.ACHIEVEMENTS.EDIT ]) && (
            <Tooltip placement="top" title={<IntlMessages id="ui-general-edit" />}>
              <div className="hp-text-right">
                <PiPencil
                  onClick={() => handleEdit(item.id)}
                  size={20}
                  className="hp-cursor-pointer hp-transition hp-hover-text-color-warning-1 hp-text-color-black-80 hp-m-4"
                />
              </div>
            </Tooltip>
          )}

          {HasRights([ Permissions.CONTENT.ACHIEVEMENTS.DELETE ]) && !item?.images?.data.length && (
            <Popconfirm
              title={<IntlMessages id="ui-general-delete-confirm-message" />}
              placement="top"
              onConfirm={() => {
                handleDelete(item.id);
              }}
              okText={<IntlMessages id="ui-general-yes" />}
              cancelText={<IntlMessages id="ui-general-no" />}
              icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
              okButtonProps={{ danger: true }}
            >
              <Tooltip placement="top" title={<IntlMessages id="ui-general-delete" />}>
                <div className="hp-text-right">
                  <Delete
                    size={20}
                    className="hp-cursor-pointer hp-transition hp-hover-text-color-danger-1 hp-text-color-black-80 hp-m-4"
                  />
                </div>
              </Tooltip>
            </Popconfirm>
          )}
        </Row>
      );
    },
  },
];
