import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const CollectionsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Collections', 'CollectionsPriority', 'CollectionsItem' ],
  endpoints: (builder) => {
    return ({
      getCollections: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          if (!queryParams) {
            return null;
          }

          return ({ url: `/${Config.VITE_HC_API_VERSION}/collections${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
            pagination: response?.meta?.pagination,
            categories: response?.meta?.custom?.categories,
            image_types: response?.meta?.custom?.image_types,
          };
        },
        transformErrorResponse: (response) => response?.data?.errors,
        providesTags: [ 'Collections' ],
        keepUnusedDataFor: 1,
      }),
      getSpecificCollection: builder.query({
        query: (collectionId) => ({ url: `/${Config.VITE_HC_API_VERSION}/collections/${collectionId}` }),
        transformResponse: (response) => response.data,
        providesTags: () => [ { type: 'CollectionsItem' } ],
      }),
      createCollection: builder.mutation({
        query: ({ values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/collections`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Collections', 'CollectionsPriority', 'Template' ],
      }),
      updateCollection: builder.mutation({
        query: ({ collectionId, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/collections/${collectionId}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Collections', 'CollectionsItem', 'CollectionsPriority', 'Template', 'Feeds' ],
      }),
      deleteCollection: builder.mutation({
        query: (collectionId) => ({
          url: `/${Config.VITE_HC_API_VERSION}/collections/${collectionId}`,
          method: 'DELETE',
        }),
        invalidatesTags: () => [ 'Collections', 'CollectionsPriority', 'Template' ],
      }),
      unpublishCollection: builder.mutation({
        query: (collectionId) => ({
          url: `/${Config.VITE_HC_API_VERSION}/collections/${collectionId}/unpublish`,
          method: 'POST',
        }),
        invalidatesTags: () => [ 'Collections', 'CollectionsItem', 'CollectionsPriority' ],
      }),
      getCollectionsPriority: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/collections/settings/priorities${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response?.data,
          };
        },
        providesTags: [ 'CollectionsPriority' ],
      }),
      updateCollectionsPriority: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/collections/settings/priorities`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'CollectionsPriority' ],
      }),
      updateCollectionImage: builder.mutation({
        query: ({ collectionId, imageId, values }) => ({
          url: `/${Config.VITE_HC_API_VERSION}/collections/${collectionId}/images/${imageId}`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'CollectionsItem' ],
      }),
    });
  },
});

export const {
  useGetCollectionsQuery,
  useGetSpecificCollectionQuery,
  useCreateCollectionMutation,
  useUpdateCollectionMutation,
  useUpdateCollectionImageMutation,
  useDeleteCollectionMutation,
  useUnpublishCollectionMutation,
  useGetCollectionsPriorityQuery,
  useUpdateCollectionsPriorityMutation,
} = CollectionsApiSlice;
