import isArray from 'lodash/isArray';
import Yup from '../../../../vendor/yup';
import { imageStatuses } from '../../../../const/imageStatuses';
import { imageTypes } from '../../../../const/imageTypes';
import { imageLabels } from '../../../../const/imageLabels';



export const validationSchema = (
  imageData,
  noLinesHashtagId,
  mysteryCategoryId,
  isInPlanningBoard = false,
) => {
  const hasValidation = imageData.status === imageStatuses.release_on || isInPlanningBoard;

  return Yup.lazy((values) => {
    return (
      Yup.object().shape({
        main_category: Yup.number().when('$mainCategory', {
          is: () => hasValidation,
          then: (schema) => schema.required('Main category is required'),
          otherwise: (schema) => schema.nullable(),
        }),
        categories: Yup.array().of(Yup.number()).nullable(),
        comment: Yup.string()
          .nullable()
          .max(255, 'Maximum number of symbols')
          .test('no-whitespace-only', 'Not allowed to specify only spaces', (value) => {
            return value ? value.trim() !== '' : true;
          }),
        labels: Yup.array().nullable()
          .test(
            'test_label_cargo',
            `${imageLabels.cargo} label is required when format is ${imageTypes.cargo}`,
            (value) => {
              return !(hasValidation
                && imageData.type_content === imageTypes.cargo
                && isArray(value)
                && !value.includes(imageLabels.cargo));
            },
          ).test(
            'test_label_mystery',
            `Image should have ${imageLabels.mystery} label when mystery main category is set`,
            (value) => {
              return !(hasValidation
                && values.main_category === mysteryCategoryId
                && isArray(value)
                && !value.includes(imageLabels.mystery));
            },
          ).test(
            'test_label_blend',
            `Image should have ${imageLabels.blend} label when format is ${imageTypes.sharp} or ${imageTypes.blend} and hashtag #nolines is set`,
            (value) => {
              return !(hasValidation
                && values.hashtags.includes(noLinesHashtagId)
                && (imageData.type_content === imageTypes.blend || imageData.type_content === imageTypes.sharp)
                && isArray(value)
                && !value.includes(imageLabels.blend));
            },
          ),
        hashtags: Yup.array().nullable(),
        publication_time: Yup.string().nullable(),
      })
    );
  });
};
