import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import Spinner from '../../../../../components/Spinner';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import { DATE_DOT_FORMAT, EMPTY_IMAGE_PLACEHOLDER } from '../../../../../const/system';
import { hasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';



const ImageModalCardSimilarImages = ({ data = [], isLoading = false }) => {
  const intl = useIntl();

  return hasRights([ Permissions.CONTENT.IMAGES.TAGS.VIEW_SIMILAR_IMAGES ]) && (
    <Col span={8} className='similar-content-container'>
      <div className='similar-content-container--title ant-modal-title'>
        <IntlMessages id="ui-general-similar-images" />
      </div>
      <div className='similar-content-container--body'>
        <Spinner spinning={isLoading}>
          {!data.length || isLoading ? (
            <EmptyDataPlaceholder placeholder='&nbsp;' />
          ) : (
            <Row gutter={[ 16, 16 ]} align='center'>
              {data.map((image) => {
                return (
                  <Col key={image.id} className='content-card similar-content-card'>
                    <LazyLoadImage
                      effect='blur'
                      alt="similar image"
                      className='similar-content-card--image'
                      placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
                      src={image.preview.data.path}
                    />
                    <div className='card-top-cover-badge card-top-cover-badge--small'>
                      {`${intl.formatMessage({ id: 'ui-general-published' })}: 
                        ${dayjs(image.preview.release_date).format(DATE_DOT_FORMAT)}`}
                    </div>
                    <Row justify='space-between' className='card-bottom-cover-badge'>
                      <div>{`ID: ${image.id}`}</div>
                      <div>{`Bee: ${image.source.data.source_item_id}`}</div>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          )}
        </Spinner>
      </div>
    </Col>
  );
};

ImageModalCardSimilarImages.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default ImageModalCardSimilarImages;
