import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import { FEATURES_SOURCE_MAP } from '../../../Featured/pages/PageFeatures/PageFeatures.const';
import urlPageFeatureDetails from '../../../../urls/urlPageFeatureDetails';
import urlPageCollectionDetails from '../../../../urls/urlPageCollectionDetails';



dayjs.extend(isSameOrBefore);

export const searchFilterFields = [ 'id', 'bee_id', 'categories', 'is_main_category', 'is_without_category', 'layouts', 'hashtags', 'is_exclude_hashtags', 'recommended_types', 'is_exclude_recommended_types', 'formats', 'taps_from', 'taps_to', 'colors_from', 'colors_to', 'released_from', 'released_to' ];

export const CONTENT_TYPE_MAP = {
  DAILY: 'daily',
  FEED: 'feed',
  COLLECTION: 'collection',
  STORY: 'story',
  ACTIVITY: 'activity',
  OTHER: 'other',
};

export const getTypeContentGradient = (contentType) => {
  switch (contentType) {
    case CONTENT_TYPE_MAP.DAILY: return 'radial-gradient(circle, rgb(175 185 173) 0%, rgb(125 220 186) 100%, rgb(255, 255, 255) 100%)';
    case CONTENT_TYPE_MAP.FEED: return 'radial-gradient(circle, rgb(230 141 75) 0%, rgb(253, 252, 136) 100%, rgb(255, 255, 255) 100%)';
    case CONTENT_TYPE_MAP.COLLECTION: return 'radial-gradient(circle, rgb(91 109 220 / 80%) 0%, rgb(91, 203, 220) 100%, rgb(255, 255, 255) 100%)';
    case CONTENT_TYPE_MAP.STORY: return 'radial-gradient(circle, rgb(236 113 111) 0%, rgb(243 201 125) 100%, rgb(255, 255, 255) 100%)';
    case CONTENT_TYPE_MAP.ACTIVITY: return 'radial-gradient(circle, rgba(110,220,91,1) 0%, rgba(91,203,220,1) 100%, rgba(255,255,255,1) 100%)';
    case CONTENT_TYPE_MAP.OTHER: return 'radial-gradient(circle, rgba(71,71,71,1) 0%, rgba(178,190,195,1) 100%, rgb(255, 255, 255) 100%)';
    default: return '';
  }
};

export const getTypeContentRedirectUrl = (contentType, id) => {
  if (contentType === CONTENT_TYPE_MAP.DAILY) {
    return urlPageFeatureDetails({ featureSource: FEATURES_SOURCE_MAP.DAILY, featureId: id });
  }
  if (contentType === CONTENT_TYPE_MAP.FEED) {
    return urlPageFeatureDetails({ featureSource: FEATURES_SOURCE_MAP.DAILY, featureId: id });
  }
  return urlPageCollectionDetails({ collectionId: id });
};

export const FEEDS_SOURCE_MAP = {
  LIST: 'list',
  PLANNING: 'planning',
  RELEASED: 'released',
  ARCHIVED: 'archived',
  TRASHED: 'trashed',
};

export const UPDATED_IMAGE_STATUSES = {
  DRAFT: 'draft',
  ARCHIVE: 'archive',
  TRASH: 'trash',
  DELETED: 'deleted',
};

export const MODAL_MODE = {
  VIEW: 'view',
  UNPUBLISH: 'unpublish',
  EDIT: 'edit',
  REMOVE: 'remove',
};

export const ON_LOAD_LIMIT = 10;
export const PLANNING_BOARD_PUBLISH_LIMIT = 30;
export const PLANNING_BOARD_PUBLISH_DEFAULT_LIMIT = 15;

export const getFeedsSourceTranslation = (source) => {
  switch (source) {
    case FEEDS_SOURCE_MAP.PLANNING: return 'feeds-breadcrumbs-planning';
    case FEEDS_SOURCE_MAP.LIST: return 'feeds-breadcrumbs-list';
    case FEEDS_SOURCE_MAP.RELEASED: return 'feeds-breadcrumbs-released-list';
    case FEEDS_SOURCE_MAP.ARCHIVED: return 'feeds-breadcrumbs-archived-list';
    case FEEDS_SOURCE_MAP.TRASHED: return 'feeds-breadcrumbs-trash-list';

    default: return EMPTY_VALUE_PLACEHOLDER;
  }
};

export const groupDataByDate = (data, source) => {
  const dateField = 'release_date';
  const isReleasedSource = source === FEEDS_SOURCE_MAP.RELEASED;

  const feedsOptions = JSON.parse(localStorage.getItem('feeds_options')) || {};
  const { layouts = [] } = feedsOptions;

  return data.reduce((result, item) => {
    const dateKey = dayjs(item[dateField]).format('D MMMM YYYY');

    if (!result[dateKey]) {
      result[dateKey] = [];
    }

    result[dateKey].push(item);

    if (isReleasedSource) {
      result[dateKey].sort((_a, _b) => {
        const indexA = layouts.indexOf(_a.source_type);
        const indexB = layouts.indexOf(_b.source_type);

        return indexA - indexB;
      });
    }

    return result;
  }, {});
};
