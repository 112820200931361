import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormItem, Select, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { PiDownload } from 'react-icons/pi';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import {
  useGetStructuresQuery,
  useGetStructureVersionsQuery,
} from '../../api/structuresApiSlice';



const validationSchema = Yup.object().shape({
  source: Yup.string().required(),
  version: Yup.string().required(),
});

const FormGenerateTemplate = ({
  onSubmit,
  isSubmitting,
  formErrors,
}) => {
  const intl = useIntl();
  const [ searchQueryParams ] = useSearchParams();
  const [ source, setSource ] = useState(null);
  const [ initialValues, setInitialValues ] = useState({});

  const { data: { data: structures = [] } = { data: [] },
    isFetching: isLoading,
  } = useGetStructuresQuery({ queryParams: 'limit=0' });

  const navigationSource = searchQueryParams.get('source') ?? null;
  const multipleSources = searchQueryParams.get('multipleSources') ?? null;
  const defaultSource = structures?.find((item) => item.source === navigationSource);

  const sourceOptions =  useMemo(() => {
    let structuresData = structures;

    if (multipleSources) {
      structuresData = structures.filter((item) => multipleSources.toLowerCase().includes(item.source.toLowerCase()));
    }
    return structuresData.map((item) => {
      return {
        label: intl.formatMessage({ id: `structures-source-${item.source}` }),
        value: item.id,
      };
    }).sort((first, second) => first.label - second.label);
  }, [ structures ]);

  const { data: versions, isLoading: isVersionsLoading } = useGetStructureVersionsQuery(source, { skip: !source });

  const versionsOptions = useMemo(() => {
    if (!versions || isVersionsLoading) {
      return [];
    }

    return versions?.data.map((item) => {
      return {
        label: `v${item.version}`,
        value: item.version,
      };
    }).sort((first, second) => first.label - second.label);
  }, [ versions?.data, isVersionsLoading ]);

  useEffect(() => {
    if (defaultSource && !multipleSources) {
      setInitialValues((original) => {
        return { ...original, source: defaultSource.id };
      });

      setSource(defaultSource.id);
    }
  }, [ defaultSource ]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      onSubmit={(values) => {
        const structure = versions?.data?.find((item) => item.version === values.version);

        onSubmit({ structure_id: structure.id });
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, dirty } = props;

        return (
          <Form layout="vertical" className='w-full'>
            <FormItem
              className='ant-form-item-col'
              name="source"
              label={<IntlMessages id="ui-dictionaries-source-field" />}
              required
            >
              <Select
                name="source"
                disabled={defaultSource && !multipleSources}
                loading={isLoading}
                options={sourceOptions}
                placeholder={<IntlMessages id="form-dictionaries-source-placeholder" />}
                onChange={async (value) => {
                  setSource(value);
                  await setFieldValue('version', null);
                }}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="version"
              label={<IntlMessages id="form-dictionaries-version-label" />}
              required
            >
              <Select
                name="version"
                loading={isVersionsLoading}
                options={versionsOptions}
                placeholder={<IntlMessages id="form-dictionaries-version-placeholder" />}
              />
            </FormItem>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <PiDownload className="btn-icon-mr-1" />
                  <IntlMessages id='ui-general-download' />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormGenerateTemplate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
};

FormGenerateTemplate.defaultProps = {
  formErrors: {},
};

export default FormGenerateTemplate;
