import { Link } from 'react-router-dom';
import React from 'react';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import urlPageRBACUserDetails from '../../../../../urls/urlPageRBACUserDetails';
import CollapsibleText from '../../../../../components/CollapsibleText';
import { DATE_TIME_FORMAT, EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import getFormattedDate from '../../../../../lib/getFormattedDate';



export const RESET_TYPE_FEED = 'reset-feed';
export const RESET_TYPE_DAILY = 'reset-daily';

export const getStaticContentResetLogsListTableColumns = (tableSize) => [
  {
    title: <IntlMessages id="ui-general-id" />,
    dataIndex: 'id',
    align: 'center',
    width: tableSize.width * 0.1,
  },
  {
    title: <IntlMessages id="ui-general-user" />,
    align: 'center',
    width: tableSize.width * 0.3,
    render: (item) => {
      return (
        <div>
          {HasRights([ Permissions.ADMINISTRATION.USERS.USER_DETAILS.VIEW ]) && item?.user_id ? (
            <Link to={urlPageRBACUserDetails({ userId: item?.user_id })} target="_blank">
              <CollapsibleText text={item?.user?.data?.name || EMPTY_VALUE_PLACEHOLDER} />
            </Link>
          ) : <CollapsibleText text={item?.user?.data?.name || EMPTY_VALUE_PLACEHOLDER} />}
        </div>
      );
    },
  },
  {
    title: <IntlMessages id="static-content-reset-logs-table-type-col" />,
    dataIndex: 'type',
    align: 'center',
    width: tableSize.width * 0.3,
    render: (value) => <IntlMessages id={`static-content-reset-logs-table-type-${value}`} />,
  },
  {
    title: <IntlMessages id="ui-general-created-at" />,
    dataIndex: 'created_at',
    align: 'center',
    width: tableSize.width * 0.3,
    render: (value) => getFormattedDate(value, DATE_TIME_FORMAT, true),
  },
];
