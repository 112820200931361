import React, { useState } from 'react';
import { Col, Row, Space, Pagination, Modal, Tooltip, Popconfirm, Tabs } from 'antd';
import { Filter } from 'iconsax-react';
import { RiCloseFill, RiErrorWarningLine } from 'react-icons/ri';
import { CopyOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { hasRights, HasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import { searchFilterFieldsImages } from '../../../forms/FormImagesFilter/FormImagesFilter.const';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import appColors from '../../../../../const/colors';
import { MODAL_MODE } from '../../Feeds/PageFeeds.const';
import urlDownloadImageArchive from '../../../../../urls/urlDownloadImageArchive';
import getModalTitleByModalMode from '../../../../../lib/getModalTitleByModalMode';
import getModalWidthByModalMode from '../../../../../lib/getModalWidthByModalMode';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import Spinner from '../../../../../components/Spinner';
import Sidebar from '../../../../../components/Sidebar';
import useToggle from '../../../../../app/hooks/useToggle';
import ContentCard from '../../../components/ContentCard';
import FormImagesFilter from '../../../forms/FormImagesFilter';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useDownload from '../../../../../app/hooks/useDownload';
import applyFilter from '../../../../../lib/applyFilter';
import resetFilter from '../../../../../lib/resetFilter';
import handleResponse from '../../../../../lib/handleResponse';
import TotalLabel from '../../../../../components/TotalLabel';
import ImageModalCard from '../../../components/ImageModalCard';
import ImageModalCardTags from '../../../components/ImageModalCard/ImageModalCardTags';
import {
  useCopyImageMutation,
  useGetImagesQuery,
  useReopenImageMutation,
} from '../../../api/imagesApiSlice';



const { TabPane } = Tabs;


const PageImages = () => {
  const intl = useIntl();
  const [ filterOpen, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ showContour, toggleShowContour ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ activeModalTab, setActiveModalTab ] = useState('general-info');
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const { download, isLoading: isDownloading } = useDownload({ method: 'POST' });
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);

  const {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
  } = useQueryParams({
    searchFilterFieldsImages,
    paginationInit: { page: 1, limit: 48 } });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} },
  isFetching,
  error: formErrors = {},
  } = useGetImagesQuery({ queryParams: searchParams.toString() });

  const [ copyImage, { isLoading: isCopying } ] = useCopyImageMutation();
  const [ reopenImage, { isLoading: isReopening } ] = useReopenImageMutation();

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
    setActiveModalTab('general-info');
  };

  const handleDownload = (id) => {
    download(urlDownloadImageArchive({ id }));
  };

  const handleCopy = (id) => {
    copyImage({ id });
  };

  const handleReopen = (id) => {
    reopenImage({ id })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'content-image-reopen-success',
        [ () => window.open(response.url) ],
      ))
      .catch((error) => handleResponse('error', intl, 'content-image-reopen-failed', [], error));
  };

  const handlePreviewModalClose = () => {
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
  };

  const actions = (item) => {
    const canClone = HasRights([ Permissions.CONTENT.IMAGES.CLONE ]);
    const canReopen = HasRights([ Permissions.CONTENT.IMAGES.REOPEN ]);
    const canDownload = HasRights([ Permissions.CONTENT.IMAGES.DOWNLOAD ]);

    const actions = [];

    if (canDownload) {
      actions.push(
        <Tooltip key="download" placement="top" title={<IntlMessages id="ui-general-download" />}>
          <DownloadOutlined
            style={{ color: appColors.gray }}
            onClick={() => {
              handleDownload(item.source.data.source_item_id);
            }}
          />
        </Tooltip>,
      );
    }

    if (item.is_copy || item.is_reopened) {
      return actions;
    }

    if (canClone) {
      actions.push(
        <Popconfirm
          key="clone"
          title={<IntlMessages id="content-images-clone-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleCopy(item.id);
          }}
          okText={<IntlMessages id="ui-general-yes" />}
          cancelText={<IntlMessages id="ui-general-no" />}
          icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          okButtonProps={{ danger: true }}
        >
          <Tooltip placement="top" title={<IntlMessages id="ui-general-clone" />}>
            <CopyOutlined style={{ color: appColors.mediumGreen }} />
          </Tooltip>
        </Popconfirm>,
      );
    }

    if (canReopen) {
      actions.push(
        <Popconfirm
          key="reopen"
          title={<IntlMessages id="content-images-reopen-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleReopen(item.id);
          }}
          okText={<IntlMessages id="ui-general-yes" />}
          cancelText={<IntlMessages id="ui-general-no" />}
          icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
          okButtonProps={{ danger: true }}
        >
          <Tooltip placement="top" title={<IntlMessages id="ui-general-reopen" />}>
            <SyncOutlined style={{ color: appColors.orange }} />
          </Tooltip>
        </Popconfirm>,
      );
    }

    return actions;
  };

  const handleChangeModalMode = (mode) => {
    setModalMode(mode);
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  const isLoading = isFetching || isCopying || isReopening || isDownloading;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs breadCrumbActive={<IntlMessages id='content-images-breadcrumbs' />} />

        <Col>
          <Space>
            <TotalLabel total={dataPagination.total} />
            <ActionButton
              onClick={toggleShowContour}
              title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
            />
            <ActionButton
              title=""
              isActive={!isEmpty(initFilterValues)}
              icon={<Filter size={18} />}
              onClick={toggleFilterSidebar}
            />
          </Space>
        </Col>

        <Sidebar
          visible={filterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={600}
        >
          <FormImagesFilter
            isSubmitting={false}
            formErrors={formErrors}
            initialValues={initFilterValues}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields: searchFilterFieldsImages,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>
      </Row>

      <Modal
        width={getModalWidthByModalMode(modalMode)}
        title={getModalTitleByModalMode(modalMode)}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <Tabs
          type="card"
          activeKey={activeModalTab}
          onChange={(key) => setActiveModalTab(key)}
        >
          <TabPane tab={<IntlMessages id='ui-general-info' />} key="general-info">
            <ImageModalCard
              data={previewImage}
              modalMode={modalMode}
              handleModalClose={handlePreviewModalClose}
              handleModalMode={(mode) => handleChangeModalMode(mode)}
              initialEditImageErrors={{}}
            />
          </TabPane>

          {hasRights([
            Permissions.CONTENT.IMAGES.TAGS.LIST,
            Permissions.CONTENT.IMAGES.TAGS.UPDATE,
          ]) && (
            <TabPane tab={<IntlMessages id='ui-general-tags' />} key="tags-info">
              {activeModalTab === 'tags-info' && (
                <ImageModalCardTags previewData={previewImage} />
              )}
            </TabPane>
          )}
        </Tabs>
      </Modal>

      {isLoading ? (
        <div className="spin-overlay">
          <div className="spin-wrapper">
            <Spinner spinning={isLoading} />
          </div>
        </div>
      ) : (
        <div>
          {images?.length ? (
            <>
              <div className='content-card-grid'>
                {images.map((item) => (
                  <ContentCard
                    key={item.id}
                    data={item}
                    showContour={showContour}
                    actions={actions(item)}
                    onClick={handleCardClick}
                    allowImageClick
                  />
                ))}
              </div>
              <Row>
                <Col span={24}>
                  {dataPagination.total && (
                    <Pagination
                      total={dataPagination.total}
                      showTotal={showTotal}
                      pageSize={pagination.limit}
                      current={pagination.page}
                      pageSizeOptions={[ '12', '24', '48', '96' ]}
                      showSizeChanger
                      onChange={(page, size) => {
                        handleChangeTableParams({
                          current: page,
                          pageSize: size,
                        }, {}, {});
                      }}
                    />
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <div className='hp-mt-64'>
              <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PageImages;
