import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Card, Modal, Dropdown, Button, Space, Menu, Popconfirm } from 'antd';
import { useIntl } from 'react-intl';
import { RiArrowLeftLine, RiCloseFill } from 'react-icons/ri';
import { DownOutlined } from '@ant-design/icons';
import { PiPencilSimpleLine, PiTrashSimple } from 'react-icons/pi';
import dayjs from 'dayjs';
import { MdOutlineUnpublished } from 'react-icons/md';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import { Permissions } from '../../../../../const/permissions';
import { PrivateComponent } from '../../../../../components/HasRights/HasRights';
import Spinner from '../../../../../components/Spinner';
import mkPreparedTemplateData from '../../../utils/mkPreparedTemplateData';
import urlPageCollections from '../../../../../urls/urlPageCollections';
import urlPageCollectionEdit from '../../../../../urls/urlPageCollectionEdit';
import handleResponse from '../../../../../lib/handleResponse';
import getConfigurations from '../../../../../lib/getConfigurations';
import useToggle from '../../../../../app/hooks/useToggle';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import ActionButton from '../../../../../layout/components/action-button';
import ContentCard from '../../../components/ContentCard';
import { COLLECTIONS_SOURCE } from '../PageCollections/PageCollections.const';
import CollectionDetailsCard from '../../../components/CollectionDetailsCard';
import ImageModalCardBodyView from '../../../components/ImageModalCard/ImageModalCardBodyView';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import { TYPE_DICTIONARY } from '../../../../Template builder/const/templates';
import { useGetDictionaryQuery } from '../../../../Translations/api/dictionariesApiSlice';
import { useGetLanguagesQuery } from '../../../../Translations/api/languagesApiSlice';
import {
  useDeleteCollectionMutation,
  useGetSpecificCollectionQuery,
  useUnpublishCollectionMutation } from '../../../api/collectionsApiSlice';



const PageCollectionDetails = () => {
  const intl = useIntl();
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});

  const { data: collectionDetails = {}, isLoading } = useGetSpecificCollectionQuery(collectionId);
  const dictionaryField = collectionDetails?.dynamic_data?.data?.find((item) => item.type === TYPE_DICTIONARY);

  const { data: collectionDictionary = {} } = useGetDictionaryQuery({
    id: dictionaryField?.value,
    include: 'translations,structure,structure.fields.translations',
  }, { skip: !dictionaryField?.value });

  const { data: {
    data: languages = [],
  } = { data: [] } } = useGetLanguagesQuery({ queryParams: 'limit=0' }, { skip: !dictionaryField });

  const data = mkPreparedTemplateData({
    templateData: collectionDetails?.template?.data?.fields?.data,
    dynamicData: collectionDetails?.dynamic_data?.data,
    dictionary: collectionDictionary,
    languages,
  });

  const [ deleteCollection, { isLoading: isCollectionDeleting } ] = useDeleteCollectionMutation();
  const [ unpublishCollection, { isLoading: isUnpublishCollectionLoading } ] = useUnpublishCollectionMutation();

  const images = collectionDetails?.images?.data ?? [];
  const collectionConfig = getConfigurations(COLLECTIONS_SOURCE);
  const allowUpdatePast = collectionConfig?.publish?.allow_update_past ?? true;
  const releaseDate = collectionDetails?.released_at;
  const allowUpdate = allowUpdatePast ? true : dayjs().isSameOrBefore(dayjs(releaseDate).subtract(-1, 'days'), 'day');
  const allowUnpublishPast = collectionConfig?.publish?.allow_unpublish_past ?? true;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(collectionDetails?.is_published) :
    Boolean(collectionDetails?.is_published) && dayjs().isSameOrBefore(dayjs(releaseDate), 'day');

  const isLoadingData = isLoading || isCollectionDeleting || isUnpublishCollectionLoading;


  useEffect(() => {
    const { hash } = window.location;

    if (hash) {
      const element = document.querySelector(hash);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ collectionDictionary, collectionDetails.images?.data ]);

  const handleUnpublish = () => {
    unpublishCollection(collectionId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleDelete = () => {
    deleteCollection(collectionId)
      .unwrap()
      .then(() => {
        handleResponse('success', intl, 'ui-general-deleted-successfully', [ () => navigate(urlPageCollections()) ]);
      })
      .catch((error) => handleResponse('error', intl, 'ui-general-delete-failed', [], error));
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };


  const actionItems = (
    <Menu>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.EDIT ]}>
        {allowUpdate && (
          <Menu.Item
            key="edit"
            icon={<PiPencilSimpleLine size={18} />}
            onClick={() => navigate(urlPageCollectionEdit({ collectionId }))}
          >
            <IntlMessages id="ui-general-edit" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.EDIT ]}>
        {allowUnpublish && (
          <Menu.Item
            key="unpublish"
            icon={<MdOutlineUnpublished size={18} />}
            onClick={handleUnpublish}
          >
            <IntlMessages id="ui-general-unpublish" />
          </Menu.Item>
        )}
      </PrivateComponent>
      <PrivateComponent allowedPermissions={[ Permissions.CONTENT.COLLECTIONS.DELETE ]}>
        {!collectionDetails.is_published && collectionDetails.images?.data.length === 0 &&
          <Popconfirm
            title={<IntlMessages id="ui-general-delete-confirm-message" />}
            placement="top"
            onConfirm={handleDelete}
          >
            <Menu.Item
              key="delete"
              icon={<PiTrashSimple size={18} />}
              onClick={() => {}}
            >
              <IntlMessages id="ui-general-delete" />
            </Menu.Item>
          </Popconfirm>}
      </PrivateComponent>
    </Menu>
  );

  return (
    <>
      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <ImageModalCardBodyView data={previewImage} />
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCollections()}>
              <IntlMessages id='collections-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={collectionDetails?.id ?? EMPTY_VALUE_PLACEHOLDER}
        />

        <Col md={10} span={24} className='text-end'>
          <Space size='middle'>
            <PrivateComponent
              allowedPermissions={[
                Permissions.CONTENT.COLLECTIONS.EDIT,
                Permissions.CONTENT.COLLECTIONS.DELETE,
              ]}
            >
              <Dropdown overlay={actionItems}>
                <Button onClick={(ev) => ev.preventDefault()}>
                  <Space size='middle'>
                    <IntlMessages id='ui-general-action' />
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </PrivateComponent>

            <ActionButton
              icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
              onClick={() => {
                navigate(urlPageCollections());
              }}
              title={<IntlMessages id='ui-general-back-to-list' />}
            />
          </Space>
        </Col>
      </Row>

      <Spinner spinning={isLoadingData}>
        <Row gutter={[ 32, 32 ]} className="hp-mb-32">
          <Col span={24}>
            <CollectionDetailsCard
              data={collectionDetails}
              collectionDictionary={collectionDictionary}
              languages={languages}
              templateCardsData={data}
            />
          </Col>
        </Row>

        <Row gutter={[ 32, 32 ]}>
          <Col span={24}>
            <Card
              id="attached-images"
              loading={isLoadingData}
              title={<IntlMessages id="achievements-attached-images" />}
            >
              <div className={images.length > 0 ? 'content-card-grid' : ''}>
                {images.length > 0 ?
                  images.map((item) => {
                    return (
                      <ContentCard
                        key={item.id}
                        data={item}
                        onClick={handleCardClick}
                      />
                    );
                  }) : <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-attached-images" />} />}
              </div>
            </Card>
          </Col>
        </Row>
      </Spinner>
    </>
  );
};

export default PageCollectionDetails;
