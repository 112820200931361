import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const HashtagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getHashtags: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          return ({ url: `/${Config.VITE_HC_API_VERSION}/hashtags${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        providesTags: [ 'Hashtags' ],
      }),
    });
  },
});

export const {
  useGetHashtagsQuery,
} = HashtagsApiSlice;
