import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import { EMPTY_IMAGE_PLACEHOLDER } from '../../../../../const/system';



export const getCompressedImagesTableColumns = () => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '120px',
    sorter: true,
  },
  {
    title: 'Preview',
    align: 'center',
    width: '150px',
    render: (item) => (
      <LazyLoadImage
        effect='blur'
        alt="deeplink preview"
        placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
        src={item?.preview?.data?.path}
      />
    ),
  },
  {
    title: 'Compressed preview',
    align: 'center',
    width: '150px',
    render: (item) => (
      <LazyLoadImage
        effect='blur'
        alt="deeplink preview"
        placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
        src={item?.preview?.data?.path?.replace('preview.png', 'preview_cd.jpg')}
      />
    ),
  },
  {
    title: 'Old file',
    align: 'center',
    render: (item) => (
      <>
        <p>
          Old:
          <a
            href={item?.preview?.data?.path?.replace('preview.png', '')} target="_blank"
            rel="noreferrer"
          >
            {item?.preview?.data?.path?.replace('preview.png', '')}
          </a>
        </p>
        <p>
          Compressed:
          <a
            href={item?.preview?.data?.path?.replace('preview.png', '_cd')} target="_blank"
            rel="noreferrer"
          >
            {item?.preview?.data?.path?.replace('preview.png', '_cd')}
          </a>
        </p>
      </>
    ),
  },
];
