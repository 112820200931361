import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import range from 'lodash/range';
import { Empty, Skeleton, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../const/permissions';
import urlPagePopupsDetails from '../../../../urls/urlPagePopupsDetails';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import SortableItem from '../../../../components/SortableItem';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  EMPTY_IMAGE_PLACEHOLDER,
  EMPTY_VALUE_PLACEHOLDER,
} from '../../../../const/system';



const PopupPriorityList = ({ data, isLoading, onOrderChange, selectedDate }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);

  useEffect(() => {
    if (data.length !== 0) {
      const init = range(1, data.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ data ]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || !active) return;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        onOrderChange(newOrder);
        return newOrder;
      });
    }
  };

  const willPublished = (values) => {
    return (
      <div>
        {values?.is_published && (
          <>
            <hr />
            <span className="bold-sm-text">
              {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${dayjs(values?.published_at).format(DATE_TIME_FORMAT)}`}
            </span>
          </>
        )}
        {!values?.is_published && (
          <Tag className='hp-mt-4 tags-padding-sm'>
            <IntlMessages id="ui-general-not-published" />
          </Tag>
        )}
      </div>
    );
  };

  const isDragDisabled = dayjs(selectedDate).isBefore(dayjs().subtract(1, 'day').format(DATE_FORMAT));

  return (
    <div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          {!data.length ? (
            <Empty />
          ) : (
            <DndContext
              modifiers={[ restrictToParentElement ]}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                <div className="priority-container">
                  {items.map((item) => {
                    const position = Number(item) - 1;
                    const popup = data[position];

                    return (
                      <SortableItem key={item} id={item} disabled={isDragDisabled}>
                        <div className={`hp-d-flex hp-d-flex-between priority-item ${!popup?.is_published ? 'priority-item-off' : ''}`}>
                          <div className="hp-d-flex hp-align-items-center" style={{ width: '100%', overflow: 'hidden' }}>
                            <LazyLoadImage
                              style={{ minWidth: '150px' }}
                              className='priority-item__image'
                              effect='blur'
                              alt="picture preview"
                              placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
                              src={popup?.preview ? `${popup?.preview}?time=${Date.now()}` : EMPTY_IMAGE_PLACEHOLDER}
                            />
                            <div className="priority-content priority-item__title">
                              <Tooltip
                                placement="topLeft"
                                title={`${popup?.title ?? EMPTY_VALUE_PLACEHOLDER} (ID: ${popup?.id})`}
                              >
                                {`${popup?.title ?? EMPTY_VALUE_PLACEHOLDER} (ID: ${popup?.id})`}
                              </Tooltip>
                              <div className="hp-pt-4">
                                {willPublished(popup)}
                              </div>
                            </div>
                          </div>

                          <PrivateComponent allowedPermissions={[ Permissions.OTHER.POPUPS.FIND ]}>
                            <Link
                              className='hp-mr-16'
                              target="_blank"
                              to={urlPagePopupsDetails({ popupId: popup?.id })}
                            >
                              <IntlMessages id='ui-general-show' />
                            </Link>
                          </PrivateComponent>
                        </div>
                      </SortableItem>
                    );
                  })}
                </div>
              </SortableContext>
            </DndContext>
          )}
        </div>
      )}
    </div>
  );
};

PopupPriorityList.propTypes = {
  data: PropTypes.array.isRequired,
  selectedDate: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default PopupPriorityList;
