import React, { useState } from 'react';
import { Card, Col, Row, Skeleton } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageTemplates from '../../../../../urls/urlPageTemplates';
import urlPageTemplateDetails from '../../../../../urls/urlPageTemplateDetails';
import { useCreateTemplateMutation, useUpdateTemplateMutation, useGetTemplateQuery } from '../../../api/templatesApiSlice';
import Spinner from '../../../../../components/Spinner';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import FormCreateEditTemplate from '../../../forms/FormCreateEditTemplates';
import DictionariesProvider from '../../../../Translations/providers/DictionariesProvider';
import handleResponse from '../../../../../lib/handleResponse';
import { countries } from '../../../../../const/countries';
import { PLATFORM_OPTIONS, TYPE_OBJECT } from '../../../const/templates';
import { useGetAllVersionsStructuresQuery } from '../../../../Translations/api/structuresApiSlice';



const PageCreateEditTemplate = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { templateId } = useParams();
  const isEditTemplate = pathname.endsWith('edit');
  const navigate = useNavigate();
  const [ templateErrors, setTemplateErrors ] = useState(null);
  const [ createTemplate, { isLoading: isCreateTemplateLoading } ] = useCreateTemplateMutation();
  const [ updateTemplate, { isLoading: isUpdateTemplateLoading } ] = useUpdateTemplateMutation();
  const {
    data = { data: {} },
    isFetching,
  } = useGetTemplateQuery(templateId, { skip: !isEditTemplate });
  const template = data?.data ?? {};
  const searchParams = localStorage.getItem('searchParams');
  const { data: structures, isLoading: isStructuresLoading } = useGetAllVersionsStructuresQuery();

  const handleCreateTemplate = async (values) => {
    let action;

    if (isEditTemplate) {
      action = updateTemplate({ id: templateId, payload: values });
    } else {
      action = createTemplate({ payload: values });
    }

    action.unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        isEditTemplate
          ? 'ui-general-updated-successfully'
          : 'ui-general-created-successfully',
        [
          () => navigate(urlPageTemplates({ searchParams })),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        isEditTemplate ? 'ui-general-update-failed' : 'ui-general-create-failed',
        [ () => {
          if (isObject(error?.data)) {
            setTemplateErrors(error.data.errors);
          }
        } ],
        error,
      ));
  };

  const buildInitialTemplateValues = () => {
    const itemsPositions = cloneDeep(template?.fields?.data ?? [])
      .filter((el) => el.type !== TYPE_OBJECT)
      .sort((el, el2) => el.position > el2.position ? 1 : -1)
      .map((el, ind) => {
        if (el.position !== ind) {
          el.position = ind;
        }

        return el;
      })
      .map((el) => el.is_main);

    return {
      app_key: template.app_key,
      title: template.title,
      is_active: template.is_active,
      content_type_id: template?.content_type?.data.id,
      target: {
        min_version: template.target.data.min_version ?? '',
        max_version: template.target.data.max_version ?? '',
        platform: template.target.data.platform,
        countries: [ ...template?.target?.data.countries ?? [] ].sort(),
      },
      // additional formik prop for sorting items "is_main" value
      is_main: itemsPositions,
    };
  };

  const breadCrumbActive = !isEditTemplate
    ? <IntlMessages id="template-create-breadcrumbs" />
    : <IntlMessages id="ui-general-edit" />;

  const breadCrumbParent2 = !isEditTemplate ? null
    : <Link to={urlPageTemplateDetails({ templateId })}>{template?.title}</Link>;

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageTemplates({ searchParams })}>
              <IntlMessages id='templates-breadcrumbs' />
            </Link>
          }
          breadCrumbParent2={breadCrumbParent2}
          breadCrumbActive={breadCrumbActive}
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <DictionariesProvider>
              <Spinner spinning={isFetching}>
                {isStructuresLoading ? <Skeleton /> : (
                  <FormCreateEditTemplate
                    initialValues={!isEmpty(template)
                      ? buildInitialTemplateValues()
                      : {
                        is_active: false,
                        target: {
                          countries: countries.map((country) => country.value).sort(),
                          platform: PLATFORM_OPTIONS.map((country) => country.value),
                        },
                      }}
                    onSubmit={handleCreateTemplate}
                    isSubmitting={isCreateTemplateLoading || isUpdateTemplateLoading}
                    formErrors={templateErrors}
                    treeData={template?.fields?.data ?? []}
                    canEditTreeAndTarget={!isEditTemplate || template.can_edit_template_data}
                    canEditStatus={!isEditTemplate || template.can_edit_template_status}
                    structures={structures}
                    isStructuresLoading={isStructuresLoading}
                  />
                )}
              </Spinner>
            </DictionariesProvider>
          </Card>
        </Col>
      </Row>
    </>
  );

};


export default PageCreateEditTemplate;

