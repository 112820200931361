import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import appColors from '../../const/colors';



export const SortableItem = ({
   id,
   children,
   disabled = false,
   className = '',
   ...restProps
 }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  const style = {
    ...restProps.style,
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition,
    opacity: isDragging ? 0.8 : 1,
    cursor: !disabled ? (isDragging ? 'grabbing' : 'grab') : 'default',
    backgroundColor: appColors.white,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={className}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps} {...(!disabled ? attributes : {})} {...(!disabled ? listeners : {})}
    >
      {children}
    </div>
  );
};

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};


export default SortableItem;
