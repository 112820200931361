import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DndContext,
  closestCenter,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import isEmpty from 'lodash/isEmpty';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableItem } from './FeedsPlanningBoardImages.const';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import { getDefaultEmptyCards } from '../../pages/Feeds/PageFeedsPlanningDetails/PageFeedsPlanningDetailsContent.const';
import { hasRights } from '../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../const/permissions';
import { imageStatuses } from '../../../../const/imageStatuses';



const FeedsPlanningBoardImages = ({
  initialImages = [],
  isEditing = false,
  handleCardClick,
  showContour = false,
  isResetPlanningBoardImages = false,
  toggleUnpublishModal = () => {},
  handleUpdateTotal = (_isReturn) => {},
  isLoadingPlanningDetails = false,
}) => {
  const { currentlyAddedToBoardItem, updateItems, updateCurrentlyAddedToBoardItem } = usePlanningBoardImagesContext();
  const [ imagesList, setImagesList ] = useState(initialImages);

  const defaultCardsLimit = Math.max(15, imagesList.length + 1);
  const hasImages = imagesList.length > 0;

  const hasActionButton = hasRights([
    Permissions.CONTENT.FEEDS.IMAGE.SET_ARCHIVED,
    Permissions.CONTENT.FEEDS.IMAGE.SET_TRASHED,
    Permissions.CONTENT.FEEDS.IMAGE.SET_DRAFT,
  ]) || imagesList.some((item) => item.status === imageStatuses.ready_for_release);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  );

  useEffect(() => {
    if (!isEmpty(imagesList)) {
      updateItems(imagesList);
    }
  }, [ imagesList ]);

  useEffect(() => {
    if (isResetPlanningBoardImages || initialImages) {
      setImagesList(initialImages);
    }
  }, [ initialImages, isResetPlanningBoardImages ]);

  useEffect(() => {
    if (!isEmpty(currentlyAddedToBoardItem)) {
      const items = [ ...imagesList, currentlyAddedToBoardItem ];

      setImagesList(items);
      updateItems(items);
    }
  }, [ currentlyAddedToBoardItem ]);

  const returnToStorage = (item) => {
    const updatedList = imagesList.filter((boardItem) => boardItem.id !== item.id);

    updateCurrentlyAddedToBoardItem({});
    updateItems(updatedList);
    setImagesList(updatedList);
    handleUpdateTotal(true);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!isEditing) return;

    // Если элемент перемещён
    if (active?.id && over?.id && active.id !== over.id) {
      setImagesList((prevItems) => {
        // Получаем старую и новую позиции элементов
        const oldIndex = prevItems.findIndex((item) => `item-${item.id}` === active.id);
        const newIndex = prevItems.findIndex((item) => `item-${item.id}` === over.id);

        // Меняем элементы местами
        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext
      modifiers={[ restrictToParentElement ]}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext
        items={imagesList.map((item) => `item-${item.id}`)}
        strategy={rectSortingStrategy}
      >
        <div style={{ position: 'relative' }}>
          {/* Основной слой */}
          {!isLoadingPlanningDetails && hasImages && (
            <div className="content-card-grid content-card-grid--mobile-planning draggable-items-layer">
              {imagesList.map((item, index) => (
                <SortableItem
                  key={item.id}
                  id={`item-${item.id}`}
                  index={index}
                  item={item}
                  handleCardClick={handleCardClick}
                  toggleUnpublishModal={toggleUnpublishModal}
                  isEditing={isEditing}
                  showContour={showContour}
                  returnToStorage={returnToStorage}
                />
              ))}
            </div>
          )}
          {/* Подложка: Пустые слоты */}
          <div
            style={{ top: hasImages ? '-10px' : '-20px' }}
            className="content-card-grid content-card-grid--mobile-planning empty-slots-layer"
          >
            {getDefaultEmptyCards(
              hasActionButton,
              1,
              defaultCardsLimit,
              isEditing,
            )}
          </div>
          {/* Генерация пустых слотов */}
          <div
            style={{
              top: hasImages ? '20px' : '-10px',
              margin: hasImages ? '0 0 20px 0' : '20px 0 0 0',
            }}
            className="content-card-grid content-card-grid--mobile-planning empty-slots-cover"
          >
            {getDefaultEmptyCards(
              hasActionButton,
              imagesList.length + 1,
              defaultCardsLimit,
              isEditing,
              true,
            )}
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

FeedsPlanningBoardImages.propTypes = {
  initialImages: PropTypes.array,
  isEditing: PropTypes.bool,
  showContour: PropTypes.bool,
  isResetPlanningBoardImages: PropTypes.bool,
  handleCardClick: PropTypes.func,
  toggleUnpublishModal: PropTypes.func,
  handleUpdateTotal: PropTypes.func,
  isLoadingPlanningDetails: PropTypes.bool,
};

export default FeedsPlanningBoardImages;
