import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const permissionsApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Permissions' ],
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: (params) => {
        const queryParams = makeQueryParams(params.queryParams);

        if (!queryParams) {
          return null;
        }

        return ({ url: `/${Config.VITE_HC_API_VERSION}/permissions${queryParams}` });
      },
      transformResponse: (response) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
        };
      },
      providesTags: [ 'Permissions' ],
      keepUnusedDataFor: 1,
    }),
  }),
});

export const {
  useGetPermissionsQuery,
} = permissionsApiSlice;
