export const Permissions = {
  ADMINISTRATION: {
    USERS: {
      VIEW_ALL: [
        'get-all-roles',
        'get-all-permissions',
        'get-specific-role',
        'get-specific-permission',
        'assign-permissions-to-user',
        'assign-roles-to-user',
        'delete-specific-user',
        'get-specific-user',
        'get-all-users',
        'update-specific-user',
      ],
      USERS_LIST: {
        VIEW_PAGE: 'get-all-users',
        DELETE_USER: 'delete-specific-user',
        CREATE_USER: 'create-user',
      },
      USER_DETAILS: {
        VIEW: 'get-specific-user',
        UPLOAD_AVATAR: 'upload-specific-user-avatar',
        UPDATE_USER_STATUS: 'update-specific-user-status',
        UPDATE_USER: 'update-specific-user',
        EDIT_OWN_ROLE: 'update-self-role',
        GET_USER_PERMISSIONS: 'get-specific-user',
        UPDATE_USER_PERMISSIONS: 'assign-permissions-to-user',
        GET_USER_ROLES: 'get-specific-user',
        UPDATE_USER_ROLES: 'assign-roles-to-user',
      },
    },
    PERMISSIONS: {
      VIEW_ALL: [
        'get-all-permissions',
        'get-specific-permission',
      ],
      VIEW_LIST: 'get-all-permissions',
      VIEW_DETAILS: 'get-specific-permission',
    },
    ROLES: {
      VIEW_ALL: [
        'get-all-roles',
        'delete-specific-role',
        'create-role',
        'get-specific-role',
        'get-specific-role-permissions',
        'update-specific-role',
        'update-specific-role-permissions',
      ],
      ROLES_LIST: {
        VIEW_PAGE: 'get-all-roles',
        DELETE_ROLE: 'delete-specific-role',
        CREATE_ROLE: 'create-role',
      },
      ROLE_DETAILS: {
        VIEW: 'get-specific-role',
        EDIT_ROLE_DETAILS: 'update-specific-role',
        EDIT_PERMISSIONS: 'attach-permissions-to-role',
        GET_ROLE_PERMISSIONS: 'get-specific-role-permissions',
      },
    },
    LOGS: {
      LIST: 'get-all-logs',
      DETAILS: 'get-specific-log',
      VIEW_MODEL: 'get-specific-log-model',
    },
  },

  TRANSLATIONS: {
    LANGUAGES: {
      LANGUAGES_LIST: {
        VIEW_PAGE: 'get-all-languages',
        CREATE: 'create-language',
        EDIT: 'update-specific-language',
        DELETE: 'delete-specific-language',
      },
    },
    STRUCTURES: {
      LIST: {
        VIEW_PAGE: 'get-all-structures',
        EDIT: 'create-structure',
      },
      DETAILS: {
        GET: 'get-specific-structure',
        EDIT: 'create-structure',
      },
    },
    DICTIONARIES: {
      LIST: {
        VIEW_PAGE: 'get-all-dictionaries',
        CREATE: 'create-dictionary',
        DELETE: 'delete-dictionary',
      },
      DETAILS: {
        VIEW: 'get-specific-dictionary',
        EDIT: 'update-specific-dictionary',
      },
      FILES: {
        IMPORT: 'import-dictionaries',
        EXPORT: 'export-specific-dictionary',
        EXPORT_TEMPLATE: 'export-specific-dictionary-template',
      },
    },
  },

  CONTENT: {
    IMAGES: {
      LIST: 'get-all-images',
      FIND: 'get-specific-image',
      VIEW_BEEPANEL: 'view-image-beepanel',
      CLONE: 'clone-specific-image',
      REOPEN: 'reopen-specific-image',
      DOWNLOAD: 'download-specific-image',
      TAGS: {
        LIST: 'get-specific-image-tags-data',
        UPDATE: 'update-specific-image-tags',
        VIEW_SIMILAR_IMAGES: 'get-specific-image-similar-images',
      },
      RESERVE_STATISTICS: {
        LIST: 'get-all-reserve-statistics',
        EDIT_TABLE_STRUCTURE: 'update-reserve-statistics-structure',
        EDIT_REFERENCES: 'update-reserve-statistics-benchmarks',
      },
    },
    CATEGORIES: {
      LIST: 'get-all-categories',
      VIEW: 'get-specific-category',
      CREATE: 'create-category',
      EDIT: 'update-specific-category',
      DELETE: 'delete-specific-category',
      OPTIONS: {
        EDIT: 'update-specific-category-options',
      },
      PRIORITIES: {
        LIST: 'get-categories-priorities',
        EDIT: 'update-categories-priorities',
      },
    },
    ACHIEVEMENTS: {
      LIST: 'get-all-achievements',
      VIEW: 'get-specific-achievement',
      CREATE: 'create-achievement',
      EDIT: 'update-specific-achievement',
      DELETE: 'delete-specific-achievement',
    },
    BONUSES: {
      LIST: 'get-all-bonuses',
      VIEW: 'get-specific-bonus',
      CREATE: 'create-bonus',
      EDIT: 'update-specific-bonus',
      UNPUBLISH: 'unpublish-specific-bonus',
      DELETE: 'delete-specific-bonus',
    },
    NEWS: {
      LIST: 'get-all-news',
      FIND: 'get-specific-news',
      CREATE: 'create-news',
      EDIT: 'update-specific-news',
      DELETE: 'delete-specific-news',
      PRIORITY: 'update-priority-news',
    },
    COLLECTIONS: {
      LIST: 'get-all-collections',
      VIEW: 'get-specific-collection',
      CREATE: 'create-collection',
      EDIT: 'update-specific-collection',
      UNPUBLISH: 'update-specific-collection',
      DELETE: 'delete-specific-collection',
      PRIORITIES: {
        LIST: 'get-collections-priorities',
        EDIT: 'update-collections-priorities',
      },
    },
    FEEDS: {
      PLANNING: {
        STORAGE: 'get-all-feeds-planning',
        BOARD: {
          LIST: 'get-all-feeds-planning-board',
          DETAILS: 'get-specific-feed-planning-board',
          PUBLISH: 'publish-specific-feed-planning-board',
          UNPUBLISH: 'unpublish-specific-feed-planning-board',
        },
      },
      LIST: 'get-all-feeds-list',
      RELEASED: 'get-all-feeds-released',
      ARCHIVED: 'get-all-feeds-archived',
      TRASHED: 'get-all-feeds-trashed',
      IMAGE: {
        UPDATE: 'update-specific-image',
        SET_DELETE: 'update-specific-image-status-deleted',
        SET_ARCHIVED: 'update-specific-image-status-archive',
        SET_TRASHED: 'update-specific-image-status-trash',
        SET_DRAFT: 'update-specific-image-status-draft',
      },
    },
  },

  FEATURED: {
    FEATURES: {
      DAILY: {
        LIST: 'get-all-featured-daily',
        FIND: 'get-specific-featured-daily',
        CREATE: 'create-featured-daily',
        EDIT: 'update-specific-featured-daily',
        DELETE: 'delete-specific-featured-daily',
      },
      EDITOR_CHOICE: {
        LIST: 'get-all-featured-editors-choice',
        FIND: 'get-specific-featured-editors-choice',
        CREATE: 'create-featured-editors-choice',
        EDIT: 'update-specific-featured-editors-choice',
        DELETE: 'delete-specific-featured-editors-choice',
      },
      EXTERNAL_PROMO: {
        LIST: 'get-all-featured-external-promo',
        FIND: 'get-specific-featured-external-promo',
        CREATE: 'create-featured-external-promo',
        EDIT: 'update-specific-featured-external-promo',
        DELETE: 'delete-specific-featured-external-promo',
      },
      PRIORITY: {
        LIST: 'get-featured-content-priorities',
        UPDATE: 'update-featured-content-priorities',
      },
    },
  },

  CONSTRUCTORS: {
    TEMPLATES: {
      LIST: 'get-all-templates',
      FIND: 'get-specific-template',
      CREATE: 'create-template',
      EDIT: 'update-specific-template',
      DELETE: 'delete-specific-template',
      FILTER: 'filter-all-templates',
      CLONE: 'clone-specific-template',
    },
    PRESETS: {
      LIST: 'get-all-presets',
      FIND: 'get-specific-preset',
      CREATE: 'create-preset',
      EDIT: 'update-specific-preset',
      DELETE: 'delete-specific-preset',
    },
  },

  OTHER: {
    PUSH_ICONS: {
      LIST: {
        VIEW: 'get-all-push-icons',
        CREATE: 'create-push-icon',
        EDIT: 'update-specific-push-icon',
        DELETE: 'delete-specific-push-icon',
      },
    },
    DEEPLINKS: {
      LIST: 'get-all-deeplinks',
      CREATE: 'create-deeplink',
    },
    STATIC_CONTENT_RESET: {
      LIST: 'get-all-static-content-reset-logs',
      RESET: 'reset-static-content',
    },
    POPUPS: {
      LIST: 'get-all-popups',
      FIND: 'get-specific-popup',
      CREATE: 'create-popup',
      EDIT: 'update-specific-popup',
      DELETE: 'delete-specific-popup',
      PRIORITY: {
        LIST: 'get-popups-priorities',
        UPDATE: 'update-popups-priorities',
      },
    },
  },
};

