import { apiSlice } from '../../../app/api/apiSlice';
import Config from '../../../configs/appConfig';
import makeQueryParams from '../../../lib/makeQueryParams';



export const DeeplinksApiSlice = apiSlice.injectEndpoints({
  tagTypes: [ 'Deeplinks' ],
  endpoints: (builder) => {
    return ({
      getDeeplinks: builder.query({
        query: (params) => {
          const queryParams = makeQueryParams(params.queryParams);

          if (!queryParams) {
            return null;
          }

          return ({ url: `/${Config.VITE_HC_API_VERSION}/deeplinks${queryParams}` });
        },
        transformResponse: (response) => {
          return {
            data: response.data,
            pagination: response.meta.pagination,
          };
        },
        providesTags: [ 'Deeplinks' ],
        keepUnusedDataFor: 1,
      }),
      createDeeplink: builder.mutation({
        query: (values) => ({
          url: `/${Config.VITE_HC_API_VERSION}/deeplinks`,
          method: 'POST',
          body: values,
        }),
        invalidatesTags: () => [ 'Deeplinks' ],
      }),
    });
  },
});

export const {
  useGetDeeplinksQuery,
  useCreateDeeplinkMutation,
} = DeeplinksApiSlice;
