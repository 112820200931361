import React, { useState } from 'react';
import { Card, Col, Row } from 'antd';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageTranslationDetails from '../../../../../urls/urlPageTranslationDetails';
import urlPageDictionaries from '../../../../../urls/urlPageDictionaries';
import urlPageFeatures from '../../../../../urls/urlPageFeatures';
import getUrlPathnamePrefix from '../../../../../lib/getUrlPathnamePrefix';
import handleResponse from '../../../../../lib/handleResponse';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import DictionariesProvider from '../../../providers/DictionariesProvider';
import FormCreateDictionary from '../../../forms/FormCreateDictionary';
import PreviewDictionaryStructure from '../../../components/PreviewDictionaryStructure';
import getFeatureSourceTranslation from '../../../../Featured/utils/getFeatureSourceTranslation';
import { useCreateDictionaryMutation } from '../../../api/dictionariesApiSlice';



const PageDictionaryCreate = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ searchQueryParams ] = useSearchParams();
  const navigationSource = searchQueryParams.get('source') ?? null;
  const multipleSources = searchQueryParams.get('multipleSources') ?? null;
  const [ dictionaryErrors, setCreateDictionaryErrors ] = useState(null);

  const urlPrefix = getUrlPathnamePrefix(pathname);

  const [ createLanguage, { isLoading: isUpdateLanguageLoading } ] = useCreateDictionaryMutation();

  const handleCreateDictionary = async (values) => {
    createLanguage({ values })
      .unwrap()
      .then(({ data: dictionary }) => handleResponse(
        'success',
        intl,
        'ui-general-created-successfully',
        [ () => navigate(urlPageTranslationDetails({ urlPrefix, id: dictionary.id, source: navigationSource, multipleSources }), { replace: true }) ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-create-failed',
        [ () => {
          if (isObject(error?.data)) {
            setCreateDictionaryErrors(error.data.errors);
          }
        } ],
        error,
      ));
  };

  return (
    <>
      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={navigationSource ? (
            <Link to={urlPageFeatures({ featureSource: navigationSource })}>
              <IntlMessages id={getFeatureSourceTranslation(navigationSource)} />
            </Link>
          ) : null}
          breadCrumbParent2={
            <Link to={urlPageDictionaries({ urlPrefix, source: navigationSource, multipleSources })}>
              <IntlMessages id='dictionaries-breadcrumbs' />
            </Link>
          }
          breadCrumbActive={<IntlMessages id='ui-general-create' />}
        />
      </Row>
      <Row gutter={32}>
        <Col span={24}>
          <Card title={<IntlMessages id='dictionaries-create-title' />} className="hp-border-color-black-40 hp-card-6">
            <DictionariesProvider>
              <Row gutter={24}>
                <Col span={11}>
                  <FormCreateDictionary
                    formErrors={dictionaryErrors}
                    isSubmitting={isUpdateLanguageLoading}
                    onSubmit={handleCreateDictionary}
                  />
                </Col>
                <Col span={11} offset={2}>
                  <PreviewDictionaryStructure />
                </Col>
              </Row>
            </DictionariesProvider>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PageDictionaryCreate;
