import React from 'react';
import { List, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { LANGUAGE_STATUS } from '../../pages/Languages/PageLanguages/PageLanguages.const';
import { EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';



const { Title, Paragraph } = Typography;

const PreviewStructure = ({ structure, languages }) => {
  const intl = useIntl();

  if (!structure.source) {
    return (
      <span className="hp-text-color-danger-1">
        <IntlMessages id="structures-preview-warning" />
      </span>
    );
  }
  const sortedLanguageIds = structure.language_ids.slice().sort((id1, id2) => {
    return id1 - id2;
  });
  const languagesList = sortedLanguageIds.map((lang) => {
    const language = languages?.find((item) => item.id === lang && item.status === LANGUAGE_STATUS.enabled);

    if (!language) {
      return null;
    }

    return (
      <Tag key={lang}>{language?.name}</Tag>
    );
  });

  return (
    <>
      <Title level={4}>
        {`${intl.formatMessage({ id: `structures-source-${structure.source}` })} v.${structure.version}`}
      </Title>

      <div>
        <div>
          <strong>
            {`${intl.formatMessage({ id: 'ui-general-description' })}: `}
          </strong>
        </div>
        <Paragraph>{structure.description ?? EMPTY_VALUE_PLACEHOLDER}</Paragraph>
      </div>

      <div>
        <div>
          <strong>
            {`${intl.formatMessage({ id: 'ui-general-languages' })}: `}
          </strong>
        </div>
        <div style={{ lineHeight: 2 }}>
          {languagesList}
        </div>
      </div>

      <List
        size="small"
        bordered={false}
        className="preview-structure"
        header={(
          <div>
            <strong>
              {`${intl.formatMessage({ id: 'dictionaries-preview-structure-fields' })}: `}
            </strong>
          </div>
        )}
        dataSource={structure.fields.data}
        renderItem={(item) => (
          <List.Item>
            <div>
              <p>
                {`${intl.formatMessage({ id: 'ui-general-label' })}: `}
                <span style={{ fontWeight: 500 }}>{item.label}</span>
              </p>
              <p style={{ lineHeight: 2 }}>
                {`${intl.formatMessage({ id: 'form-dictionaries-validation-field' })}: `}
                <Tag>
                  {`${intl.formatMessage({ id: 'ui-general-min' })}: ${item.validation.min === null ? '-' : item.validation.min}`}
                </Tag>
                <Tag>
                  {`${intl.formatMessage({ id: 'ui-general-max' })}: ${item.validation.max === null ? '-' : item.validation.max}`}
                </Tag>
                <Tag>
                  {`${intl.formatMessage({ id: 'ui-general-required' })}: ${item.validation.required ? 'true' : 'false'}`}
                </Tag>
              </p>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

PreviewStructure.propTypes = {
  structure: PropTypes.shape({
    source: PropTypes.string,
    language_ids: PropTypes.array,
    version: PropTypes.number,
    description: PropTypes.string,
    fields: PropTypes.object,
  }).isRequired,
  languages: PropTypes.array.isRequired,
};

export default PreviewStructure;
