import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import range from 'lodash/range';
import dayjs from 'dayjs';
import { Skeleton, Tag, Tooltip } from 'antd';
import EmptyDataPlaceholder from '../../../../components/EmptyDataPlaceholder';
import { DATE_TIME_FORMAT, EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import SortableItem from '../../../../components/SortableItem';



const DynamicNewsPriority = ({ news, isLoading, onOrderChange }) => {
  const intl = useIntl();
  const [ initOrder, setInitOrder ] = useState([]);
  const [ items, setItems ] = useState(initOrder);

  useEffect(() => {
    if (news.length !== 0) {
      const init = range(1, news.length + 1).map((item) => `${item}`);

      setInitOrder(init);
      setItems(init);
    }
  }, [ news ]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || !active) return;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        onOrderChange(newOrder);
        return newOrder;
      });
    }
  };

  const willPublished = (newsItem) => {
    return (
      <div>
        {newsItem?.is_published ? (
          <>
            <hr />
            <span className="bold-sm-text">
              {`${intl.formatMessage({ id: 'ui-general-published-at' })}: ${dayjs(newsItem?.published_at).format(DATE_TIME_FORMAT)}`}
            </span>
          </>
        ) : (
          <Tag className='hp-mt-4 tags-padding-sm'>
            <IntlMessages id="ui-general-not-published" />
          </Tag>
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          {!news.length ? (
            <EmptyDataPlaceholder placeholder={<IntlMessages id="news-priority-empty" />} />
          ) : (
            <DndContext
              modifiers={[ restrictToParentElement ]}
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                <div className="priority-container">
                  {items.map((item) => {
                    const position = Number(item) - 1;
                    const newsItem = news[position];

                    return (
                      <SortableItem key={item} id={item}>
                        <div className={`hp-d-flex hp-d-flex-between priority-item ${!newsItem?.is_published ? 'priority-item-off' : ''}`}>
                          <div className="hp-d-flex hp-align-items-center">
                            <LazyLoadImage
                              className='priority-item__image'
                              effect='blur'
                              alt="picture preview"
                              placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
                              src={newsItem?.preview ? `${newsItem?.preview}?time=${Date.now()}` : EMPTY_IMAGE_PLACEHOLDER}
                            />
                            <div className="priority-content priority-item__title">
                              <Tooltip
                                placement="topLeft"
                                title={`${newsItem?.title ?? EMPTY_VALUE_PLACEHOLDER} (ID: ${newsItem?.id})`}
                              >
                                {`${newsItem?.title ?? EMPTY_VALUE_PLACEHOLDER} (ID: ${newsItem?.id})`}
                              </Tooltip>
                              <div className="hp-pt-4">
                                {willPublished(newsItem)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </SortableItem>
                    );
                  })}
                </div>
              </SortableContext>
            </DndContext>
          )}
        </div>
      )}
    </div>
  );
};

DynamicNewsPriority.propTypes = {
  news: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default DynamicNewsPriority;
