import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Card, Col, Modal, Row, Spin, Tabs } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Filter } from 'iconsax-react';
import { RiCloseFill } from 'react-icons/ri';
import InfiniteScroll from 'react-infinite-scroll-component';
import urlPageFeeds from '../../../../../urls/urlPageFeeds';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import { Permissions } from '../../../../../const/permissions';
import { hasRights, PrivateComponent } from '../../../../../components/HasRights/HasRights';
import { FEEDS_SOURCE_MAP, getFeedsSourceTranslation, MODAL_MODE, searchFilterFields } from '../PageFeeds.const';
import getSourceFromUrl from '../../../../../lib/getSourceFromUrl';
import useInfiniteScroll from '../../../../../app/hooks/useInfiniteScroll';
import useToggle from '../../../../../app/hooks/useToggle';
import resetFilter from '../../../../../lib/resetFilter';
import applyFilter from '../../../../../lib/applyFilter';
import getFeedsNotificationLink from '../../../utils/getFeedsNotificationLink';
import getModalWidthByModalMode from '../../../../../lib/getModalWidthByModalMode';
import getModalTitleByModalMode from '../../../../../lib/getModalTitleByModalMode';
import DocumentationTooltip from '../../../../../components/DocumentationTooltip/DocumentationTooltip';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Spinner from '../../../../../components/Spinner';
import Sidebar from '../../../../../components/Sidebar';
import ActionButton from '../../../../../layout/components/action-button';
import ContentCard from '../../../components/ContentCard';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import ImageModalCardTags from '../../../components/ImageModalCard/ImageModalCardTags';
import FormFeedsFilter from '../../../forms/FormFeedsFilter';
import ImageModalCard from '../../../components/ImageModalCard';
import TotalLabel from '../../../../../components/TotalLabel';
import FeedsPlanningBoardCalendar from '../../../components/FeedsPlanningBoardCalendar';
import { useGetFeedsQuery } from '../../../api/feedsApiSlice';



const { TabPane } = Tabs;

const PageFeedsPlanning = () => {
  const { pathname } = useLocation();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ queryParams, setQuery ] = useState('');
  const [ modalMode, setModalMode ] = useState(MODAL_MODE.VIEW);
  const [ previewImage, setPreviewImage ] = useState({});
  const [ activeModalTab, setActiveModalTab ] = useState('general-info');
  const [ showContour, toggleShowContour ] = useToggle();
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ isUpdateList, setIsUpdateList ] = useState(false);
  const [ updatedListItem, setUpdatedListItem ] = useState({});

  const feedSource = getSourceFromUrl(pathname);

  const { data: { data, pagination } = { data: [] },
    isFetching,
    error: formErrors = {},
  } = useGetFeedsQuery({ feedSource, queryParams });

  const isLoading = isFetching;

  const {
    itemsArray,
    groupedItemsList,
    itemsLength,
    hasMore,
    handleLoadNextData,
    initFilterValues,
    setInitFilterValues,
    combinedQueryParams,
    setResetManuallyTriggered,
  } = useInfiniteScroll({
    data,
    pagination,
    searchFilterFields,
    source: feedSource,
    isUpdateList,
    updatedListItem,
  });

  useEffect(() => {
    if (!isEmpty(combinedQueryParams)) {
      if (combinedQueryParams) {
        const cleanedCombinedQueryParams = combinedQueryParams.split('&').filter((item) => {
          const [ key ] = item.split('=');

          if (key !== 'planningSearch') {
            return item;
          }

          return '';
        }).join('&');

        setQuery(cleanedCombinedQueryParams);
      }

      if (combinedQueryParams === '') {
        setQuery('');
      }
    }
  }, [ combinedQueryParams ]);

  const handleUpdateListItem = (id, values = {}) => {
    setIsUpdateList(true);
    setUpdatedListItem({ id, values });
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    setModalMode(MODAL_MODE.VIEW);
    togglePreviewModal();
    setActiveModalTab('general-info');
  };

  const handlePreviewModalClose = () => {
    togglePreviewModal();
  };

  const handleChangeModalMode = (mode) => {
    setModalMode(mode);
  };

  return (
    <>
      <Modal
        className='feed-modal'
        width={getModalWidthByModalMode(modalMode)}
        title={getModalTitleByModalMode(modalMode)}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        <Tabs
          type="card"
          activeKey={activeModalTab}
          onChange={(key) => setActiveModalTab(key)}
        >
          <TabPane tab={<IntlMessages id='ui-general-info' />} key="general-info">
            <ImageModalCard
              data={previewImage}
              modalMode={modalMode}
              handleModalClose={handlePreviewModalClose}
              handleUpdateListItem={(imageId, values = {}) => handleUpdateListItem(imageId, values)}
              handleModalMode={(mode) => handleChangeModalMode(mode)}
              initialEditImageErrors={{}}
            />
          </TabPane>

          {hasRights([
            Permissions.CONTENT.IMAGES.TAGS.LIST,
            Permissions.CONTENT.IMAGES.TAGS.UPDATE,
          ]) && (
            <TabPane
              key="tags-info"
              tab={<IntlMessages id='ui-general-tags' />}
            >
              {activeModalTab === 'tags-info' && (
                <ImageModalCardTags previewData={previewImage} />
              )}
            </TabPane>
          )}
        </Tabs>
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.LIST })}>
              <IntlMessages id='sidebar-content-feeds' />
            </Link>
          }
          breadCrumbActive={
            <div>
              <IntlMessages id={getFeedsSourceTranslation(feedSource)} />
              <DocumentationTooltip link={getFeedsNotificationLink(feedSource)} />
            </div>
          }
        />
      </Row>

      <Row gutter={[ 32, 32 ]}>
        <Sidebar
          visible={isFilterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={600}
        >
          <FormFeedsFilter
            isSubmitting={isFetching}
            initialValues={initFilterValues}
            formErrors={formErrors}
            feedSource={feedSource}
            onCancel={() => {
              setResetManuallyTriggered(true);

              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>

        <PrivateComponent allowedPermissions={[ Permissions.CONTENT.FEEDS.PLANNING.STORAGE ]}>
          <Col
            xs={24}
            md={hasRights([ Permissions.CONTENT.FEEDS.PLANNING.BOARD.LIST ]) ? 10 : 24}
          >
            <Spinner spinning={isLoading} tip=''>
              <Card
                width="100%"
                title={(
                  <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                    <IntlMessages id="ui-general-storage-images" />
                    <ActionButton
                      title=""
                      size='small'
                      isActive={!isEmpty(initFilterValues)}
                      className='hp-mr-sm-8'
                      icon={<Filter size={18} />}
                      onClick={toggleFilterSidebar}
                    />
                  </div>
                )}
              >
                <Row
                  justify='space-between'
                  className='hp-pb-18 center'
                  style={{ marginTop: '-6px', alignItems: 'center' }}
                >
                  <TotalLabel total={isEmpty(formErrors) ? pagination?.total : 0} />
                  <ActionButton
                    size="small"
                    className='hp-mr-0'
                    onClick={toggleShowContour}
                    title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
                  />
                </Row>

                <div id="scrollableDiv" style={{ maxHeight: window.innerHeight - 355, overflow: 'auto' }}>
                  {!isEmpty(groupedItemsList) ? (
                    <InfiniteScroll
                      dataLength={itemsLength}
                      next={handleLoadNextData}
                      scrollableTarget="scrollableDiv"
                      scrollThreshold='100px'
                      hasMore={hasMore}
                      loader={isFetching && (
                        <div className='centered-item'><Spin /></div>
                      )}
                    >
                      <div className='content-card-grid content-card-grid--small hp-mb-32'>
                        {itemsArray.map((item) => (
                          <ContentCard
                            key={item.id}
                            data={item}
                            showContour={showContour}
                            onClick={handleCardClick}
                          />
                        ))}
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <div className='hp-mt-64'>
                      <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />
                    </div>
                  )}
                </div>
              </Card>
            </Spinner>
          </Col>
        </PrivateComponent>

        <PrivateComponent allowedPermissions={[ Permissions.CONTENT.FEEDS.PLANNING.BOARD.LIST ]}>
          <Col
            xs={24}
            md={hasRights([ Permissions.CONTENT.FEEDS.PLANNING.STORAGE ]) ? 14 : 24}
          >
            <FeedsPlanningBoardCalendar />
          </Col>
        </PrivateComponent>
      </Row>
    </>
  );
};

export default PageFeedsPlanning;
