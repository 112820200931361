const getCategoriesOptions = (categories = []) => {
  return categories.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
};

export default getCategoriesOptions;
