const static_content_reset = {
  'static-content-reset-logs-breadcrumbs': 'Сброс статического контента',
  'static-content-reset-logs-table-type-col': 'Тип сброса',
  'static-content-reset-logs-table-type-reset-daily': 'Сброс статики ежедн.',
  'static-content-reset-logs-table-type-reset-feed': 'Сброс статики ленты',
  'static-content-reset-logs-btn-reset-daily': 'Сброс ежедн. на CDN',
  'static-content-reset-logs-btn-reset-feed': 'Сброс ленты на CDN',
  'static-content-reset-logs-btn-reset-feed-confirm': 'Сбросить статические данные ленты?',
  'static-content-reset-logs-btn-reset-daily-confirm': 'Сбросить статические данные ежедневного контента?',
  'static-content-reset-successfully': 'Статические данные успешно сброшены',
  'static-content-reset-failed': 'Произошла ошибка при сбросе статических данных',
};

export default static_content_reset;
