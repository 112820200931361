import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from '../../layout/components/lang/IntlMessages';
import appColors from '../../const/colors';



const SelectedLabel = ({ total = 0 }) => {
  return (
    <span className="hp-badge-text hp-font-weight-600 hp-mb-0 hp-text-color-dark-30">
      <span style={{ color: appColors.darkGray }}>
        <IntlMessages id='ui-general-selected' />
        {': '}
      </span>
      <span>{total}</span>
    </span>
  );
};

SelectedLabel.propTypes = {
  total: PropTypes.number,
};

export default SelectedLabel;
