const images = {
  'images-breadcrumbs': 'Изображения',
  'images-reserve-statistics-breadcrumbs': ' Статистика резерва',
  'images-reserve-statistics-reference-value': 'Исходное значение',
  'images-reserve-statistics-reserve-value': 'мec. резерв',
  'images-reserve-statistics-configure-table': 'Настроить таблицу',
  'images-reserve-statistics-view-reference': 'Исходные значения',
  'images-reserve-statistics-add-rows': 'Добавить строки',
  'images-reserve-statistics-add-rows-disabled': 'Все существующие категории уже добавлены',
  'images-reserve-statistics-save-table-same-structure-disabled': 'Для сохранения данных необходимо внести изменения',
  'images-reserve-statistics-save-table-empty-structure-disabled': 'Для сохранения данных необходимо наличие строк и столбцов, пожалуйста внесите изменения',
  'images-reserve-statistics-add-edit-column-empty-message': 'Пожалуйста, заполните хотя бы одно поле',
  'images-reserve-statistics-add-col': 'Добавить колонку',
  'images-reserve-statistics-edit-col': 'Редактировать колонку',
  'images-reserve-statistics-remove-row': 'Удалить строку',
  'images-reserve-statistics-remove-col': 'Удалить колонку',
  'images-reserve-statistics-reference-notification': 'Эталонные значения резерва на 1 месяц',
  'form-add-rows-notification': 'В списке для добавления будут показаны только те категории, которые еще не добавлены',
  'form-add-rows-success-notification': 'Строки успешно добавлены',
  'form-add-column-success-notification': 'Колонка успешно добавлена',
  'form-edit-column-success-notification': 'Данные колонки успешно обновлены',
  'form-remove-rows-success-notification': 'Строка успешно удалена',
  'form-remove-columns-success-notification': 'Колонка успешно удалена',
  'images-reserve-statistics-remove-row-confirmation': 'Вы уверены, что хотите удалить из статистики строку',
  'images-reserve-statistics-remove-column-confirmation': 'Вы уверены, что хотите удалить из статистики колонку',
  'images-tags-applied-templates-label': 'Смотреть примененные шаблоны:',
  'images-tags-edit': 'Редактировать теги',
  'tags-no-tags-applied-warning-message': 'У этого изображения пока нет тегов!',
};

export default images;
