import dayjs from 'dayjs';
import { DATE_FORMAT } from '../../../../../const/system';
import appColors from '../../../../../const/colors';



export const getCardBackground = (imagesCount, cellDate) => {
  const minImagesCount = 15;
  const currentDate = dayjs(new Date()).format(DATE_FORMAT);
  const daysDifference = dayjs(cellDate).diff(currentDate, 'days');

  if (imagesCount >= minImagesCount) {
    return 'feed-board-cell-success';
  }
  if (daysDifference > 13) {
    return 'feed-board-cell-warning';
  }
  return 'feed-board-cell-danger';
};

export const getMedianOrAvgColor  = (fieldValue) => {
  return { color: fieldValue < 600 || fieldValue > 800 ? appColors.error : '' };
};
