const getDictionariesOptions = (dictionaries) => {
  const unusedDictionaries = dictionaries.filter((dict) => !dict.is_used).sort((dictA, dictB) => dictB.id - dictA.id);
  const usedDictionaries = dictionaries.filter((dict) => dict.is_used).sort((dictA, dictB) => dictB.id - dictA.id);

  let dictionariesData = [ ...unusedDictionaries, ...usedDictionaries ];

  dictionariesData = dictionariesData.map((item) => {
    return {
      label: item.name,
      value: Number(item.id),
      disabled: item.is_used,
    };
  });

  return dictionariesData;
};

export default getDictionariesOptions;
