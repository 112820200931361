import React from 'react';
import PropTypes from 'prop-types';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Row } from 'antd';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';



const ImageBadge = ({ image, hasValidationError = false }) => {
  const { is_copy, is_reopened }  = image;
  const isBadgeVisible = is_copy || is_reopened;

  const imageStatus = () => {
    if (is_copy) {
      return <IntlMessages id='ui-general-img-copy' />;
    }
    if (is_reopened) {
      return <IntlMessages id='ui-general-img-reopened' />;
    }
    return '';
  };

  return (
    <div>
      {hasValidationError && (
        <div className="card-top-cover-badge-error">
          <Row gutter={8} align="middle" justify='center'>
            <div className="card-top-cover-badge-error--icon">
              <RiErrorWarningFill size={18} />
            </div>
            <IntlMessages id='feeds-invalid-card-alert' />
          </Row>
        </div>
      )}
      {isBadgeVisible ? (
        <div className={`card-top-cover-badge ${hasValidationError ? 'card-top-cover-badge--squared' : ''}`}>
          {imageStatus()}
        </div>
      ) : null}
    </div>
  );
};

ImageBadge.propTypes = {
  image: PropTypes.shape({
    is_copy: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    is_reopened: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
    status: PropTypes.string,
  }),
  hasValidationError: PropTypes.bool,
};

export default ImageBadge;
