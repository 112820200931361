import React, { useEffect, useState } from 'react';
import { Col, Collapse, DatePicker, Divider, Row, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form, FormItem, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { fieldTypeToValidationRule } from '../FormCreateDynamicNews/FormCreateDynamicNews.const';
import { DEFAULT_RELEASE_TIME } from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import getConfigurations from '../../../../lib/getConfigurations';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import mkMainAndOptionalTemplateFields from '../../../Template builder/utils/mkMainAndOptionalTemplateFields';
import isFieldRequired from '../../utils/isFieldRequired';
import modifyDefaultValue from '../../../Template builder/utils/modifyDefaultValue';
import mkTemplateDynamicFormData from '../../utils/mkTemplateDynamicFormData';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import getTemplateOptions from '../../../../lib/getTemplateOptions';
import applyPreset from '../../../../lib/applyPreset';
import getCategoriesOptions from '../../../../lib/getCategoriesOptions';
import getImageTypeOptions from '../../../../lib/getImageTypeOptions';
import Spinner from '../../../../components/Spinner';
import TitleWithAutomaticGeneration from '../../../../components/TitleWithAutomaticGeneration';
import PresetPreview from '../../../../components/PresetPreview';
import DynamicFormDataFields from '../../components/DynamicFormDataFields';
import TargetingSettings from '../../../../components/TargetingSettings';
import { makeValidationSchema } from './FormCreateCollection.const';
import { TIME_FORMAT } from '../../../../const/system';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../Template builder/const/templates';
import { COLLECTION_TEMPLATE_CONTENT_TYPE, COLLECTIONS_SOURCE } from '../../pages/Collections/PageCollections/PageCollections.const';
import { useGetCategoriesQuery } from '../../api/categoriesApiSlice';
import { useGetTemplatesQuery } from '../../../Template builder/api/templatesApiSlice';
import { useGetPresetsQuery } from '../../../Template builder/api/presetsApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

let validation = {};

const FormCreateCollection = ({
  initialErrors,
  onSubmit,
  isSubmitting,
}) => {
  const intl = useIntl();
  const collectionConfig = getConfigurations(COLLECTIONS_SOURCE);
  const language = useSelector(({ customise: { language } }) => language);
  const { onBlur } = useDatepickerHandler();
  const [ currentTemplate, setCurrentTemplate ] = useState(null);

  const defaultConfigUtcValue = collectionConfig?.publish?.enabled_utc0_field ?? false;
  const isDisabledUtcField = !collectionConfig?.publish?.show_utc0_field;
  const useDatePickerTime = collectionConfig?.publish?.use_time ?? false;
  const hasReleaseRange = collectionConfig?.release_range ?? false;
  const useAutomaticGeneration = collectionConfig?.title?.use_automatic_generation ?? true;
  const imageTypes = collectionConfig?.image_types ?? [];

  const { data: templates = { data: [] }, isFetching: isTemplatesFetching } = useGetTemplatesQuery({
    queryParams: `limit=0&search=status:1;content_type:${COLLECTION_TEMPLATE_CONTENT_TYPE}`,
  });

  const { data: categories = { data: [] }, isLoading: isCategoriesLoading } = useGetCategoriesQuery({
    queryParams: 'limit=0',
  });
  const categoriesOptions = getCategoriesOptions(categories.data);
  const imageTypeOptions = getImageTypeOptions(imageTypes);

  const {
    data: { data: presets } = { data: [] },
    isFetching: isPresetsFetching,
  } = useGetPresetsQuery({
    queryParams: `search=template_id:${currentTemplate}`,
  }, { skip: !currentTemplate });

  const template = templates?.data?.find((template) => Number(template.id) === Number(currentTemplate));
  const modifiedErrors = useModifiedDynamicDataErrors(currentTemplate, template, initialErrors, language);
  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(template?.fields?.data);

  useEffect(() => {
    validation = makeValidationSchema(collectionConfig, {});
  }, [ isPresetsFetching ]);

  const applyTemplate = async (value, setFieldValue) => {
    setCurrentTemplate(value);
    await setFieldValue('preset_id', null);
    await setFieldValue('template_id', value);

    if (!value) {
      setFieldValue('data', {});
      return;
    }

    const data = {};
    const dataSchema = {};
    const template = templates?.data?.find((template) => template.id === value);

    template?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach((field) => {
        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[field.full_name] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        data[field.full_name] = {
          value: field.type === TYPE_IMAGE ? [] : modifyDefaultValue(field),
          template_field_id: field.id,
        };
      });

    setFieldValue('data', data);
    setFieldValue('target', {
      min_version: template?.target?.data?.min_version,
      max_version: template?.target?.data?.max_version,
      platform: template?.target?.data?.platform,
      countries: template?.target?.data?.countries,
    });

    validation = makeValidationSchema(collectionConfig, {
      data: Yup.object().shape(dataSchema),
    });
  };

  const initialValues = {
    title: null,
    categories: [],
    image_type: null,
    use_utc_zero_time: defaultConfigUtcValue,
    released_at: null,
    ...hasReleaseRange && {
      released_to: null,
    },
    template_id: null,
    preset_id: null,
    target: {
      min_version: null,
      max_version: null,
      platform: [],
      countries: [],
    },
    data: {},
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialErrors={modifiedErrors}
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(mkTemplateDynamicFormData(values, language, template?.fields?.data), resetForm);
      }}
    >
      {({ isValid, values, setFieldValue, setFieldTouched, resetForm }) => {
        const preset = presets?.find((preset) => Number(preset.id) === Number(values?.preset_id));

        return (
          <Form layout="vertical" >
            <Spinner spinning={isSubmitting}>
              <Title className="hp-mb-24" level={4}>{intl.formatMessage({ id: 'collection-details-title' })}</Title>

              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          loading={isTemplatesFetching}
                          allowClear
                          showSearch
                          name="template_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                          options={getTemplateOptions(templates.data)}
                          onChange={async (value) => {
                            await applyTemplate(value, setFieldValue);
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preset' })}
                        extra={intl.formatMessage({ id: 'presets-template-hint' })}
                        name='preset_id'
                      >
                        <Select
                          loading={isPresetsFetching}
                          allowClear
                          showSearch
                          disabled={!currentTemplate}
                          name="preset_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                          options={getTemplateOptions(presets)}
                          onChange={async (value) => {
                            await applyPreset(value, values, setFieldValue, presets, template?.fields?.data ?? []);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'collection-form-categories-title' })}
                        name='categories'
                        required
                      >
                        <Select
                          loading={isCategoriesLoading}
                          mode="multiple"
                          allowClear
                          showSearch
                          name="categories"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-categories' })}
                          options={categoriesOptions}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'collection-form-image-type-title' })}
                        name='image_type'
                        required
                      >
                        <Select
                          allowClear
                          name="image_type"
                          options={imageTypeOptions}
                          placeholder={intl.formatMessage({ id: 'collection-form-specify-image-type' })}
                        />
                      </FormItem>
                    </Col>

                    {hasReleaseRange ? (
                      <>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-at' })}
                            name='released_at'
                            required
                          >
                            <DatePicker
                              name='released_at'
                              showToday
                              showTime={useDatePickerTime ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) } : false}
                              disabledDate={(date) => (!isEmpty(values?.released_to) && date.isAfter(moment(values?.released_to)))
                                || moment(date).subtract('-2', 'days').isSameOrBefore(moment(new Date()))}
                              value={values?.released_at ? moment(values.released_at) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_at', dateString);
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_at', true);
                                await onBlur('released_at', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-to' })}
                            name='released_to'
                          >
                            <DatePicker
                              name='released_to'
                              showTime={useDatePickerTime ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) } : false}
                              disabledDate={(date) => !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at))}
                              value={values?.released_to ? moment(values.released_to) : null}
                              onChange={async (date, dateString) => {
                                await setFieldValue('released_to', dateString);
                              }}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_to', true);
                                await onBlur('released_to', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <Col span={24}>
                        <FormItem
                          label={intl.formatMessage({ id: 'ui-general-released-at' })}
                          name='released_at'
                          required
                        >
                          <DatePicker
                            name='released_at'
                            showToday
                            showTime={useDatePickerTime ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT) } : false}
                            disabledDate={(date) => moment(date).subtract('-2', 'days').isSameOrBefore(moment(new Date()))}
                            value={values?.released_at ? moment(values.released_at) : null}
                            onChange={async (date, dateString) => {
                              await setFieldValue('released_at', dateString);
                            }}
                            onBlur={async ({ target }) => {
                              await setFieldTouched('released_at', true);
                              await onBlur('released_at', target.value, setFieldValue);
                            }}
                          />
                        </FormItem>
                      </Col>
                    )}

                    <Col span={24}>
                      <Collapse accordion className='hp-mb-24'>
                        <Panel
                          key="additional-info"
                          header={
                            <Title className='collapse-item-title' level={5}>
                              {intl.formatMessage({ id: 'ui-general-optional-data' })}
                            </Title>
                          }
                        >
                          <Row gutter={16}>
                            <Col span={24}>
                              <TitleWithAutomaticGeneration
                                rows={2}
                                useAutomaticGeneration={useAutomaticGeneration}
                              />
                            </Col>

                            <Col span={24}>
                              <FormItem
                                name='use_utc_zero_time'
                                label={
                                  <Row className='w-full' justify="space-between" align="middle">
                                    <IntlMessages id="features-use-utc" />
                                    <Tooltip
                                      placement="top"
                                      title={
                                        <>
                                          <IntlMessages id="features-use-utc-info" />
                                          <div>
                                            <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                              <u><IntlMessages id="ui-general-documentation-link" /></u>
                                            </Link>
                                          </div>
                                        </>
                                      }
                                    >
                                      <InfoCircleOutlined className='hp-ml-8' />
                                    </Tooltip>
                                  </Row>
                                }
                              >
                                <Switch
                                  name="use_utc_zero_time"
                                  defaultChecked={defaultConfigUtcValue}
                                  disabled={isDisabledUtcField}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xs={24}>
                  <PresetPreview src={preset?.preview} />
                </Col>
              </Row>

              {values?.template_id && (
                <>
                  <Divider />
                  <Title className="hp-mb-24" level={4}>
                    <IntlMessages id="ui-general-template-data" />
                  </Title>
                  <Row gutter={[ 16, 16 ]} className="hp-mb-24">
                    <Col span={24}>
                      <DynamicFormDataFields
                        setFieldValue={setFieldValue}
                        fields={mainFields}
                        values={values}
                        span={24}
                      />
                      {!!optionalFields.length && (
                        <Collapse>
                          <Panel
                            key="1"
                            header={
                              <Title level={5} className='collapse-item-title'>
                                <IntlMessages id="ui-general-additional-parameters" />
                              </Title>
                            }
                          >
                            <DynamicFormDataFields
                              setFieldValue={setFieldValue}
                              fields={optionalFields}
                              values={values}
                              span={24}
                            />
                          </Panel>
                        </Collapse>
                      )}
                    </Col>
                  </Row>

                  <Divider />

                  <TargetingSettings setFieldValue={setFieldValue} values={values} />
                </>
              )}

              <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => {
                      setCurrentTemplate(null);
                      resetForm(initialValues);
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateCollection.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormCreateCollection;
