import url from './url';
import makeClearableUrlPrefix from '../lib/makeClearableUrlPrefix';



export default url(({ urlPrefix = null, id = ':id', source = null, multipleSources = null }) => {
  const prefix = makeClearableUrlPrefix(urlPrefix);

  if (source && multipleSources) {
    return `${prefix}/dictionaries/${id}/translations?source=${source}&multipleSources=${multipleSources}`;
  }

  return source
    ? `${prefix}/dictionaries/${id}/translations?source=${source}`
    : `${prefix}/dictionaries/${id}/translations`;
});
