import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import { switchLanguage } from '../../../app/features/customise/customiseSlice';
import enLang from '../../../assets/images/languages/en.svg';
import ruLang from '../../../assets/images/languages/ru.svg';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';



const HeaderLanguages = () => {
  const dispatch = useDispatch();
  const customise = useSelector((state) => state.customise);
  const [ activeLanguage, setActiveLanguage ] = useState(customise.language);


  useEffect(() => {
    setActiveLanguage(customise.language);
  }, [ customise ]);


  const handleLanguageSwitch = (lang) => {
    if (lang !== activeLanguage) {
      dayjs.locale(lang);
      dispatch(switchLanguage(lang));
    }
  };

  return (
    <Radio.Group
      value={activeLanguage}
      onChange={(event) => handleLanguageSwitch(event.target.value)}
    >
      <Radio.Button value="ru" size='small' className='hp-language-radio'>
        <img src={ruLang} alt="Russian" className="hp-language-flag" />
        Ru
      </Radio.Button>
      <Radio.Button value="en" size='small' className='hp-language-radio'>
        <img src={enLang} alt="English" className="hp-language-flag" />
        En
      </Radio.Button>
    </Radio.Group>
  );
};

export default HeaderLanguages;
