import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Alert, Col, Descriptions, Radio, Row, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { IoWarning } from 'react-icons/io5';
import isEmpty from 'lodash/isEmpty';
import { hasRights } from '../../../../../components/HasRights/HasRights';
import { Permissions } from '../../../../../const/permissions';
import { EMPTY_IMAGE_PLACEHOLDER, EMPTY_VALUE_PLACEHOLDER } from '../../../../../const/system';
import appColors from '../../../../../const/colors';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import Spinner from '../../../../../components/Spinner';
import ActionButton from '../../../../../layout/components/action-button';
import { getBeePanelEnvUrl } from '../../../../../lib/getBeePanelEnvUrl';
import { useGetImagesTagsQuery } from '../../../api/imagesApiSlice';



const ImageModalCardTags = ({ previewData = {}, hasSimilarImages = false }) => {
  const [ templateValue, setTemplateValue ] = useState(null);

  const { data: {
    data: imageTagsData = [],
  } = { data: [], pagination: {} }, isFetching: isLoadingImageTags } = useGetImagesTagsQuery({
    queryParams: `image_ids=${previewData.id}`,
  });

  const imageTags = imageTagsData[0] ?? {};
  const selectedTemplate = !isEmpty(imageTags) ? imageTags?.templates.filter((template) => template.id === templateValue)[0] : {};

  useEffect(() => {
    if (!isEmpty(imageTags) && imageTags?.templates.length > 0) {
      setTemplateValue(imageTags.templates[0].id);
    }
  }, [ imageTags ]);

  const onTemplateChange = (event) => {
    setTemplateValue(event.target.value);
  };

  return (
    <Spinner spinning={isLoadingImageTags}>
      <Row justify="space-between">
        <Col span={hasSimilarImages ? 8 : 6}>
          {hasRights([ Permissions.CONTENT.IMAGES.TAGS.LIST ]) && (
            <>
              <div className='image-preview-card'>
                <LazyLoadImage
                  effect='blur'
                  className='image-preview-card--image'
                  alt="picture preview"
                  placeholderSrc={EMPTY_IMAGE_PLACEHOLDER}
                  key={previewData?.id}
                  src={previewData?.url ? `${previewData?.url}?time=${Date.now()}` : EMPTY_IMAGE_PLACEHOLDER}
                />
                <Row justify='space-between' className='image-preview-card--image--badge'>
                  <div>{`ID: ${previewData?.id ?? EMPTY_VALUE_PLACEHOLDER}`}</div>
                  <div>{`Bee: ${previewData?.bee_id ?? EMPTY_VALUE_PLACEHOLDER}`}</div>
                </Row>
              </div>

              {!isEmpty(imageTags) && imageTags?.templates.length > 0 && (
                <Col className='hp-mt-16 hp-ml-8'>
                  <div className='bold-md-text'>
                    <IntlMessages id='images-tags-applied-templates-label' />
                  </div>

                  <Radio.Group
                    className='hp-mt-8'
                    onChange={onTemplateChange}
                    value={templateValue}
                  >
                    <Space direction="vertical" className='image-preview-card--templates-container'>
                      {imageTags?.templates?.map((template) => (
                        <Radio key={template.id} value={template.id}>{template.label}</Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Col>
              )}
            </>
          )}
        </Col>

        <Col
          xxs={hasSimilarImages ? 4 : 10}
          xs={hasSimilarImages ? 8 : 12}
          sm={hasSimilarImages ? 10 : 14}
          md={hasSimilarImages ? 12 : 16}
          lg={hasSimilarImages ? 14 : 18}
          xl={hasSimilarImages ? 16 : 18}
          className='hp-pr-16'
        >
          <Space direction="vertical" className='hp-pr-16 image-preview-card--tags-container'>
            <Descriptions layout="vertical" column={1} className="hp-mb-16">
              {!isEmpty(selectedTemplate) ? selectedTemplate?.groups.map((group) => (
                <Descriptions.Item
                  className='description-block'
                  key={group.id}
                  label={
                    <span>
                      {`${group.label}:`}
                      {group.is_required && <span style={{ color: appColors.error }}>*</span>}
                    </span>
                  }
                >
                  {group?.tags?.length ? group?.tags.map((tag) => (
                    <Tag key={tag.id} className='hp-mb-8'>{tag.label}</Tag>
                  )) : (
                    <span className='hp-d-flex'>
                      <IntlMessages id='ui-general-not-selected' />
                      {group.is_required && (
                        <span className='hp-ml-16'>
                          <IoWarning fill={appColors.error} size={20} />
                        </span>
                      )}
                    </span>
                  )}
                </Descriptions.Item>
              )) : (
                <div>
                  <Alert
                    className='hp-mt-8 w-full'
                    message="Warning"
                    description={<IntlMessages id='tags-no-tags-applied-warning-message' />}
                    type="warning"
                    showIcon
                  />
                </div>
              )}
            </Descriptions>
          </Space>

          {hasRights([ Permissions.CONTENT.IMAGES.TAGS.UPDATE ]) && (
            <Row justify='end' className='hp-pr-16' style={{ marginTop: !isEmpty(selectedTemplate) ? '15px' : '' }}>
              <Link to={`${getBeePanelEnvUrl()}/tag-system/pictures/${imageTags?.bee_id}`} target="_blank">
                <ActionButton
                  className=''
                  onClick={() => {}}
                  loading={isLoadingImageTags}
                  title={<IntlMessages id='images-tags-edit' />}
                />
              </Link>
            </Row>
          )}
        </Col>
      </Row>
    </Spinner>
  );
};

ImageModalCardTags.propTypes = {
  hasSimilarImages: PropTypes.bool,
  previewData: PropTypes.object.isRequired,
};

export default ImageModalCardTags;
