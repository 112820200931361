import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { Col, DatePicker, Row, Upload } from 'antd';
import { Form, FormItem, Input, Select, ResetButton, SubmitButton } from 'formik-antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RiUploadCloud2Line } from 'react-icons/ri';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import Yup from '../../../../vendor/yup';
import { STRUCTURE_SOURCE_NEWS, typeOptions } from './FormCreateNews.const';
import useDebounce from '../../../../app/hooks/useDebounce';
import { dateFieldFormat } from '../../../../const/system';
import { useGetDictionariesQuery } from '../../../Translations/api/dictionariesApiSlice';
import getDictionariesOptions from '../../../../lib/getDictionariesOptions';
import prepareFormData from '../../../../lib/prepareFormData';
import disableDateBeforeYesterday from '../../../../lib/disableDateBeforeYesterday';
import useDatepickerHandler from '../../../../app/hooks/useDatepickerHandler';
import { useGetAllVersionsStructuresQuery } from '../../../Translations/api/structuresApiSlice';



const validationSchema = Yup.object().shape({
  target: Yup.string(),
  dictionary: Yup.number().min(1),
  published_at: Yup.date(),
  published_to: Yup.date(),
  type: Yup.string().required(),
  icons: Yup
    .array()
    .test(
      'iconsAmount',
      '2 icons required',
      (value) => {
        return value?.length === 0 || value?.length === 2;
      },
    ),
});

const FormCreateNews = ({
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const intl = useIntl();
  const { onBlur } = useDatepickerHandler();
  const [ search, setSearch ] = useState('');
  const searchQuery = useDebounce(search.trim(), 300);

  const { data: structures, isLoading: isStructuresLoading } = useGetAllVersionsStructuresQuery({
    queryParams: `search=source:${STRUCTURE_SOURCE_NEWS}`,
  });

  let queryParams = `limit=0&search=status:ready;structure_id:${structures?.data?.map((structure) => structure.id)}`;

  if (searchQuery) {
    queryParams += `name:${searchQuery}`;
  }

  const { data: { data: dictionaries = [] } = { data: [] },
    isFetching: isDictionaryFetching,
  } = useGetDictionariesQuery({ queryParams }, { skip: isStructuresLoading });

  const onSelect = (event, field, setFieldValue) => {
    setFieldValue(field, event);
  };

  const onSearch = useCallback((searchText) => {
    setSearch(searchText);
  }, [ search ]);

  return (
    <Formik
      enableReinitialize
      isSubmitting
      initialValues={{
        status: false,
        icons: [],
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(prepareFormData(values), resetForm);
      }}
      onReset={async (values) => {
        onCancel(values);
      }}
    >
      {({ isValid, dirty, values, setFieldValue }) => {
        return (
          <Form layout="vertical" >
            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-type' })}
                  name='type'
                  required
                >
                  <Select
                    allowClear
                    name="type"
                    options={typeOptions}
                    placeholder={intl.formatMessage({ id: 'ui-general-choose-type' })}
                  />
                </FormItem>
              </Col>
            </Row>

            <FormItem
              label={intl.formatMessage({ id: 'news-create-form-target-label' })}
              name='target'
            >
              <Input
                name="target"
                placeholder={intl.formatMessage({ id: 'news-create-form-target-placeholder' })}
              />
            </FormItem>

            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-dictionary' })}
                  name='dictionary'
                >
                  <Select
                    allowClear
                    showSearch
                    loading={isDictionaryFetching}
                    value={values?.dictionary ?? null}
                    options={getDictionariesOptions(dictionaries)}
                    onSelect={(event) => onSelect(event, 'dictionary', setFieldValue)}
                    onSearch={onSearch}
                    filterOption={false}
                    onChange={(event) => onSelect(event, 'dictionary', setFieldValue)}
                    placeholder={intl.formatMessage({ id: 'news-create-form-dictionary-placeholder' })}
                    name='dictionary'
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <FormItem
                  label={intl.formatMessage({ id: 'news-create-form-icons-label' })}
                  name='icons'
                >
                  <Upload.Dragger
                    name="icons"
                    multiple
                    beforeUpload={() => false}
                    listType="picture"
                    fileList={values.icons}
                    onChange={({ fileList }) => {
                      if (fileList.length > 2) {
                        fileList = fileList.slice(-2);
                      }
                      setFieldValue('icons', fileList);
                    }}
                    defaultFileList={[ ...values.icons ]}
                  >
                    <p className="ant-upload-drag-icon hp-mb-0">
                      <RiUploadCloud2Line size={24} className="remix-icon" />
                    </p>

                    <p className="ant-upload-text">
                      <IntlMessages id="news-create-form-upload-description-label" />
                    </p>
                  </Upload.Dragger>
                </FormItem>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published-at' })}
                  name='published_at'
                >
                  <DatePicker
                    showToday
                    name='published_at'
                    format={dateFieldFormat}
                    disabledDate={disableDateBeforeYesterday}
                    value={values?.published_at ? moment(values.published_at) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('published_at', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('published_at', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  label={intl.formatMessage({ id: 'ui-general-published-to' })}
                  name='published_to'
                >
                  <DatePicker
                    showToday
                    name='published_to'
                    format={dateFieldFormat}
                    disabledDate={disableDateBeforeYesterday}
                    value={values?.published_to ? moment(values.published_to) : null}
                    onChange={async (date, dateString) => {
                      await setFieldValue('published_to', dateString);
                    }}
                    onBlur={async ({ target }) => {
                      await onBlur('published_to', target.value, setFieldValue);
                    }}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <ResetButton disabled={false}>
                  <IntlMessages id='ui-general-reset' />
                </ResetButton>
              </Col>

              <Col>
                <SubmitButton
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  <IntlMessages id="ui-general-save" />
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateNews.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};


export default FormCreateNews;
