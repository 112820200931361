import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PiCalendarBlankBold } from 'react-icons/pi';
import { Button, Card, Col, DatePicker, Modal, Popconfirm, Row, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { RiArrowLeftLine, RiErrorWarningLine } from 'react-icons/ri';
import { DATE_FORMAT } from '../../../../const/system';
import { Permissions } from '../../../../const/permissions';
import appColors from '../../../../const/colors';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageFeeds from '../../../../urls/urlPageFeeds';
import urlPageFeedsPlanningDetails from '../../../../urls/urlPageFeedsPlanningDetails';
import { PrivateComponent } from '../../../../components/HasRights/HasRights';
import { makePayload } from './FeedsCalendarWidget.const';
import { usePlanningBoardImagesContext } from '../../contexts/PlanningImagesContext';
import handleResponse from '../../../../lib/handleResponse';
import useToggle from '../../../../app/hooks/useToggle';
import getSourceFromUrl from '../../../../lib/getSourceFromUrl';
import ActionButton from '../../../../layout/components/action-button';
import {
  FEEDS_SOURCE_MAP,
  PLANNING_BOARD_PUBLISH_DEFAULT_LIMIT,
  PLANNING_BOARD_PUBLISH_LIMIT,
} from '../../pages/Feeds/PageFeeds.const';
import { usePublishFeedsPlanningBoardMutation } from '../../api/feedsPlanningApiSlice';



const FeedsCalendarWidget = ({
  isEditing,
  isLoading,
  showContour,
  toggleShowContour,
  handleChangeCalendarDate = (_date) => {},
  handleCancelChanges = () => {},
  handleCloseEdit = () => {},
  handleNavigate = () => {},
}) => {
  const intl = useIntl();
  const { date } = useParams();
  const { pathname } = useLocation();
  const feedSource = getSourceFromUrl(pathname);
  const navigate = useNavigate();
  const [ showDatePicker, toggleShowDatePicker ] = useToggle();
  const [ isConfirmationModalOpen, setIsConfirmationModalOpen ] = useState(false);
  const [ hasInitialPriority, setHasInitialPriority ] = useState(true);
  const [ selectedDate, setSelectedDate ] = useState(null);
  const { items: planningBoardImages, originalItems: originalPlanningBoardImages, updateImageWithErrorsIds, updateCurrentlyAddedToBoardItem, imageWithErrorsIds } = usePlanningBoardImagesContext();

  const [ publishPlanningBoard, { isLoading: isPublishingPlanningBoard } ] = usePublishFeedsPlanningBoardMutation();

  const makeErrorIndexes = (errors) => {
    const imageWithErrorsIds = [];

    Object.keys(errors).forEach((key) => {
      const [ _first, errorIndex ] = key.split('.');

      imageWithErrorsIds.push(planningBoardImages[Number(errorIndex)]?.id);
    });

    updateImageWithErrorsIds(imageWithErrorsIds);
  };

  const handlePublish = () => {
    publishPlanningBoard({ date: dayjs(date).format(DATE_FORMAT), payload: { items: makePayload(planningBoardImages) } })
      .unwrap()
      .then(() => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [
          () => navigate(urlPageFeedsPlanningDetails({ feedSource, date })),
          () => updateImageWithErrorsIds([]),
        ],
      ))
      .catch((error) => handleResponse(
        'error',
        intl,
        'ui-general-update-failed',
        [ () => makeErrorIndexes(error?.data?.errors) ],
        error,
      ));
  };

  useEffect(() => {
    if (difference(originalPlanningBoardImages, planningBoardImages).length > 0) {
      setHasInitialPriority(false);
    } else {
      const isOrderSame = isEqual(originalPlanningBoardImages, planningBoardImages);

      setHasInitialPriority(isOrderSame);
    }
  }, [ originalPlanningBoardImages, planningBoardImages ]);

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleLeaveEditing = () => {
    updateCurrentlyAddedToBoardItem({});
    handleCloseConfirmationModal();
    handleCancelChanges();
    handleChangeCalendarDate(selectedDate);
  };

  const showModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const onDatepickerChange = (date) => {
    if (isEditing && !isEqual(originalPlanningBoardImages, planningBoardImages)) {
      setSelectedDate(date);
      showModal();
    } else {
      handleChangeCalendarDate(date);
    }
  };

  const publishMaxLimitExceeded = planningBoardImages.length > PLANNING_BOARD_PUBLISH_LIMIT;
  const publishDefaultLimitExceeded = planningBoardImages.length > PLANNING_BOARD_PUBLISH_DEFAULT_LIMIT;
  const isPublishBtnDisabled = hasInitialPriority || !isEmpty(imageWithErrorsIds) || publishMaxLimitExceeded;

  const getPublishTooltip = () => {
    if (publishMaxLimitExceeded) {
      return <IntlMessages id="planning-board-save-and-publish-limit-hint" />;
    }
    return !isEmpty(imageWithErrorsIds) ? <IntlMessages id="planning-board-has-errors" /> : '';
  };

  return (
    <div className='hp-mb-32'>
      <Modal
        title={<IntlMessages id="ui-general-leave-changes-confirm-modal-title" />}
        centered
        visible={isConfirmationModalOpen}
        onOk={handleLeaveEditing}
        onCancel={handleCloseConfirmationModal}
      >
        <IntlMessages id="ui-general-leave-changes-confirm-message" />
      </Modal>

      <Spin spinning={isLoading || isPublishingPlanningBoard} tip=''>
        <Card className="feeds-calendar-widget">
          <Row justify='space-between' align='middle' gutter={[ 8, 8 ]}>
            <Col xs={24} sm={8} align='left'>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span className='feeds-calendar-widget__date'>
                  {dayjs(date).format('D MMMM, YYYY')}
                </span>
                <PiCalendarBlankBold
                  className='hp-cursor-pointer feeds-calendar-widget__calendar-icon'
                  size={21}
                  color={appColors.blue}
                  onClick={toggleShowDatePicker}
                />
              </div>

              <DatePicker
                open={showDatePicker}
                format={DATE_FORMAT}
                value={moment(date)}
                className="calendar-widget-datepicker"
                onChange={onDatepickerChange}
                onOpenChange={(open) => {
                  if (!open && showDatePicker) {
                    toggleShowDatePicker();
                  }
                }}
              />
            </Col>

            <Col xs={24} sm={16} align='right'>
              <div style={{ display: 'inline-flex' }}>
                <Button
                  size='small'
                  className='hp-mr-sm-8 hp-mr-16'
                  icon={<RiArrowLeftLine className="remix-icon hp-mr-8" />}
                  onClick={() => {
                    if (isEditing) {
                      if (hasInitialPriority) {
                        handleCloseEdit(date);
                      } else {
                        setSelectedDate(moment(date));
                        showModal();
                      }
                    } else {
                      navigate(urlPageFeeds({ feedSource: FEEDS_SOURCE_MAP.PLANNING }));
                    }
                  }}
                >
                  <IntlMessages id="ui-general-back-to-view" />
                </Button>

                <ActionButton
                  className='hp-mr-sm-8 hp-mr-16'
                  size="small"
                  onClick={toggleShowContour}
                  title={<IntlMessages id={showContour ? 'content-images-show-colors' : 'content-images-show-contour'} />}
                />

                <PrivateComponent
                  allowedPermissions={[
                    Permissions.CONTENT.FEEDS.PLANNING.BOARD.UNPUBLISH,
                    Permissions.CONTENT.FEEDS.PLANNING.BOARD.PUBLISH,
                  ]}
                >
                  {isEditing ? (
                    <>
                      <ActionButton
                        size="small"
                        disabled={hasInitialPriority}
                        title={<IntlMessages id='ui-general-reset-changes' />}
                        onClick={handleCancelChanges}
                      />
                      <Popconfirm
                        title={<IntlMessages id="planning-board-save-and-publish-confirmation" />}
                        onConfirm={handlePublish}
                        placement="top"
                        disabled={!publishDefaultLimitExceeded || isPublishBtnDisabled}
                        okText={<IntlMessages id="ui-general-ok" />}
                        cancelText={<IntlMessages id="ui-general-cancel" />}
                        icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
                        okButtonProps={{ danger: false }}
                      >
                        <Tooltip title={getPublishTooltip()}>
                          <Button
                            disabled={isPublishBtnDisabled}
                            type='primary'
                            size='small'
                            onClick={!publishDefaultLimitExceeded ? handlePublish : ''}
                          >
                            <IntlMessages id='ui-general-save-and-publish' />
                          </Button>
                        </Tooltip>
                      </Popconfirm>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      size="small"
                      onClick={handleNavigate}
                    >
                      <IntlMessages id="ui-general-edit" />
                    </Button>
                  )}
                </PrivateComponent>
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
};

FeedsCalendarWidget.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showContour: PropTypes.bool.isRequired,
  toggleShowContour: PropTypes.func.isRequired,
  handleChangeCalendarDate: PropTypes.func,
  handleCancelChanges: PropTypes.func,
  handleNavigate: PropTypes.func,
  handleCloseEdit: PropTypes.func,
};

export default FeedsCalendarWidget;
