import React from 'react';
import { Card, Col, Input, Row, Table } from 'antd';
import { useIntl } from 'react-intl';
import { Search } from 'react-iconly';
import isEmpty from 'lodash/isEmpty';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import { PERMISSIONS_TABLE_COLUMNS } from './PagePermissions.const';
import { useGetPermissionsQuery } from '../../../api/permissionsApiSlice';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import makeTableLoadingObject from '../../../../../lib/makeTableLoadingObject';
import basePagination from '../../../../../const/pagination';



const PagePermissions = () => {
  const intl = useIntl();

  const {
    pagination,
    search,
    setSearchTerm,
    handleChangeTableParams,
    searchParams,
  } = useQueryParams({ searchFields: [ 'name', 'display_name' ] });

  const { data: {
    data: permissions = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} },
  isFetching: isLoading,
  } = useGetPermissionsQuery({ queryParams: searchParams.toString() });


  const handleSearch = (ev) => {
    setSearchTerm(ev.target.value);
  };

  return (
    <Row gutter={32}>
      <Col className="hp-mb-32" span={24}>
        <Row
          gutter={[ 32, 32 ]}
          justify="space-between"
          className="hp-print-none"
        >
          <BreadCrumbs breadCrumbActive={<IntlMessages id='permissions-breadcrumbs' />} />

          <Col md={15} span={24}>
            <Row justify="end" align="middle" gutter={[ 16, 16 ]}>
              <Col md={14} span={24}>
                <Input
                  allowClear={!isEmpty(search)}
                  placeholder={intl.formatMessage({ id: 'permissions-search-by' })}
                  prefix={<Search set="curved" size={16} className="hp-text-color-black-80" />}
                  value={search}
                  onChange={handleSearch}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Card className="hp-border-color-black-40">
          <Table
            sticky
            rowKey="id"
            loading={makeTableLoadingObject(isLoading)}
            columns={PERMISSIONS_TABLE_COLUMNS}
            dataSource={permissions}
            onChange={handleChangeTableParams}
            pagination={{
              ...basePagination,
              current: pagination.page,
              pageSize: pagination.limit,
              total: dataPagination?.total,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};


export default PagePermissions;
