import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import dayjs from 'dayjs';
import { Tag } from 'antd';
import { useIntl } from 'react-intl';
import { DATE_TIME_FORMAT } from '../../../../const/system';
import SortableItem from '../../../../components/SortableItem';



const CategoriesPrioritiesNew = ({ data, onOrderChange }) => {
  const [ items, setItems ] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    if (data.length > 0) {
      setItems(range(1, data.length + 1).map((item) => `${item}`));
    }
  }, [ data ]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || !active) return;

    if (active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.indexOf(active.id);
        const newIndex = prevItems.indexOf(over.id);
        const newOrder = arrayMove(prevItems, oldIndex, newIndex);

        onOrderChange(newOrder);
        return newOrder;
      });
    }
  };

  return (
    <DndContext
      modifiers={[ restrictToParentElement ]}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div>
          {items.map((item) => {
            const position = Number(item) - 1;

            return (
              <SortableItem
                key={item}
                id={item}
                className={`categories-priority__item ${!data[position]?.status ? 'categories-priority__item--inactive' : ''} ${data[position]?.deleted_at ? 'categories-priority__item--deleted' : ''}`}
              >
                <div className="hp-d-flex hp-d-flex-justify-between hp-align-items-center">
                  <div className="priority-content">
                    {`${data[position]?.name} (${intl.formatMessage({ id: 'ui-general-id' })}: ${data[position]?.client_id})`}
                  </div>
                  {data[position]?.deleted_at && (
                    <Tag color="default">
                      <span>
                        {`${intl.formatMessage({ id: 'categories-deleted-at' })}: `}
                      </span>
                      {dayjs(data[position]?.deleted_at).format(DATE_TIME_FORMAT)}
                    </Tag>
                  )}
                </div>
              </SortableItem>
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};

CategoriesPrioritiesNew.propTypes = {
  data: PropTypes.array.isRequired,
  onOrderChange: PropTypes.func.isRequired,
};

export default CategoriesPrioritiesNew;
