import React from 'react';
import { Alert, Col, Collapse, DatePicker, Divider, Modal, Row, Spin, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import { Form, FormItem, ResetButton, Select, SubmitButton, Switch } from 'formik-antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { imageStatuses } from '../../../../const/imageStatuses';
import { DEFAULT_RELEASE_TIME } from '../../../Featured/forms/FormEditFeature/FormEditFeature.const';
import { TIME_FORMAT } from '../../../../const/system';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import mkMainAndOptionalTemplateFields from '../../../Template builder/utils/mkMainAndOptionalTemplateFields';
import mkTemplateDynamicFormData from '../../utils/mkTemplateDynamicFormData';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import getTemplateOptions from '../../../../lib/getTemplateOptions';
import applyPreset from '../../../../lib/applyPreset';
import useToggle from '../../../../app/hooks/useToggle';
import { isOutImageOfReleaseDates } from './FormEditCollection.const';
import Spinner from '../../../../components/Spinner';
import TitleWithAutomaticGeneration from '../../../../components/TitleWithAutomaticGeneration';
import PresetPreview from '../../../../components/PresetPreview';
import DynamicFormDataFields from '../../components/DynamicFormDataFields';
import TargetingSettings from '../../../../components/TargetingSettings';
import { useGetPresetsQuery } from '../../../Template builder/api/presetsApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

const FormEditCollection = ({
  initialErrors,
  initialValues,
  isSubmitting,
  isLoading,
  collectionConfig,
  validationSchema,
  onSubmit,
  templateFields,
  templateOptions,
  categoriesOptions,
  imageTypeOptions,
  isEnabledTemplateStatus = false,
  hasReleasedImages = false,
  releasedImages = [],
}) => {
  const intl = useIntl();
  const [ alertModalOpen, toggleAlertModal ] = useToggle();
  const language = useSelector(({ customise: { language } }) => language);

  const isUpdateImageReleaseDates = hasReleasedImages && initialValues.image_type === imageStatuses.ready_for_release;

  if (!isEmpty(initialErrors)) {
    window.scrollTo(0, 0);
  }

  const { data: { data: presets } = { data: [] }, isFetching: isPresetsFetching } = useGetPresetsQuery({
    queryParams: `search=template_id:${initialValues?.template_id}`,
  }, { skip: !initialValues?.template_id });

  const { mainFields, optionalFields } = mkMainAndOptionalTemplateFields(templateFields);
  const defaultConfigUtcValue = collectionConfig?.publish?.enabled_utc0_field ?? false;
  const isDisabledUtcField = !collectionConfig?.publish?.show_utc0_field;
  const useDatePickerTime = collectionConfig?.publish?.use_time ?? false;
  const useAutomaticGeneration = collectionConfig?.title?.use_automatic_generation ?? true;
  const hasReleaseRange = collectionConfig?.release_range ?? false;

  const handleUpdateCollectionWithImages = (values, resetForm) => {
    onSubmit(mkTemplateDynamicFormData(values, language, templateFields), resetForm);
    toggleAlertModal();
  };

  const handleFormSubmit = async (values, resetForm) => {
    if (isUpdateImageReleaseDates &&
      isOutImageOfReleaseDates(
        releasedImages,
        initialValues,
        values?.released_at,
        values?.released_to,
      )) {
      toggleAlertModal();
    } else {
      onSubmit(mkTemplateDynamicFormData(values, language, templateFields), resetForm);
    }
  };

  const onDatepickerChange = async (key, dateString, setFieldValue) => {
    await setFieldValue(key, !isEmpty(dateString) ? moment(dateString).utc(true) : null);

    await setFieldValue(
      'update_image_release_dates',
      isUpdateImageReleaseDates && !(moment(dateString).utc(true).isSame(moment(initialValues[key]))),
      false,
    );
  };

  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialErrors={initialErrors}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => handleFormSubmit(values, resetForm)}
    >
      {({ isValid, values, setFieldValue, setFieldTouched, resetForm, dirty }) => {
        const hasUnpublishedChanges = !moment(values?.published_at).isSame(moment(values?.updated_at));
        const preset = presets?.find((preset) => Number(preset.id) === Number(values.preset_id));

        if (!hasReleasedImages && values?.is_published) {
          setFieldValue('is_published', false);
        }

        return (
          <Form layout="vertical" >
            <Spinner spinning={isSubmitting || isLoading}>
              <Modal
                width={800}
                title={false}
                centered
                visible={alertModalOpen}
                onCancel={() => toggleAlertModal()}
                onOk={() => handleUpdateCollectionWithImages(values, resetForm)}
              >
                <IntlMessages id='collection-autoupdate-image-release-dates-info' />
              </Modal>

              <Title className="hp-mb-24" level={4}>{intl.formatMessage({ id: 'collection-details-title' })}</Title>

              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          loading={isLoading}
                          allowClear
                          disabled
                          name="template_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                          options={templateOptions}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preset' })}
                        extra={intl.formatMessage({ id: 'presets-template-hint' })}
                        name='preset_id'
                      >
                        <Select
                          loading={isPresetsFetching}
                          allowClear
                          showSearch
                          name="preset_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-preset' })}
                          options={getTemplateOptions(presets)}
                          onChange={async (value) => {
                            await applyPreset(value, values, setFieldValue, presets, templateFields);
                          }}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'collection-form-categories-title' })}
                        name='categories'
                        required
                      >
                        <Select
                          loading={isLoading}
                          mode="multiple"
                          disabled
                          allowClear
                          name="categories"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-categories' })}
                          options={categoriesOptions}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'collection-form-image-type-title' })}
                        name='image_type'
                        required
                      >
                        <Select
                          disabled
                          allowClear
                          name="image_type"
                          options={imageTypeOptions}
                          placeholder={intl.formatMessage({ id: 'collection-form-specify-image-type' })}
                        />
                      </FormItem>
                    </Col>

                    {hasReleaseRange ? (
                      <>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-at' })}
                            name='released_at'
                            required
                          >
                            <DatePicker
                              name='released_at'
                              showToday
                              showTime={useDatePickerTime ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT).utc(true) } : false}
                              disabledDate={(date) => (!isEmpty(values?.released_to) && date.isAfter(moment(values?.released_to)))
                                || moment(date).subtract('-2', 'days').isSameOrBefore(moment(new Date()))}
                              value={values?.released_at ? moment(values.released_at).utc(false) : null}
                              onChange={(date, dateString) => onDatepickerChange('released_at', dateString, setFieldValue)}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_at', true);
                                await onDatepickerChange('released_at', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                        <Col span={24}>
                          <FormItem
                            label={intl.formatMessage({ id: 'ui-general-released-to' })}
                            name='released_to'
                          >
                            <DatePicker
                              name='released_to'
                              showTime={useDatePickerTime ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT).utc(true) } : false}
                              disabledDate={(date) => !isEmpty(values?.released_at) && date.isBefore(moment(values?.released_at))}
                              value={values?.released_to ? moment(values.released_to).utc(false) : null}
                              onChange={(date, dateString) => onDatepickerChange('released_to', dateString, setFieldValue)}
                              onBlur={async ({ target }) => {
                                await setFieldTouched('released_to', true);
                                await onDatepickerChange('released_to', target.value, setFieldValue);
                              }}
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <Col span={24}>
                        <FormItem
                          label={intl.formatMessage({ id: 'ui-general-released-at' })}
                          name='released_at'
                          required={values?.is_published}
                        >
                          <DatePicker
                            name='released_at'
                            showToday
                            showTime={useDatePickerTime ? { defaultValue: moment(DEFAULT_RELEASE_TIME, TIME_FORMAT).utc(true) } : false}
                            disabledDate={(date) => moment(date).subtract('-2', 'days').isSameOrBefore(moment(new Date()))}
                            value={values?.released_at ? moment(values.released_at).utc(false) : null}
                            onChange={(date, dateString) => onDatepickerChange('released_at', dateString, setFieldValue)}
                            onBlur={async ({ target }) => {
                              await setFieldTouched('released_at', true);
                              await onDatepickerChange('released_at', target.value, setFieldValue);
                            }}
                          />
                        </FormItem>
                      </Col>
                    )}

                    <Col span={24}>
                      <Collapse accordion className='hp-mb-24'>
                        <Panel
                          key="additional-info"
                          header={
                            <Title className='collapse-item-title' level={5}>
                              {intl.formatMessage({ id: 'ui-general-optional-data' })}
                            </Title>
                          }
                        >
                          <Row gutter={16}>
                            <Col span={24}>
                              <TitleWithAutomaticGeneration
                                rows={2}
                                useAutomaticGeneration={useAutomaticGeneration}
                              />
                            </Col>

                            <Col span={24}>
                              <FormItem
                                name='use_utc_zero_time'
                                label={
                                  <Row className='w-full' justify="space-between" align="middle">
                                    <IntlMessages id="features-use-utc" />
                                    <Tooltip
                                      placement="top"
                                      title={
                                        <>
                                          <IntlMessages id="features-use-utc-info" />
                                          <div>
                                            <Link to='https://x-flow-ltd.atlassian.net/wiki/spaces/HCD/pages/734461960/Use+UTC+0' target="_blank">
                                              <u><IntlMessages id="ui-general-documentation-link" /></u>
                                            </Link>
                                          </div>
                                        </>
                                      }
                                    >
                                      <InfoCircleOutlined className='hp-ml-8' />
                                    </Tooltip>
                                  </Row>
                                }
                              >
                                <Switch
                                  name="use_utc_zero_time"
                                  defaultChecked={defaultConfigUtcValue}
                                  disabled={isDisabledUtcField}
                                />
                              </FormItem>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
                <Col md={12} xs={24}>
                  <PresetPreview src={preset?.preview} />
                </Col>
              </Row>

              <Divider />

              <Title className="hp-mb-24" level={4}><IntlMessages id="ui-general-template-data" /></Title>

              {templateFields.length ? (
                <Row>
                  <Col span={12}>
                    <DynamicFormDataFields
                      setFieldValue={setFieldValue}
                      fields={mainFields}
                      values={values}
                      span={24}
                    />
                    {!!optionalFields.length && (
                      <Collapse>
                        <Panel
                          key="1"
                          header={
                            <Title level={5} className='collapse-item-title'>
                              <IntlMessages id="ui-general-additional-parameters" />
                            </Title>
                          }
                        >
                          <DynamicFormDataFields
                            setFieldValue={setFieldValue}
                            fields={optionalFields}
                            values={values}
                            span={24}
                          />
                        </Panel>
                      </Collapse>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row gutter={16} className="hp-d-flex-justify-center"><Spin spinning /></Row>
              )}

              <Divider />

              <TargetingSettings setFieldValue={setFieldValue} values={values} />

              {hasReleasedImages && (dirty || hasUnpublishedChanges) && (
                <Alert
                  message={<IntlMessages id="features-edit-has-changes" />}
                  description={
                    <>
                      <Row justify='end' className='hp-mt-32'>
                        <Col>
                          <Row>
                            <IntlMessages id="features-edit-publish-to-hc" />
                            <Switch disabled={!isEnabledTemplateStatus} name="is_published" className='hp-ml-16' />
                          </Row>
                        </Col>
                      </Row>
                      {!isEnabledTemplateStatus && (
                        <Row>
                          <div className='error-text'>
                            <IntlMessages id='features-disabled-template-text-error' />
                          </div>
                        </Row>
                      )}
                    </>
                  }
                  type="info"
                  showIcon
                />
              )}

              {!hasReleasedImages && (
                <Alert
                  className='hp-mb-32'
                  message={<IntlMessages id="collection-publish-notification-note" />}
                  type="warning"
                  showIcon
                />
              )}

              <Row gutter={[ 16, 16 ]} className='hp-mt-16' justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => resetForm(initialValues)}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormEditCollection.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  validationSchema: PropTypes.object.isRequired,
  collectionConfig: PropTypes.object,
  templateFields: PropTypes.array,
  isEnabledTemplateStatus: PropTypes.bool,
  hasReleasedImages: PropTypes.bool,
  templateOptions: PropTypes.array,
  categoriesOptions: PropTypes.array,
  imageTypeOptions: PropTypes.array,
  releasedImages: PropTypes.array,
};

export default FormEditCollection;
