import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Form, FormItem, Input, ResetButton, Select, SubmitButton } from 'formik-antd';
import { Col, Collapse, Divider, Row, Typography, Upload } from 'antd';
import PropTypes from 'prop-types';
import { RiUploadCloud2Line } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import { useGetTemplateQuery, useGetTemplatesQuery } from '../../api/templatesApiSlice';
import { VALIDATION_RULES_REQUIRED } from '../../const/templates';
import Spinner from '../../../../components/Spinner';
import modifyDefaultValue from '../../utils/modifyDefaultValue';
import mkTemplateDynamicFormData from '../../../Content/utils/mkTemplateDynamicFormData';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import getTemplateOptions from '../../../../lib/getTemplateOptions';
import DynamicFormDataFields from '../../../Content/components/DynamicFormDataFields';
import isFieldUsingInPreset from '../../../../lib/isFieldUsingInPreset';
import useModifiedDynamicDataErrors from '../../../../app/hooks/useModifiedDynamicDataErrors';
import { mkValidationSchema } from './FormCreateEditPresets.const';



const { Title } = Typography;
const { Panel } = Collapse;

const FormCreateEditPresets = ({
  isSubmitting,
  initialErrors,
  onSubmit,
  initialValues = {},
}) => {
  const intl = useIntl();
  const language = useSelector(({ customise: { language } }) => language);
  const [ currentTemplateId, setCurrentTemplateId ] = useState(null);
  const [ currentFormState, setCurrentFormState ] = useState({});

  const { data: templates = { data: [] }, isFetching: isTemplatesFetching } = useGetTemplatesQuery({
    queryParams: 'limit=0&search=status:1',
  });

  const templatesWithAvailablePresetFields = useMemo(() => {
    if (isEmpty(templates.data)) {
      return [];
    }

    return templates.data?.filter((template) => {
      const presetFieldsCount = template.fields?.data.reduce((acc, current) => {
        return current.use_in_preset ? ++acc : acc;
      }, 0);

      return presetFieldsCount > 0;
    });
  }, [ templates ]);

  const {
    data: { data: template } = { data: {} },
    isFetching: isTemplateFetching,
  } = useGetTemplateQuery(currentTemplateId, { skip: !currentTemplateId });

  const modifiedErrors = useModifiedDynamicDataErrors(currentTemplateId, template, initialErrors, language);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setCurrentTemplateId(initialValues.template_id);
    }
  }, [ initialValues ]);

  const mainTemplateFields = useMemo(() => {
    if (!currentTemplateId) {
      return [];
    }

    return template?.fields?.data.filter((field) => isFieldUsingInPreset(field) && field.is_main)
      .map((field) => {
        const validations = { data: field.validations?.data.filter((item) => item.rule !== VALIDATION_RULES_REQUIRED) };

        return { ...field, validations };
      });
  }, [ template?.fields?.data ]);

  const additionalTemplateFields = useMemo(() => {
    if (!currentTemplateId) {
      return [];
    }

    return template?.fields?.data.filter((field) => isFieldUsingInPreset(field) && !field.is_main)
      .map((field) => {
        const validations = { data: field.validations?.data.filter((item) => item.rule !== VALIDATION_RULES_REQUIRED) };

        return { ...field, validations };
      });
  }, [ template?.fields?.data ]);

  const defaultData = useMemo(() => {
    if (!currentTemplateId) {
      return {};
    }

    const data = {};

    template?.fields?.data.filter((field) => isFieldUsingInPreset(field))
      .forEach((field) => {
        data[field.full_name] = {
          value: modifyDefaultValue(field),
          template_field_id: field.id,
        };
      });

    return data;
  }, [ template, currentTemplateId ]);


  const emptyFormValues = {
    title: '',
    template_id: null,
    data: {},
  };

  return (
    <Formik
      initialErrors={modifiedErrors}
      initialValues={!isEmpty(initialValues) ? initialValues : {
        title: '',
        ...currentFormState,
        template_id: currentTemplateId,
        data: defaultData,
      }}
      enableReinitialize
      isSubmitting={isSubmitting}
      validationSchema={mkValidationSchema}
      onSubmit={async (values, { resetForm }) => {
        onSubmit(mkTemplateDynamicFormData(values, language, template?.fields?.data), resetForm);
      }}
    >
      {({ isValid, values, setFieldValue, setValues, resetForm }) => {
        return (
          <Form layout="vertical" >
            <Spinner spinning={isTemplateFetching}>
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-title' })}
                        name='title'
                        required
                      >
                        <Input
                          name="title"
                          placeholder={intl.formatMessage({ id: 'presets-form-title-placeholder' })}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-template' })}
                        name='template_id'
                        required
                      >
                        <Select
                          disabled={!isEmpty(initialValues)}
                          loading={isTemplatesFetching}
                          allowClear
                          showSearch
                          name="template_id"
                          placeholder={intl.formatMessage({ id: 'ui-general-choose-template' })}
                          filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                          options={getTemplateOptions(templatesWithAvailablePresetFields)}
                          onChange={async (value) => {
                            setCurrentTemplateId(value);
                            setCurrentFormState(values);
                            await setFieldValue('template_id', value);
                          }}
                        />
                      </FormItem>
                    </Col>

                    <Col span={24}>
                      <FormItem
                        label={intl.formatMessage({ id: 'ui-general-preview' })}
                        name='preview'
                      >
                        <Upload.Dragger
                          name="preview"
                          accept=".jpeg,.jpg,.png"
                          beforeUpload={() => false}
                          listType="picture"
                          fileList={values?.preview}
                          onChange={({ fileList }) => {
                            if (fileList.length > 1) {
                              fileList = fileList.slice(-1);
                            }
                            setFieldValue('preview', fileList);
                          }}
                        >
                          <p className="ant-upload-drag-icon hp-mb-0">
                            <RiUploadCloud2Line size={24} className="remix-icon" />
                          </p>

                          <p className="ant-upload-text">
                            <IntlMessages id="presets-form-preview-label" />
                          </p>
                        </Upload.Dragger>
                        <IntlMessages id="presets-form-preview-hint" />
                      </FormItem>
                    </Col>

                    {values?.template_id && (
                      <>
                        <Divider />

                        <Col span={24}>
                          <DynamicFormDataFields
                            setFieldValue={setFieldValue}
                            fields={mainTemplateFields || []}
                            values={values}
                            span={24}
                          />
                        </Col>

                        {!isEmpty(additionalTemplateFields) && (
                          <Col span={24}>
                            <Collapse accordion className='hp-mb-24'>
                              <Panel
                                key="additional-info"
                                header={
                                  <Title className='collapse-item-title' level={5}>
                                    {intl.formatMessage({ id: 'ui-general-additional-parameters' })}
                                  </Title>
                                }
                              >
                                <DynamicFormDataFields
                                  setFieldValue={setFieldValue}
                                  fields={additionalTemplateFields || []}
                                  values={values}
                                  span={24}
                                />
                              </Panel>
                            </Collapse>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row gutter={[ 16, 16 ]} justify='end'>
                <Col>
                  <ResetButton
                    disabled={false}
                    onClick={() => {
                      setCurrentTemplateId(null);
                      resetForm();
                      if (isEmpty(initialValues)) {
                        setValues(emptyFormValues).then(() => {
                          setFieldValue('title', '');
                        });
                      }
                    }}
                  >
                    <IntlMessages id='ui-general-reset' />
                  </ResetButton>
                </Col>

                <Col>
                  <SubmitButton
                    type="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <IntlMessages id="ui-general-save" />
                  </SubmitButton>
                </Col>
              </Row>
            </Spinner>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditPresets.propTypes = {
  initialErrors: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default FormCreateEditPresets;
